<template>
    <!--START EDITOR TOP SIDE-->
    <div class="editor_topside">
        <div class="editor_leftsidetop">
            <div class="header_left">
                <a href="/" class="header_logo">
                    <div class="header_logo_img">
                        <div class="header_logo_img_play"></div>
                        <div class="header_logo_img_lion"></div>
                    </div>
                </a>
                <div class="header_logo_txt">
                    <span>Editor</span>
                </div>
            </div>
            <!--START CHOOSE THE ASPECT RATION-->
            <!--
                FLORINUXXX
                -> ADD .active ON .ratio_button IF IS SELECTED
                -> UPDATE SOME CLASES RELATED
            -->
            <div class="editor_ratio_block clr">
                <ul class="ratio_list clr">
                    <li>
                        <div class="ratio_button ratio_button_square"
                             :class="{active: ratio === 'square'}"
                             @click="changeAspectRatio('square')"
                        >
                            <div class="radio_c radio_c1"></div>
                            <div class="radio_c radio_c2"></div>
                            <div class="radio_c radio_c3"></div>
                            <div class="radio_c radio_c4"></div>
                            <span>1:1</span>
                        </div>
                    </li>
                    <li>
                        <div class="ratio_button ratio_button_portrait"
                             :class="{active: ratio === 'portrait'}"
                             @click="changeAspectRatio('portrait')"
                        >
                            <div class="radio_c radio_c1"></div>
                            <div class="radio_c radio_c2"></div>
                            <div class="radio_c radio_c3"></div>
                            <div class="radio_c radio_c4"></div>
                            <span>9:16</span>
                        </div>
                    </li>
                    <li>
                        <div class="ratio_button ratio_button_landscape"
                             :class="{active: ratio === 'landscape'}"
                             @click="changeAspectRatio('landscape')"
                        >
                            <div class="radio_c radio_c1"></div>
                            <div class="radio_c radio_c2"></div>
                            <div class="radio_c radio_c3"></div>
                            <div class="radio_c radio_c4"></div>
                            <span>16:9</span>
                        </div>
                    </li>
                </ul>
            </div>
            <!--END CHOOSE THE ASPECT RATION-->
        </div>
    </div>
    <!--END EDITOR TOP SIDE-->
</template>

<script>

export default {
    name: "Header",
    props: {
        ratio: {
            type: String,
            default: 'square'
        }
    },
    methods: {
        changeAspectRatio(ratio) {
            this.$emit('updateAspectRatio', ratio);
        }
    }
}
</script>

<style scoped>

</style>
