<template>
    <!--START POPUP -> FONT FAMILY-->
    <!--FLORINXXXX -> ADD .active ON .popup_block TO ACTIVATE POPUP-->
    <!--FLORINXXXX -> ON CLICK .popup_close_button CLOSE POPUP -> REMOVE .active-->
    <div class="popup_block active">
        <div class="popup_container">
            <div class="popup_close_button" title="Close" @click="$emit('toggleShowFontSelector')">
                <div><span class="material-icons">close</span></div>
            </div>

            <div class="popup_content">
                <!--FLORINXXXX CHANGE H2 HEADLINE vs. TEXT-->
                <h2 v-if="headline">Headline Font</h2>
                <h2 v-else>Text Font</h2>

                <div class="clear" style="height: 12px;"></div>
                <div class="popup_font_picker">

                    <!--FLORIN -> CURRENT FONT -> HERE WILL UPDATE THE FONT NAME + FONT-FAMILY NAME-->
                    <div class="choose_font_button choose_headline_font current_font_button">
                        <div class="choose_font_button_inner">
                            <div class="choose_font_label">Current font</div>
                            <div
                                class="choose_font_currnet"
                                v-bind:style="{ fontFamily:temporaryFontName +', sans-serif' }"
                            >
                                {{ temporaryFontName }}
                            </div>
                            <span class="material-icons">font_download</span>
                        </div>
                    </div>

                    <!--FLORIN -> LIST FONTS-->
                    <div class="list_the_fonts">
                        <ul>
                            <li v-for="(font,index) in fontList" :key="index"
                                @click="fontChanged(font)"
                            >
                                <div class="add_font_button"
                                     :class="{active: temporaryFontName === font}"
                                >
                                    <div
                                        class="add_font_display"
                                        v-bind:style="{ fontFamily:font +', sans-serif' }"
                                    >
                                        {{ font }}
                                    </div>
                                    <!--<span class="material-icons">check</span>-->
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div class="popup_footer">
                <div class="popup_footer_block">
                    <!--FLORINXXXX -> ON CLICK .popup_button_update -> UPDATE-->
                    <div class="popup_button_update" @click="updateFontValue">
                        <div style="padding-right: 12px;">
                            <strong>Update</strong>
                            <span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span>
                        </div>
                    </div>
                    <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                    <div class="popup_button_cancel" @click="$emit('toggleShowFontSelector')">
                        <div style="padding-left: 16px;">
                            Cancel
                            <span class="material-icons" style="top: 14px; left: -10px;">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--START POPUP -> FONT FAMILY-->
</template>

<script>

export default {
    name: "FontFamily",
    data() {
        return ({
            temporaryFontName: null,
            fontList: [
"Alatsi",
"Amatic SC",
"Antic",
"Anton",
"Bebas Neue",
"Bellefair",
"BioRhyme",
"Cabin",
"Codystar",
"Comforter",
"Crimson Text",
"Cutive Mono",
"Dancing Script",
"Della Respira",
"DM Sans",
"Economica",
"Exo 2",
"Fredoka One",
"Gasoek One",
"Geostar Fill",
"Gochi Hand",
"Hammersmith One",
"Inconsolata",
"Josefin Sans",
"Kablammo",
"Knewave",
"Lato",
"Libre Barcode 39 Text",
"Limelight",
"Lobster",
"Major Mono Display",
"Montserrat",
"Nixie One",
"Open Sans",
"Orbit",
"Orbitron",
"Oswald",
"Outfit",
"Pacifico",
"Paytone One",
"Poiret One",
"Poppins",
"Raleway",
"Righteous",
"Rowdies",
"Rubik Mono One",
"Sanchez",
"Sedgwick Ave",
"Shadows Into Light",
"Source Code Pro",
"Special Elite",
"Staatliches",
"Yeseva One",
"Zilla Slab Highlight",
"Zilla Slab"
            ]
        });
    },
    props: {
        activeFontHeadline: {
            type: String,
            default: 'Josefin Sans'
        },
        activeFontText: {
            type: String,
            default: 'Josefin Sans'
        },
        headline: {
            type: Boolean,
            default: true
        },
        apiKey: {
            type: String,
            default: 'AIzaSyBQaGRidi8mP_8DyOjadllh1pf45Z4QTy0'
        }
    },
    computed: {
        fontType() {
            if (this.headline) {
                return 'headline';
            } else {
                return 'text';
            }
        }
    },
    mounted() {
        if (this.headline) {
            this.temporaryFontName = this.activeFontHeadline;
        } else {
            this.temporaryFontName = this.activeFontText;
        }
    },
    methods: {
        fontChanged(font) {
            this.temporaryFontName = font;
        },
        updateFontValue() {
            this.$emit('changeActiveFont', this.temporaryFontName, this.headline);
            this.$emit('toggleShowFontSelector', this.temporaryFontName, this.headline);
        }
    }

}
</script>

<style scoped>

</style>
