<template>
    <div>
        <div style="height: 42px" class="clear"></div>
        <product-list
            :products="data"
            :uniqueID="uniqueID"
            @togglePopUp="$emit('togglePopUp')"
            @moveProductDown="moveProductDown"
            @moveProductUp="moveProductUp"
            @cloneProduct="cloneProduct"
            @deleteProduct="deleteProduct"
            @updateProductContentSuggestion="updateProductContentSuggestion"
            @productSetActiveTextType="productSetActiveTextType"
            @productHeadlineChanged="productHeadlineChanged"
            @productTextChanged="productTextChanged"
            @setDiscounted="setDiscounted"
            @productNewPriceChanged="productNewPriceChanged"
            @productOldPriceChanged="productOldPriceChanged"
            @productDiscountChanged="productDiscountChanged"
            @productPriceChanged="productPriceChanged"
            @toggleCallToActionButton="toggleCallToActionButton"
            @setCallToActionText="setCallToActionText"
            @addSelectedImages="addSelectedImages"
            @deleteImage="deleteImage"
            @moveImageLeft="moveImageLeft"
            @moveImageRight="moveImageRight"
            @updateImage="updateImage"
            @addNewProduct="addNewProduct"
        />
    </div>
</template>

<script>
import ProductList from "../../common/ProductList.vue";

export default {
    name: "Index",
    components: {
        ProductList
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        data: {
            type: Array,
            default: () => ([])
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName('Product to Video Data'));
    },
    methods: {
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
        addNewProduct(product) {
            this.$emit('addNewProduct', product)
        },
        moveProductDown(index) {
            this.$emit('moveProductDown', index)
        },
        moveProductUp(index) {
            this.$emit('moveProductUp', index)
        },
        cloneProduct(index) {
            this.$emit('cloneProduct', index)
        },
        deleteProduct(index) {
            this.$emit('deleteProduct', index)
        },
        updateProductContentSuggestion(data, index) {
            this.$emit('updateProductContentSuggestion', data, index)
        },
        productSetActiveTextType(value, index) {
            this.$emit('productSetActiveTextType', value, index)
        },
        productHeadlineChanged(value, index) {
            this.$emit('productHeadlineChanged', value, index)
        },
        productTextChanged(value, index) {
            this.$emit('productTextChanged', value, index)
        },
        setDiscounted(value, index) {
            this.$emit('setDiscounted', value, index)
        },
        productNewPriceChanged(value, index) {
            this.$emit('productNewPriceChanged', value, index)
        },
        productOldPriceChanged(value, index) {
            this.$emit('productOldPriceChanged', value, index)
        },
        productDiscountChanged(value, index) {
            this.$emit('productDiscountChanged', value, index)
        },
        productPriceChanged(value, index) {
            this.$emit('productPriceChanged', value, index)
        },
        toggleCallToActionButton(index) {
            this.$emit('toggleCallToActionButton', index)
        },
        setCallToActionText(value, index) {
            this.$emit('setCallToActionText', value, index)
        },
        addSelectedImages(images, index,replace_images=null) {
            this.$emit('addSelectedImages', images, index,replace_images)
        },
        deleteImage(imageIndex, productIndex) {
            this.$emit('deleteImage', imageIndex, productIndex)
        },
        moveImageLeft(imageIndex, productIndex) {
            this.$emit('moveImageLeft', imageIndex, productIndex)
        },
        moveImageRight(imageIndex, productIndex) {
            this.$emit('moveImageRight', imageIndex, productIndex)
        },
        updateImage(image, imageIndex, productIndex) {
            this.$emit('updateImage', image, imageIndex, productIndex)
        }
    }
}
</script>

<style scoped>

</style>
