<template>
    <div>
        <div style="height: 42px" class="clear"></div>
        <h1>Outro Setup <span class="optional_step_title">(Optional)</span></h1>
        <choose-text-field
            :showContentSuggestion="showIntroContentSuggestion"
            :activeTextType="data.activeTextType"
            :type="data.type"
            :headline="data.headline"
            :text="data.text"
            :contentSuggestion="contentSuggestionIntro"
            @toggleShowContentSuggestion="toggleShowIntroContentSuggestion"
            @updateTheContentSuggestion="updateTheOutroContentSuggestion"
            @setActiveTextType="setActiveTextType"
            @headlineChanged="outroHeadlineChanged"
            @textChanged="outroTextChanged"
        />
        <call-to-action-button
            :showContentSuggestion="showCallToActionContentSuggestion"
            :contentSuggestion="contentSuggestionCallToAction"
            :callToActionButton="data.callToActionButton"
            :callToActionText="data.callToActionText"
            @toggleCallToActionButton="toggleCallToActionButton"
            @textChanged="setCallToActionText"
            @toggleShowContentSuggestion="toggleShowCallToActionContentSuggestion"
            @updateTheContentSuggestion="updateTheContentSuggestion"
        />

        <upload-single-image-with-opacity
            :title="'Outro Image'"
            :image="data.image"
            :opacity="data.opacity"
            :backgroundColor="data.backgroundColor"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateImage="updateImage"
            @updateOpacity="updateOpacity"
            @deleteImage="deleteImage"
        />

        <div class="left_side_footer_spacer clear"></div>
    </div>

</template>

<script>
import ChooseTextField from "../../common/ChooseTextField.vue";
import ContentSuggestion from "../popups/ContentSuggestion.vue";
import CallToActionButton from "../../common/CallToActionButton.vue";
import UploadSingleImageWithOpacity from "../../common/UploadSingleImageWithOpacity.vue";

export default {
    name: "Index",
    components: {
        UploadSingleImageWithOpacity,
        CallToActionButton,
        ChooseTextField,
        ContentSuggestion
    },
    props: {
        uniqueID:{
            type:String,
            default:null
        },
        data: {
            type: Object,
            default: () => {
                return {
                    activeTextType: 1,
                }
            }
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
    },
    data: () => {
        return {
            showIntroContentSuggestion: false,
            contentSuggestionIntro: [
{
type: 2,
headline: '<p>Thanks for watching!</p>',
text: '<p>Stay tuned for more exciting content. Remember to like, share, and <strong>subscribe</strong>.</p>'
},
{
type: 3,
headline: '<p>We hope you enjoyed this video!</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Until next time, take care and <strong>stay awesome!</strong></p>'
},
{
type: 1,
headline: '<p><strong>Are you a tech enthusiast?</strong></p>',
text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
},
{
type: 1,
headline: '<p>Have thoughts on this video?</p>',
text: '<p>Join the conversation in the comments below. We\'d love to hear from you!</p>'
},
{
type: 4,
headline: null,
text: '<p><strong>Subscribe</strong> and be the first to enjoy exclusive previews in our upcoming video.</p>'
},
{
type: 2,
headline: '<p><strong>Keep Exploring</strong></p>',
text: '<p>Hungry for more knowledge? Keep exploring with us in our next video. See you there!</p>'
},
{
type: 1,
headline: '<p>Loved the products in this video?</p>',
text: '<p>Shop the look by clicking the <strong>links in the description below</strong>.</p>'
},
{
type: 3,
headline: '<p><strong>Get the Deals</strong></p>',
text: null
},
{
type: 2,
headline: '<p>Don\'t miss out on great deals.</p>',
text: '<p>Check out the links below to grab the best offers on the products featured today.</p>'
},
{
type: 2,
headline: '<p><strong>Travel with Us</strong></p>',
text: '<p>Join us on our next adventure. Subscribe now to travel the world from the comfort of your home.</p>'
},
{
type: 4,
headline: null,
text: '<p>Subscribe now to become the <strong>ultimate smart shopper</strong> with insights from our next video.</p>'
},
{
type: 1,
headline: '<p>Looking to save?</p>',
text: '<p>Stay tuned for our selection of budget-friendly picks in the upcoming video.</p>'
},
{
type: 2,
headline: '<p><strong>Bon Appetit</strong></p>',
text: '<p>We hope you try this recipe at home. Bon appétit and happy cooking!</p>'
},
{
type: 1,
headline: '<p><strong>Inspired to create?</strong></p>',
text: '<p>Join us in our next video for more DIY projects and crafting fun.</p>'
},
{
type: 1,
headline: '<p>Had a good laugh?</p>',
text: '<p><strong>Hit subscribe to keep the laughter rolling!</strong> See you in the next video.</p>'
},
{
type: 1,
headline: '<p>Craving more entertainment?</p>',
text: '<p>Like, share, and <strong>stay tuned for the encore</strong> in our upcoming videos!</p>'
},
{
type: 1,
headline: '<p><strong>Loved the workout? Stay Fit!</strong></p>',
text: '<p>Keep pushing your limits and stay fit with us. Don\'t forget to subscribe for more fitness inspiration!</p>'
},
{
type: 2,
headline: '<p><strong>Sweat & Smile</strong></p>',
text: '<p>Thanks for sweating it out with us today! Keep smiling and stay tuned for more fun workouts.</p>'
},
{
type: 3,
headline: '<p>Hit subscribe to keep the <strong>laughter rolling</strong>!</p>',
text: null
},
{
type: 1,
headline: '<p>Stay gorgeous and keep shining!</p>',
text: '<p>Check out the links below for all the products featured today.</p>'
},
{
type: 3,
headline: '<p>Like, share, and stay tuned for the encore in our upcoming videos!</p>',
text: null
},
{
type: 3,
headline: '<p>Bon appétit and happy cooking!</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Don\'t forget to subscribe for more fitness inspiration!</p>'
},
{
type: 2,
headline: '<p><strong>Keep the style game strong!</strong></p>',
text: '<p>See you in the next video for more fashion-forward tips and trends.</p>'
},
{
type: 1,
headline: '<p>Enjoyed the laughter?</p>',
text: '<p>There\'s more where that came from. Subscribe now for more joyous moments.</p>'
},
{
type: 3,
headline: '<p><strong>Tech It Out</strong></p>',
text: null
},
{
type: 1,
headline: '<p>Enjoyed our tech insights?</p>',
text: '<p>Subscribe to tech it out with us in the upcoming video!</p>'
},
{
type: 4,
headline: null,
text: '<p>See you in the next video for more fashion-forward tips and trends.</p>'
},
{
type: 4,
headline: null,
text: '<p>Stay tuned for our <strong>next exclusive unveil</strong>.</p>'
},
{
type: 3,
headline: '<p>Stay tuned for more tech updates!</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Become a gadget guru with our expert reviews and tips.</p>'
},
{
type: 4,
headline: null,
text: '<p>Thanks for gaming with us! Remember to <strong>like, share, and hit the subscribe button</strong> to join us in the next gaming adventure.</p>'
},
{
type: 1,
headline: '<p><strong>Ready to level up?</strong></p>',
text: '<p>Stay tuned for more epic gaming content coming your way!</p>'
},
{
type: 2,
headline: '<p><strong>Booked for More</strong></p>',
text: '<p>Can\'t wait to share more book gems with you. Stay booked for more in our <strong>next video</strong>!</p>'
},
{
type: 4,
headline: null,
text: '<p>Subscribe now to travel the world from the comfort of your home.</p>'
},
{
type: 1,
headline: '<p>Keep the pages turning!</p>',
text: '<p>Join us next time as we delve into another fantastic read.</p>'
},
{
type: 2,
headline: '<p><strong>Keep Laughing and Stay Tuned</strong></p>',
text: '<p>Enjoyed the laughter? There\'s more where that came from. Subscribe now for more joyous moments.</p>'
},
{
type: 4,
headline: null,
text: '<p>Hit subscribe to keep the laughter rolling!</p>'
},
{
type: 4,
headline: null,
text: '<p>Remember to like, share, and <strong>subscribe</strong>!</p>'
},
{
type: 1,
headline: '<p><strong>Your Daily Dose of Fitness Awaits</strong></p>',
text: '<p>Keep up the great work! Join us in the next session to continue your fitness journey.</p>'
},
{
type: 3,
headline: '<p>Subscribe to stay updated!</p>',
text: null
},
{
type: 3,
headline: '<p>Subscribe now for more joyous moments.</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Keep smiling and stay tuned for more fun workouts.</p>'
},
{
type: 2,
headline: '<p><strong>Create with Us</strong></p>',
text: '<p>Join us in our next video for more DIY projects and crafting fun.</p>'
},
{
type: 3,
headline: '<p>Join Us Again for More Tech Insights</p>',
text: null
},
{
type: 1,
headline: '<p>Are you a tech enthusiast?</p>',
text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
},
{
type: 3,
headline: '<p>Come Back for More Style and Elegance</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Remember to like, share, and hit the subscribe button to join us in the next gaming adventure.</p>'
},
{
type: 3,
headline: '<p>Subscribe now for more joyous moments</p>',
text: null
},
{
type: 1,
headline: '<p>Love fashion? So do we!</p>',
text: '<p>Come back for more style tips and fashion revelations in our next video.</p>'
},
{
type: 2,
headline: '<p>The Adventure Continues: Travel with Us</p>',
text: '<p>Your passport to the world\'s most wonderful places awaits. Join us in the next video as the adventure continues.</p>'
},
{
type: 1,
headline: '<p><strong>Delicious Journeys Await: Bon Appétit</strong></p>',
text: '<p>We have more delectable recipes to share with you. Stay tuned for more culinary journeys.</p>'
},
{
type: 3,
headline: '<p><strong>Your Daily Dose of Fitness Awaits</strong></p>',
text: null
},
{
type: 2,
headline: '<p>More Gaming Adventures Await You</p>',
text: '<p>Enjoyed the gaming session? We have more adventures lined up for you. Stay tuned and game on!</p>'
},
{
type: 3,
headline: '<p><strong>Stay Tuned for More Video-Turning Discussions</strong></p>',
text: null
},
{
type: 1,
headline: '<p>Are you a tech enthusiast?</p>',
text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
},
{
type: 4,
headline: null,
text: '<p>Book lovers, we have more engaging discussions coming up in our next video. Keep the pages turning!</p>'
},
{
type: 3,
headline: '<p><strong>Your Destination for Unbeatable Deals</strong></p>',
text: null
},
{
type: 1,
headline: '<p><strong>Eager for more great deals?</strong></p>',
text: '<p>Stay tuned, your destination for unbeatable deals and offers is right here.</p>'
},
{
type: 2,
headline: '<p><strong>Discover More Hidden Gems in Our Next Video</strong></p>',
text: '<p>Can\'t wait to find the next hidden gem? Join us again as we unveil more treasures in our upcoming videos.</p>'
},
{
type: 4,
headline: null,
text: '<p>Don\'t Miss the Next Exclusive Unveiling</p>'
},
{
type: 3,
headline: '<p>Stay tuned and game on!</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p><strong>Stay tuned for more exclusive product unveils in our upcoming videos.</strong></p>'
},
{
type: 3,
headline: '<p>Subscribe to get the first look at the hottest deals!</p>',
text: null
},
{
type: 3,
headline: '<p>Become the Ultimate Smart Shopper with Us</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Join us in the next video as the adventure continues</p>'
},
{
type: 1,
headline: '<p>Loved our shopping tips?</p>',
text: '<p>Subscribe now to become the ultimate smart shopper with insights from our next video.</p>'
},
{
type: 3,
headline: '<p><strong>More Top Deals and Discounts Await</strong></p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Excited for more savings? Join us in the next video as we reveal more top deals and discounts just for you!</p>'
},
{
type: 4,
headline: null,
text: '<p><strong>Your Gateway to Exclusive Fashion Finds</strong></p>',

type: 3,
headline: '<p>Don\'t miss our next episode filled with top deals.</p>',
text: null
},
{
type: 1,
headline: '<p><strong>Can\'t get enough of fashion?</strong></p>',
text: '<p>Your gateway to the best fashion finds continues in our next video. Don\'t miss it!</p>'
},
{
type: 3,
headline: '<p><strong>Join Us in the Next Ultimate Home Makeover</strong></p>',
text: null
},
{
type: 1,
headline: '<p>Dreaming of a home makeover?</p>',
text: '<p>We\'ve got more inspiration and exclusive products lined up for you in our next video.</p>'
},
{
type: 3,
headline: '<p>Unveiling the Best in Tech in Our Next Video</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Tech enthusiasts, you won\'t want to miss our next video where we unveil the best and latest in tech. Stay tuned!</p>'
},
{
type: 4,
headline: null,
text: '<p><strong>Experience the Future of Shopping with Us</strong></p>'
},
{
type: 2,
headline: '<p>Get ready to experience the future of shopping!</p>',
text: '<p>Join us in our next video for more product showcases and unbeatable deals.</p>'
},
{
type: 3,
headline: '<p><strong>Discover Rare Finds in Our Next Showcase</strong></p>',
text: null
},
{
type: 3,
headline: '<p>Subscribe to stay updated!</p>',
text: null
},
{
type: 1,
headline: '<p>Are you a fan of rare finds?</p>',
text: '<p>Don\'t miss our next showcase as we unveil more unique products just for you!</p>'
},
{
type: 3,
headline: '<p><strong>Your Guide to Luxurious Living Continues</strong></p>',
text: null
},
{
type: 1,
headline: '<p>Crave luxury? </p>',
text: '<p>Our guide to luxurious living continues in the next video with more premium products and exclusive offers.</p>'
},
{
type: 2,
headline: '<p>Stay Tuned for More Budget-Friendly Picks</p>',
text: '<p>Looking to save? Stay tuned for our selection of budget-friendly picks in the upcoming video.</p>'
},
{
type: 3,
headline: '<p><strong>Journey Through the Best of [Product Category] with Us</strong></p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Your journey through the best of [Product Category] continues. Subscribe now to discover more in our next video!</p>'
},
{
type: 4,
headline: null,
text: '<p>Subscribe now to discover more in our next video!</p>'
},
{
type: 4,
headline: null,
text: '<p>Join us again as we unveil more treasures in our upcoming videos.</p>'
},
{
type: 2,
headline: '<p><strong>The Best in [Product Category]: More to Come</strong></p>',
text: '<p>Loved our selections? We have more to come in the world of [Product Category]. Join us in the next video!</p>'
},
{
type: 4,
headline: null,
text: '<p>Get ready to experience the <strong>future of shopping</strong>!</p>'
},
{
type: 4,
headline: null,
text: '<p>Subscribe now to discover more in our next video!</p>'
},
{
type: 1,
headline: '<p>Next: Top Deals</p>',
text: '<p>Subscribe for exclusive access to the best shopping deals in our next video.</p>'
},
{
type: 1,
headline: '<p><strong>Ready to save big?</strong> </p>',
text: '<p>Don\'t miss our next episode filled with <strong>top deals</strong>.</p>'
},
{
type: 4,
headline: null,
text: '<p>Don\'t miss our deep dives into the latest gadgets. <strong>Stay tuned</strong>!</p>'
},
{
type: 1,
headline: '<p><strong>Are you a tech enthusiast?</strong> </p>',
text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
},
{
type: 2,
headline: '<p>Fashion Finds Ahead</p>',
text: '<p>Stay tuned for more hand-picked fashion finds. Don\'t miss out!</p>'
},
{
type: 2,
headline: '<p>Tech Unveiled Soon</p>',
text: '<p>Get ready for the latest tech unveils in our upcoming episode. <strong>Subscribe now!</strong></p>'
},
{
type: 3,
headline: '<p><strong>Join us in the next video!</strong></p>',
text: null
},
{
type: 3,
headline: '<p>More Bargains Coming</p>',
text: null
},
{
type: 4,
headline: null,
text: '<p>Subscribe for exclusive access to the <strong>best shopping deals</strong> in our next video.</p>'
},
{
type: 1,
headline: '<p>Loved the bargains?</p>',
text: '<p>We have more coming your way. <strong>Stay tuned!</strong></p>'
},
{
type: 4,
headline: null,
text: '<p><strong>Join us again for a look into the future of tech!</strong></p>'
},
{
type: 4,
headline: null,
text: '<p>Stay tuned for more exclusive unveils</p>'
},
{
type: 1,
headline: '<p><strong>Your Destination for Lifestyle Tips</strong></p>',
text: '<p>Subscribe now and make us your destination for all things lifestyle. See you in the next video!</p>'
},
{
type: 4,
headline: null,
text: '<p><strong>Subscribe for More Fitness Tips</strong></p>'
},
{
type: 4,
headline: null,
text: '<p>Join us again for more fitness tips and workouts. <strong>Subscribe now!</strong></p>'
},
{
type: 4,
headline: null,
headline: '<p>Your Fitness Journey Continues Here</p>',

type: 4,
headline: null,
text: '<p>Join us in the next session for more fitness guidance. Stay tuned!</p>'
},
{
type: 3,
headline: '<p><strong>Your Daily Dose of Laughter Continues Here</strong></p>',
text: null
},
{
type: 1,
headline: '<p>Loved the humor?</p>',
text: '<p>Join us again for more laughter and joy. <strong>Subscribe now!</strong></p>'
},
{
type: 3,
headline: '<p>Your Culinary Journey Continues</p>',
text: null
},
{
type: 1,
headline: '<p>Excited to try more recipes?</p>',
text: '<p>Join us again as your culinary journey continues. Stay tuned for more!</p>'
},
            ],
            showCallToActionContentSuggestion: false,
            contentSuggestionCallToAction:[
  {
    type: 4,
    headline: null,
    text: '<p>Indulge Yourself</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Watch Next</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Your Style Awaits</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>See More Deals</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Shop Now</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Explore More</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Stay Connected</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Get the Look</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>See Product Details</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Claim Your Discount</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Share Your Thoughts</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Sign Up</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Reach Out</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Visit Our Site</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Follow Us</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Order Today</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Don’t Miss Out</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Claim the Offer</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Join Our Newsletter</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>New Collection Alert</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Send a Tip</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Secure Yours Now</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Discover More</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Today’s Special Deal</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Daily Deals</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>See More</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Hot Deal Alert</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Feedback Welcome</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Support Us</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Read the Blog</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Subscribe Now</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Limited Stock</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Check the Description</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Follow Our Journey</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Stay Updated</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Get it First</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>View Portfolio</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>View in Store</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Early Bird Discount</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Shop Exclusive</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Book Now</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Last Chance</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Comment Below</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Find Your Style</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Get the App</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Keep Watching</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Explore the Range</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Find Out More</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Donate Now</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Rate Us</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Learn More</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Stay Tuned</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Unlock Discounts</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Register Here</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Add to Cart</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Visit Our Shop</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Learn More</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Get Involved</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>View More</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Contact Us</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Ready, Set, Shop!</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><em>Grab Yours</em></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Order Now</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Exclusive Offer</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Join the Community</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Like and Share</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Join Us</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Save Big</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Check Out Now</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Download Now</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Customize Yours</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Shop Smart</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Get Yours Now</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Buy Now</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Snag the Deal</strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Limited Time Offer</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong><em>Order and Save</em></strong></p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Explore Further</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p>Shop the Look</p>',
  },
  {
    type: 4,
    headline: null,
    text: '<p><strong>Discover the Collection</strong></p>',
  },
            ]
        }
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName('Outro'));
    },
    methods: {
        getSceneByName(name){
            return this.scenes.find(scene => scene.name === name)
        },
        toggleShowIntroContentSuggestion() {
            this.showIntroContentSuggestion = !this.showIntroContentSuggestion;
            this.togglePopUp();
        },
        updateTheOutroContentSuggestion(selectedContentSuggestion) {
            this.$emit('setActiveTextType', selectedContentSuggestion.type)
            this.$emit('outroHeadlineChanged', selectedContentSuggestion.headline)
            this.$emit('outroTextChanged', selectedContentSuggestion.text)
        },
        togglePopUp() {
            this.$emit('togglePopUp')
        },
        setActiveTextType(value) {
            this.$emit('setActiveTextType', value)
        },
        outroHeadlineChanged(event) {
            this.$emit('outroHeadlineChanged', event.target.value)
        },
        outroTextChanged(event) {
            this.$emit('outroTextChanged', event.target.value)
        },
        toggleCallToActionButton(){
            this.$emit('toggleCallToActionButton')
        },
        setCallToActionText(event){
            this.$emit('setCallToActionText', event.target.value);
        },
        toggleShowCallToActionContentSuggestion(){
            this.togglePopUp();
            this.showCallToActionContentSuggestion = !this.showCallToActionContentSuggestion;
        },
        updateTheContentSuggestion(selectedContent){
            this.$emit('setCallToActionText', selectedContent.text);
        },
        updateOpacity(value) {
            this.$emit('updateOutroImageOpacity', value)
        },
        updateImage(image) {
            this.$emit('updateOutroImage', image);
        },
        deleteImage() {
            this.$emit('updateOutroImage', null);
        },
    }
}
</script>

