<template>
    <div>
        <div class="clear" style="height: 42px"></div>
        <h1>Video Settings</h1>

        <div class="video_settings_block">
            <ol class="video_settings_list">
                <speed-and-timing
                    :activeChild="activeChild"
                    :data="data"
                    @setActiveChild="setActiveChild"
                    @updateVideoSpeed="updateVideoSpeed"
                    @updateVideoTiming="updateVideoTiming"
                />
                <scale
                    :activeChild="activeChild"
                    :data="data"
                    @setActiveChild="setActiveChild"
                    @updateVideoScaleX="updateVideoScaleX"
                    @updateVideoScaleY="updateVideoScaleY"
                />
                <filters
                    :activeChild="activeChild"
                    :data="data"
                    @resetFilters="resetFilters"
                    @setActiveChild="setActiveChild"
                    @updateFiltersBrightness="updateFiltersBrightness"
                    @updateFiltersContrast="updateFiltersContrast"
                    @updateFiltersGrayscale="updateFiltersGrayscale"
                    @updateFiltersHue="updateFiltersHue"
                    @updateFiltersInvert="updateFiltersInvert"
                    @updateFiltersSaturate="updateFiltersSaturate"
                    @updateFiltersSepia="updateFiltersSepia"
                />
                <watermark
                    :activeChild="activeChild"
                    :data="data"
                    :selectedScenes="selectedScenes"
                    @setActiveChild="setActiveChild"
                    @toggleWatermark="toggleWatermark"
                    @updateWatermarkPositionX="updateWatermarkPositionX"
                    @updateWatermarkPositionY="updateWatermarkPositionY"
                    @updateWatermarkScale="updateWatermarkScale"
                />
                <overlay
                    :activeChild="activeChild"
                    :data="data"
                    :selectedScenes="selectedScenes"
                    @setActiveChild="setActiveChild"
                    @toggleOverlay="toggleOverlay"
                    @updateOverlayOpacity="updateOverlayOpacity"
                />
                <border
                    :activeChild="activeChild"
                    :data="data"
                    :selectedScenes="selectedScenes"
                    @setActiveChild="setActiveChild"
                    @toggleBorder="toggleBorder"
                    @updateBorderOpacity="updateBorderOpacity"
                    @updateBorderScale="updateBorderScale"
                    @updateBorderSize="updateBorderSize"
                />
                <sound
                    ref="soundComponent"
                    :activeChild="activeChild"
                    :initialSong="data.sound"
                    @setActiveChild="setActiveChild"
                    @updateActiveSong="updateActiveSong"
                />
            </ol>
        </div>
    </div>
</template>

<script>
import SpeedAndTiming from "./SpeedAndTiming.vue";
import Scale from "./Scale.vue";
import Watermark from "./Watermark.vue";
import Filters from "./Filters.vue";
import Overlay from "./Overlay.vue";
import Border from "./Border.vue";
import Sound from "./Sound.vue";

export default {
    name: "Index",
    components: {
        SpeedAndTiming,
        Scale,
        Watermark,
        Filters,
        Overlay,
        Border,
        Sound
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
        selectedScenes: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data: () => {
        return {
            //audio player
            sound: {
                playSong: {
                    generatedId: 'M57X38',
                    title: 'Silver Waves - TrackTribe',
                    src: 'users/1/songs/63b7e29e16b9b.mp3'
                }
            }
        }
    },
    mounted() {
        this.$emit('setCurrentScene', null);
    },
    unmounted() {
        this.$emit('setActiveChild', 'SpeedAndTiming');
    },
    methods: {
        stopSoundInChild() {
            if (this.$refs.soundComponent) {
                this.$refs.soundComponent.player.isPlaying=false;
                this.$refs.soundComponent.$refs.player.pause();
            }
        },
        setActiveChild(childName) {
            this.$emit('setActiveChild', childName);
            if (['SpeedAndTiming', 'Scale', 'Filters', 'Sound'].includes(childName)) {
                this.$emit('setCurrentScene', null);
            } else {
                this.$emit('setCurrentScene', this.getSceneByName(childName));
            }
        },
        //video speed and timing
        updateVideoSpeed(value) {
            this.$emit('updateVideoSpeed', value);
        },
        updateVideoTiming(timing) {
            this.$emit('updateVideoTiming', timing);
        },
        //video scale
        updateVideoScaleX(value) {
            this.$emit('updateVideoScaleX', value);
        },
        updateVideoScaleY(value) {
            this.$emit('updateVideoScaleY', value);
        },
        //watermark
        toggleWatermark(value) {
            if (value) {
                this.$emit('setCurrentScene', this.getSceneByName('Watermark'));
            } else {
                this.setActiveChild('SpeedAndTiming');
                this.$emit('selectIframe', {type: this.getSceneByName('Watermark').name, iframe: null});
            }
            this.$emit('toggleWatermark', value);
        },
        updateWatermarkScale(value) {
            this.$emit('updateWatermarkScale', value);
        },
        updateWatermarkPositionX(value) {
            this.$emit('updateWatermarkPositionX', value);
        },
        updateWatermarkPositionY(value) {
            this.$emit('updateWatermarkPositionY', value);
        },
        //filters
        updateFiltersBrightness(value) {
            this.$emit('updateFiltersBrightness', value);
        },
        updateFiltersContrast(value) {
            this.$emit('updateFiltersContrast', value);
        },
        updateFiltersGrayscale(value) {
            this.$emit('updateFiltersGrayscale', value);
        },
        updateFiltersHue(value) {
            this.$emit('updateFiltersHue', value);
        },
        updateFiltersInvert(value) {
            this.$emit('updateFiltersInvert', value);
        },
        updateFiltersSaturate(value) {
            this.$emit('updateFiltersSaturate', value);
        },
        updateFiltersSepia(value) {
            this.$emit('updateFiltersSepia', value);
        },
        resetFilters() {
            this.$emit('resetFilters');
        },
        //overlay
        toggleOverlay(value) {
            if (value) {
                this.$emit('setCurrentScene', this.getSceneByName('Overlay'));
            } else {
                this.$emit('selectIframe', {type: this.getSceneByName('Overlay').name, iframe: null});
            }
            this.$emit('toggleOverlay', value);
        },
        updateOverlayOpacity(value) {
            this.$emit('updateOverlayOpacity', value);
        },
        //border
        toggleBorder(value) {
            if (value) {
                this.$emit('setCurrentScene', this.getSceneByName('Border'));
            } else {
                this.setActiveChild('SpeedAndTiming');
                this.$emit('selectIframe', {type: this.getSceneByName('Border').name, iframe: null});
            }
            this.$emit('toggleBorder', value);
        },
        updateBorderSize(value) {
            this.$emit('updateBorderSize', value);
        },
        updateBorderScale(value) {
            this.$emit('updateBorderScale', value);
        },
        updateBorderOpacity(value) {
            this.$emit('updateBorderOpacity', value);
        },
        ///sound
        updateActiveSong(song) {
            this.$emit('updateActiveSong', song);
        },
        //scene
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
    }

}
</script>

<style scoped>

</style>
