<template>
    <div class="rtd_renderww_block">
        <a :href="useLink" class="rtd_renderww_button">
            <div>Use as Template <span class="material-icons">palette</span></div>
        </a>
    </div>
</template>
<script>
export default {
    name: "UseAsTemplate",
    props: {
        animation: {
            type: Object,
            default: () => ({}),
        },
        category: {
            type: Object,
            default: () => ({}),
        },
    },
    computed:{
        useLink(){
            return `${this.category.slug}/${this.animation.name}/use`;
        },
    },
}
</script>
