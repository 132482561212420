<template>
    <div>
        <!--START SETTING THE BACKGROUND -> UPLOAD IMAGE / BACKGROUND COLOR / ETC-->
        <!--START UPLOAD SINGLE IMAGE FORM-->
        <div style="height: 42px" class="clear"></div>

        <text-list
            :texts="data.texts"
            :contentSuggestion="contentSuggestion"
            @togglePopUp="$emit('togglePopUp')"
            @addTextBlock="addTextBlock"
            @deleteTextBlock="deleteTextBlock"
            @setActiveTextType="setActiveTextType"
            @headlineChanged="headlineChanged"
            @textChanged="textChanged"
            @moveUpTextBlock="moveUpTextBlock"
            @moveDownTextBlock="moveDownTextBlock"
            @updateTheContentSuggestion="updateTheContentSuggestion"
        />

        <div style="height: 64px" class="clear"></div>
        <!--START UPLOAD LOGO / AVATAR / IMAGES / PHOTOS / AND MORE-->
        <upload-multiple-images
            :uniqueID="uniqueID"
            @addSelectedImages="addSelectedImages"
            @togglePopUp="$emit('togglePopUp')"
            :deleted-image-index="deletedImageIndex"
            :selected-images="data.images"
        />
        <images-list
            :images="data.images"
            @deleteImage="deleteImage"
            @moveImageLeft="moveImageLeft"
            @moveImageRight="moveImageRight"
            @updateImage="updateImage"
        />

    </div>
</template>

<script>
import UploadMultipleImages from "../../common/UploadMultipleImages.vue";
import ImagesList from "../../common/ImagesList.vue";
import TextList from "../../common/TextList.vue";

export default {
    name: "Index.vue",
    components: {
        ImagesList,
        UploadMultipleImages,
        TextList
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        data: {
            type: Object,
            default: () => {
                return {
                    images: [],
                }
            }
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
    },
    data: () => {
        return {
            deletedImageIndex: null,
            contentSuggestion: [
                {
                    type: 1,
                    headline: '<p><strong>Welcome to the Exploration</strong></p>',
                    text: '<p>Dive in as we uncover the secrets and stories waiting to be discovered.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Your journey of curiosity and wonder starts right here, right now.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Join our bargain hunt as we find the best deals in [Product Category]</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>Your Adventure Begins Here</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Unveiled: The Secrets of [Topic]</p>',
                    text: '<p>Prepare for a journey into the heart of [Topic]. We\'re peeling back the layers, offering you a fresh, unprecedented look at what really lies beneath.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>The Ultimate [Topic] Guide</strong></p>',
                    text: '<p>Join us as we delve deep into the fascinating world of [Topic].</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Products that stand out with uniqueness and quality, all in one place.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Whether you\'re a newbie or an expert, there\'s something here for <strong>everyone</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>A Journey of Discovery Begins</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Behind the Scenes: [Topic] Revealed</strong></p>',
                    text: '<p>Ever wondered what goes on behind the scenes of [Topic]?</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us as we explore the best-selling items in [Product Category]</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Discover what shoppers are loving the most!</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us as we lift the curtain to reveal the hidden workings and untold stories.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Embark on a Journey of Discovery</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss the top-ranked selections in our countdown!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Critics\' Choice Highlights</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Ready to deep dive into [Topic]? </p>',
                    text: '<p>Stay tuned as we explore the lesser-known facts and uncover the secrets that will keep you hooked.</p>'
                },
                {
                    type: 1,
                    headline: '<p>The [Topic] Breakdown</p>',
                    text: '<p>Get ready to break down the world of [Topic], dissecting each detail to bring you the insights you\'ve been craving.</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Exploring the Uncharted: [Topic]</strong></p>',
                    text: '<p>Join us as we navigate the complex and intriguing landscape of [Topic].</p>'
                },
                {
                    type: 3,
                    headline: '<p>The Ultimate Guide to [Topic]</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Your [Topic] Adventure</p>',
                    text: '<p>Step into the world of [Topic] as we guide you through the most fascinating <strong>tales</strong> and facts.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Your adventure into the heart of <strong>[Topic]</strong> starts here.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Dive Deeper with [Topic]</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p><strong>Top 10 [Topic] Revealed</strong></p>',
                    text: '<p>Dive in as we unveil the absolute best in [Category]. Don\'t miss the top-ranked selections in our countdown!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Behind the Magic: A Closer Look</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Behind the Scenes: [Topic] Revealed</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>The Best of [Category]</strong></p>',
                    text: '<p>Join us as we explore the cream of the crop in this exclusive roundup.</p>'
                },
                {
                    type: 3,
                    headline: '<p>The Unveiled Secrets of [Topic]</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Viewer\'s Choice: [Category]</strong></p>',
                    text: '<p>We asked, you voted. Discover the top [Category] as chosen by viewers like you.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Critics\' Choice</strong>: [Category] Highlights</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Trust the experts</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Don\'t miss the top-ranked selections in our countdown!</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>We\'re spotlighting the best [Category] acclaimed by critics.</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p>Tune in for the <strong>top recommendations</strong>!</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss the top-ranked selections in our countdown!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Unlocking the Secrets: A Deeper Dive</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover what shoppers are loving the most!</strong></p>'
                },
                {
                    type: 2,
                    headline: '<p>Hidden Gems: [Category] Unveiled</p>',
                    text: '<p>Delve deeper as we reveal the <strong>hidden gems</strong> in [Category]. </p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>These are the best kept secrets, now brought to light!</strong></p>'
                },
                {
                    type: 1,
                    headline: '<p>Best in Class: [Category]</p>',
                    text: '<p>Welcome to a showcase of the finest selections in <strong>[Category]</strong>. Discover what makes these picks the best in class!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Delve into the Details</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>An Exclusive Behind-the-Scenes Journey</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Embarking on a New Journey</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Top Trends: [Category]</strong></p>',
                    text: '<p>Join us as we unveil the top trends dominating [Category] right now.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover what shoppers are loving the most!</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p>Your Gateway to the <strong>Unknown</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Ultimate Guide: [Category]</p>',
                    text: '<p>Your go-to guide for the best in [Category]. Dive in as we showcase the top picks and industry favorites.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Top Deals: Unmissable [Product Category]</p>',
                    text: '<p>Ready for the best deals in [Product Category]?</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Stay tuned as we unveil the top offers you can\'t resist.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Join us as we unveil the top trends dominating [Category] right now.</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>An In-depth Look: [Topic]</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Ready for the best deals in [Product Category]?</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Best Sellers: [Product Category] Edition</strong></p>',
                    text: '<p>Join us as we explore the best-selling items in <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover what shoppers are loving the most!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Step Inside the Adventure</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Editor\'s Picks: The Best of [Product Category]</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss our editor\'s top picks in <strong>[Product Category]</strong>. </p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Handpicked for quality and value, these are the products you need to see.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Hot Trends: [Product Category]</p>',
                    text: '<p>Tune in to discover the hottest trends in [Product Category].</p>'
                },
                {
                    type: 3,
                    headline: '<p>User Favorites: Top-Rated [Product Category]</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover the [Product Category] that users are raving about.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Prepare for a journey into the heart of [Topic]</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us for a roundup of the top-rated products according to <strong>shoppers like you</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>New Arrivals: Fresh in [Product Category]</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Be the first to explore the fresh arrivals in [Product Category]</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>From the latest releases to must-have items, we\'ve got it all covered.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Find your next favorite product in our latest collection!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Exploring Uncharted Territories</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Welcome to Our Journey</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Handpicked for quality and value, these are the products you need to see.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Limited Time Offers: [Product Category]</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss out on limited-time offers in <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Grab the best deals before time runs out!</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Exclusive Finds: [Product Category] Specials</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover exclusive finds in our <strong>[Product Category] specials</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Products that stand out with uniqueness and quality, all in one place.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Begin Your Adventure with Us</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Must-Have [Product Category]: Don\'t Miss Out</p>',
                    text: '<p>Dive in as we uncover the must-have products in <strong>[Product Category]</strong>. Stay tuned to find your next great purchase!</p>'
                },
                {
                    type: 2,
                    headline: '<p>Season\'s Picks: The Best of [Product Category]</p>',
                    text: '<p>Get into the season\'s spirit with the top picks in [Product Category]. </p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover what\'s trending this season right here!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Diving Deep: A Closer Look</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>The Ultimate [Product Category] Showcase</p>',
                    text: '<p>Welcome to the ultimate showcase of <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Sit back and enjoy as we bring you the crème de la crème of products to shop.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Insider\'s Perspective: The Full Story</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Top-Rated [Product Category]</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p><strong>[Product Category]: Top Recommendations</strong></p>',
                    text: '<p>Looking for the best in [Product Category]? We\'ve got you covered with our top recommendations. Tune in for the details!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>We\'ve got you covered with our top recommendations</p>'
                },
                {
                    type: 1,
                    headline: '<p>Your [Product Category] Wishlist</p>',
                    text: '<p>Dream with us as we explore the wish-list worthy items in <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Find products you didn\'t know you needed until now!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Insider Picks: [Product Category]</strong></p>',
                    text: '<p>Get the insider scoop as we unveil the top picks in [Product Category].</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>These are the products that industry insiders are buzzing about!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Discover the Magic Within</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Discover the Undiscovered</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Sneak Peek: Upcoming [Product Category]</p>',
                    text: '<p>Be the first to get a sneak peek of the upcoming releases in [Product Category].</p>'
                },
                {
                    type: 1,
                    headline: '<p>Bargain Hunt: The Best Deals in [Product Category]</p>',
                    text: '<p>Join our bargain hunt as we find the best deals in [Product Category].</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover what shoppers are loving the most!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get ready for unbeatable prices and fantastic value!</strong></p>'
                },
                {
                    type: 2,
                    headline: '<p>Thanks for watching!</p>',
                    text: '<p>Stay tuned for more exciting content. Remember to like, share, and <strong>subscribe</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p>We hope you enjoyed this video!</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Until next time, take care and <strong>stay awesome!</strong></p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Are you a tech enthusiast?</strong></p>',
                    text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Have thoughts on this video?</p>',
                    text: '<p>Join the conversation in the comments below. We\'d love to hear from you!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Subscribe</strong> and be the first to enjoy exclusive previews in our upcoming video.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Keep Exploring</strong></p>',
                    text: '<p>Hungry for more knowledge? Keep exploring with us in our next video. See you there!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Loved the products in this video?</p>',
                    text: '<p>Shop the look by clicking the <strong>links in the description below</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Get the Deals</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Don\'t miss out on great deals.</p>',
                    text: '<p>Check out the links below to grab the best offers on the products featured today.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Travel with Us</strong></p>',
                    text: '<p>Join us on our next adventure. Subscribe now to travel the world from the comfort of your home.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Subscribe now to become the <strong>ultimate smart shopper</strong> with insights from our next video.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Looking to save?</p>',
                    text: '<p>Stay tuned for our selection of budget-friendly picks in the upcoming video.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Bon Appetit</strong></p>',
                    text: '<p>We hope you try this recipe at home. Bon appétit and happy cooking!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Inspired to create?</strong></p>',
                    text: '<p>Join us in our next video for more DIY projects and crafting fun.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Had a good laugh?</p>',
                    text: '<p><strong>Hit subscribe to keep the laughter rolling!</strong> See you in the next video.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Craving more entertainment?</p>',
                    text: '<p>Like, share, and <strong>stay tuned for the encore</strong> in our upcoming videos!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Loved the workout? Stay Fit!</strong></p>',
                    text: '<p>Keep pushing your limits and stay fit with us. Don\'t forget to subscribe for more fitness inspiration!</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Sweat & Smile</strong></p>',
                    text: '<p>Thanks for sweating it out with us today! Keep smiling and stay tuned for more fun workouts.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Hit subscribe to keep the <strong>laughter rolling</strong>!</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Stay gorgeous and keep shining!</p>',
                    text: '<p>Check out the links below for all the products featured today.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Like, share, and stay tuned for the encore in our upcoming videos!</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Bon appétit and happy cooking!</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t forget to subscribe for more fitness inspiration!</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Keep the style game strong!</strong></p>',
                    text: '<p>See you in the next video for more fashion-forward tips and trends.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Enjoyed the laughter?</p>',
                    text: '<p>There\'s more where that came from. Subscribe now for more joyous moments.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Tech It Out</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Enjoyed our tech insights?</p>',
                    text: '<p>Subscribe to tech it out with us in the upcoming video!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>See you in the next video for more fashion-forward tips and trends.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Stay tuned for our <strong>next exclusive unveil</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Stay tuned for more tech updates!</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Become a gadget guru with our expert reviews and tips.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Thanks for gaming with us! Remember to <strong>like, share, and hit the subscribe button</strong> to join us in the next gaming adventure.</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Ready to level up?</strong></p>',
                    text: '<p>Stay tuned for more epic gaming content coming your way!</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Booked for More</strong></p>',
                    text: '<p>Can\'t wait to share more book gems with you. Stay booked for more in our <strong>next video</strong>!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Subscribe now to travel the world from the comfort of your home.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Keep the pages turning!</p>',
                    text: '<p>Join us next time as we delve into another fantastic read.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Keep Laughing and Stay Tuned</strong></p>',
                    text: '<p>Enjoyed the laughter? There\'s more where that came from. Subscribe now for more joyous moments.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Hit subscribe to keep the laughter rolling!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Remember to like, share, and <strong>subscribe</strong>!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Your Daily Dose of Fitness Awaits</strong></p>',
                    text: '<p>Keep up the great work! Join us in the next session to continue your fitness journey.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Subscribe to stay updated!</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Subscribe now for more joyous moments.</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Keep smiling and stay tuned for more fun workouts.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Create with Us</strong></p>',
                    text: '<p>Join us in our next video for more DIY projects and crafting fun.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Join Us Again for More Tech Insights</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Are you a tech enthusiast?</p>',
                    text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Come Back for More Style and Elegance</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Remember to like, share, and hit the subscribe button to join us in the next gaming adventure.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Subscribe now for more joyous moments</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Love fashion? So do we!</p>',
                    text: '<p>Come back for more style tips and fashion revelations in our next video.</p>'
                },
                {
                    type: 2,
                    headline: '<p>The Adventure Continues: Travel with Us</p>',
                    text: '<p>Your passport to the world\'s most wonderful places awaits. Join us in the next video as the adventure continues.</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Delicious Journeys Await: Bon Appétit</strong></p>',
                    text: '<p>We have more delectable recipes to share with you. Stay tuned for more culinary journeys.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Your Daily Dose of Fitness Awaits</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>More Gaming Adventures Await You</p>',
                    text: '<p>Enjoyed the gaming session? We have more adventures lined up for you. Stay tuned and game on!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Stay Tuned for More Video-Turning Discussions</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Are you a tech enthusiast?</p>',
                    text: '<p>Don\'t miss our deep dives into the latest gadgets. Stay tuned!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Book lovers, we have more engaging discussions coming up in our next video. Keep the pages turning!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Your Destination for Unbeatable Deals</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Eager for more great deals?</strong></p>',
                    text: '<p>Stay tuned, your destination for unbeatable deals and offers is right here.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Discover More Hidden Gems in Our Next Video</strong></p>',
                    text: '<p>Can\'t wait to find the next hidden gem? Join us again as we unveil more treasures in our upcoming videos.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t Miss the Next Exclusive Unveiling</p>'
                },
                {
                    type: 3,
                    headline: '<p>Stay tuned and game on!</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Stay tuned for more exclusive product unveils in our upcoming videos.</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p>Subscribe to get the first look at the hottest deals!</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Become the Ultimate Smart Shopper with Us</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us in the next video as the adventure continues</p>'
                },
            ],
        }
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName('Text to Video Data'));
    },
    methods: {
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
        addSelectedImages(images,index, replace_images = null) {
            this.$emit('addSelectedImages', images, replace_images)
        },
        deleteImage(index) {
            this.deletedImageIndex = index===this.deletedImageIndex?String(index):index
            this.$emit('deleteDataImage', index)
        },
        moveImageLeft(index) {
            this.$emit('moveImageLeft', index)
        },
        moveImageRight(index) {
            this.$emit('moveImageRight', index)
        },
        updateImage(image, index) {
            this.$emit('updateImage', image, index)
        },
        addTextBlock(data) {
            this.$emit('addTextBlock', data)
        },
        deleteTextBlock(index) {
            this.$emit('deleteTextBlock', index)
        },
        moveUpTextBlock(index) {
            this.$emit('moveUpTextBlock', index)
        },
        moveDownTextBlock(index) {
            this.$emit('moveDownTextBlock', index)
        },
        setActiveTextType(value, index) {
            this.$emit('setActiveTextType', value, index)
        },
        headlineChanged(event, index) {
            this.$emit('headlineChanged', event.target.value, index)
        },
        textChanged(event, index) {
            this.$emit('textChanged', event.target.value, index)
        },
        updateTheContentSuggestion(data, index) {
            this.$emit('updateTheContentSuggestion', data, index)
        },
    }
}
</script>

<style scoped>

</style>
