<template>
    <div class="rtd_renderww_block rtd_renderww_block_template">
        <a href="#"
           class="rtd_renderww_button"
           :class="{'videoIsTemmplate': isTemplate}"
           @click.prevent="toggleTemplate"
        >
            <div> </div>
        </a>
    </div>
</template>
<script>
export default {
    name: "SetAsTemplate",
    props: {
        animation: {
            type: Object,
            default: () => ({}),
        }
    },
    methods: {
        toggleTemplate() {
            this.$emit('toggleTemplate');
        }
    },
    computed: {
        isTemplate() {
            return Boolean(this.animation.isTemplate);
        }
    }
}
</script>
