<template>
    <div>
        <upload-and-image-gallery
            :title="title"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateImage="updateImage"
            :logo-deleted="imageDeleted"
            :image="image"


        />


        <!--START BACKGROUND IMAGE SETTINGS-->
        <!--FLORINXXX -> ADD .none WHEN THE PROFILE HAS NO BACKGROUND IMAGE-->
        <div class="editor_block editor_block_logo"
             v-if="image"
        >
            <div class="editor_logo_container_left">
                <div class="editor_logo_display"
                     :class="{isRotating: isRotating}"
                >
                    <!--FLORINXXX -> UPDATE BACKGROUND IMAGE COLOR #hex (DEFAULT IS FIRST COLOR FROM THE PROFILE) OR ADD none-->
                    <!--FLORINXXX -> UPDATE BACKGROUND IMAGE VALUE TO STYLE (I USED 25 TO UNDERSTAND)-->
                    <!--FLORINXXX -> .editor_img_button_delete DELETE THE IMAGE-->

                    <div
                        :style="{'background-image': 'url('+imageSrc+')',opacity:((100-opacity)/100)}"
                        class="editor_bgimage_display">
                    </div>

                    <div class="editor_img_rotate_preloader">
                        <div class="lds-ring lds-ring6">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>

                    <div
                        class="editor_img_button_rotateleft"
                        title="Rotate left"
                        @click="rotateImageLeft(imageSrc)"
                    >
                        <span class="material-icons">
                            rotate_left
                        </span>
                    </div>
                    <div
                        class="editor_img_button_rotateright"
                        title="Rotate right"
                        @click="rotateImageRight(imageSrc)"
                    >
                        <span class="material-icons">
                            rotate_right
                        </span>
                    </div>
                    <div class="editor_img_button_delete"
                         title="Delete image"
                         @click="deleteImage"
                    ><span class="material-icons">clear</span>
                    </div>
                </div>
            </div>
            <div class="editor_logo_container_right">
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="bgOpacity"><span>Transparency</span></label>
                    <custom-input
                        :value="opacity"
                        :id="'Opacity'"
                        :min="0"
                        :max="100"
                        :increment="5"
                        :unit="'%'"
                        @updateInput="updateOpacity"
                    />
                </div>

            </div>
        </div>
        <!--END SETTING THE BACKGROUND -> UPLOAD IMAGE / BACKGROUND COLOR / ETC-->
    </div>
</template>

<script>
import UploadAndImageGallery from "./UploadAndImageGallery.vue";
import CustomInput from "./CustomInput.vue";
import LogoUpload from "../components/Profile/Logo/Upload.vue";

export default {
    name: "UploadSingleImageWithOpacity",
    components: {
        LogoUpload,
        CustomInput,
        UploadAndImageGallery
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Upload Background Image'
        },
        image: {
            type: String,
            default: 'https://images.unsplash.com/photo-1522441815192-d9f04eb0615c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGJhY2tncm91bmR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60'
        },
        opacity: {
            type: Number,
            default: 75
        },
        backgroundColor: {
            type: String,
            default: null
        }
    },
    data: () => {
        return {
            isRotating: false,
            imageDeleted:false
        }
    },
    computed: {
        imageSrc() {
            if (this.image.includes('unsplash.com') || this.image.includes('https://storage.pixteller.com/')) {
                return this.image;
            } else {
                return 'https://storage.renderlion.com/' + this.image;
            }
        }
    },
    methods: {
        togglePopUp() {
            this.$emit('togglePopUp');
        },
        updateImage(image) {
            this.$emit('updateImage', image);
            this.setImageDeleted(false)

        },
        updateOpacity(value) {
            this.$emit('updateOpacity', value)
        },
        deleteImage() {
            this.$emit('updateImage', null);
            this.setImageDeleted(true)

        },
        setImageDeleted(imageDeleted) {
            this.imageDeleted = imageDeleted;
        },
        rotateImageLeft(imageSrc) {
            this.isRotating = true;
            axios.post('/api/resources/rotate-image',
                {
                    url: imageSrc,
                    orientation: 'left'
                }
            ).then((response) => {
                //stop the preloader and empty the files
                this.$emit('updateImage', response.data);
                this.isRotating = false;
            }).catch((error) => {

            });
        },
        rotateImageRight(imageSrc) {
            this.isRotating = true;

            axios.post('/api/resources/rotate-image',
                {
                    url: imageSrc,
                    orientation: 'right'
                }
            ).then((response) => {
                //stop the preloader and empty the files
                this.$emit('updateImage', response.data);
                this.isRotating = false;
            }).catch((error) => {

            });
        },
    }
}
</script>

<style scoped>

</style>
