<template>
    <div class="input_slider_block">
        <div class="input_numb_block">
            <input :id="id"
                   v-model="localValue"
                   class="input_numb"
                   type="number"
                   @change="onInputChange"
            >
            <label :for="id" class="input_numb_label_unit">{{ unit }}</label>
            <div class="input_numb_block_selection"></div>
            <div class="input_numb_button input_numb_button_minus" @click="decrementValue"></div>
            <div class="input_numb_button input_numb_button_plus" @click="incrementValue"></div>
        </div>
        <div class="filter_slider_block">
            <div class="slider_bar"></div>
            <div :style="{ left: ( (value-min) / (max-min) ) * 100 + '%' }"
                 class="slider_button"
                 @mousedown="startDragging"
                 @touchend="stopDragging"
                 @touchmove="updateValueFromTouchMove"
                 @touchstart="startDragging">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputSlider',
    props: {
        id: {
            type: String,
            default: 'input_slider'
        },
        value: {
            type: Number,
            default: 100
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        increment: {
            type: Number,
            default: 5
        },
        unit: {
            type: String,
            default: '%'
        }
    },
    data() {
        return {
            isDragging: false,
            lastMouseX: null,
            temporaryValue: null,
            localValue: this.value,
        }
    },
    watch: {
        value(newValue, oldValue) {
             this.localValue = newValue;
        },
        localValue(newValue,oldValue) {
            let value = newValue;
            if (value > this.max) {
                value = this.max;
            } else if (value < this.min) {
                value = this.min;
            }
            this.$emit('onInputChange',value);
        },
    },
    methods: {
        onInputChange(e) {
            const newValue = parseFloat(e.target.value);
            if (!isNaN(newValue) && isFinite(newValue)) {
                if (newValue > this.max) {
                    this.localValue = this.max;
                } else if (newValue < this.min) {
                    this.localValue = this.min;
                } else {
                    this.localValue = newValue;
                }
            }
        },
        incrementValue() {
            if (this.localValue < this.max) {
                if (this.max > (this.localValue + this.increment)) {
                    this.localValue += this.increment;
                } else {
                    this.localValue = this.max;
                }
            }
        },
        decrementValue() {
            if (this.localValue > this.min) {
                if ((this.localValue - this.increment) > this.min) {
                    this.localValue -= this.increment;
                } else {
                    this.localValue = this.min;
                }
            }
        },
        startDragging() {
            event.stopPropagation();
            this.isDragging = true;
            window.addEventListener("mousemove", this.updateValueFromMouseMove);
            window.addEventListener("mouseup", this.stopDragging);
        },
        stopDragging() {
            this.isDragging = false;
            window.removeEventListener("mousemove", this.updateValueFromMouseMove);
            window.removeEventListener("mouseup", this.stopDragging);
        },
        updateValueFromMouseMove(event) {
            if (this.isDragging) {
                const sliderBar = this.$el.querySelector(".slider_bar");
                const mouseX = event.clientX - sliderBar.getBoundingClientRect().left;
                let percentage = (mouseX / sliderBar.clientWidth) * (this.max - this.min) + this.min;
                if (percentage < this.min) {
                    percentage = this.min;
                } else if (percentage > this.max) {
                    percentage = this.max;
                }
                this.localValue = Math.round(percentage);
            }
        },
        updateValueFromTouchMove(event) {
            if (this.isDragging) {
                const sliderBar = this.$el.querySelector('.slider_bar');
                const touch = event.touches[0] || event.changedTouches[0];
                const touchX = touch.clientX - sliderBar.getBoundingClientRect().left;
                let percentage = (touchX / sliderBar.clientWidth) * (this.max - this.min) + this.min;
                if (percentage < this.min) {
                    percentage = this.min;
                } else if (percentage > this.max) {
                    percentage = this.max;
                }
                this.localValue = Math.round(percentage);
            }
        },
    }
}
</script>

