<template>
    <div class="platform_ratio">
        <div class="editor_ratio_title">Display as</div>
        <div class="editor_ratio_block clr">
            <ul class="ratio_list clr">
                <li>
                    <div class="ratio_button ratio_button_square no_refresh"
                         :class="{'active': aspectRatio==='square'}"
                         @click="changeAspectRatio('square')"
                         title="Square">
                        <div class="radio_c radio_c1"></div>
                        <div class="radio_c radio_c2"></div>
                        <div class="radio_c radio_c3"></div>
                        <div class="radio_c radio_c4"></div>
                        <span>1:1</span>
                    </div>
                </li>
                <li>
                    <div class="ratio_button ratio_button_portrait no_refresh"
                         :class="{'active': aspectRatio==='portrait'}"
                         @click="changeAspectRatio('portrait')"
                         title="Portrait">
                        <div class="radio_c radio_c1"></div>
                        <div class="radio_c radio_c2"></div>
                        <div class="radio_c radio_c3"></div>
                        <div class="radio_c radio_c4"></div>
                        <span>9:16</span>
                    </div>
                </li>
                <li>
                    <div class="ratio_button ratio_button_landscape no_refresh"
                         :class="{'active': aspectRatio==='landscape'}"
                         @click="changeAspectRatio('landscape')"
                         title="Landscape">
                        <div class="radio_c radio_c1"></div>
                        <div class="radio_c radio_c2"></div>
                        <div class="radio_c radio_c3"></div>
                        <div class="radio_c radio_c4"></div>
                        <span>16:9</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</template>
<script>
export default {
    name: "FilterAspectRatio",
    props: {
        aspectRatio: {
            type: String,
            default: 'square'
        }
    },
    methods: {
        changeAspectRatio(aspectRatio) {
            this.$emit('changeAspectRatio', aspectRatio)
        }
    }
}
</script>

<style scoped>

</style>
