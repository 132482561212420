<template>
    <div class="vid_data_primary vid_data_primary_visit" v-if="isGuest">
        <ul class="register_to_datalist clr">
            <li>
                <div class="register_to_download">
                    <div class="register_to_button register_to_download_button">
                        <div><span class="material-icons">arrow_drop_down</span> Download</div>
                    </div>
                </div>
            </li>
            <li>
                <div class="register_to_use">
                    <div class="register_to_button register_to_use_button">
                        <div><span class="material-icons">color_lens</span> Use</div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="register_to_message">
            <p>Downloading or using this animation<br/> is just a few clicks away.</p>
            <ul>
                <li><a href="/login">Login</a></li>
                <li><a href="/register">Register</a></li>
            </ul>
        </div>
    </div>
    <div class="vid_data_primary vid_data_primary_owner" v-else-if="animationStatus==='rendered' && !showLimitReachedPopUp">
        <div class="owner_rendered_block" v-if="rendered">
            <a :href="videoDownloadLink" class="rtd_download_button"
               download
               :class="{ 'disabled-btn': isDisabledDownload }"
               @click="disableDownloadLink"
            >
                <div>
                    Download {{ uppercaseAspectRatio }} Video
                    <span class="material-icons is_off">file_download</span>
                </div>
            </a>
        </div>
    </div>
    <div v-if="isAdmin && ['rendered','not-rendered'].includes(animationStatus) && !showLimitReachedPopUp">
        <div class="owner_rendered_block" v-if="!rendered">
            <a href="#" class="rtd_download_button"
               @click.prevent="triggerRender('paid')"
               :class="{ 'disabled-btn': isDisabledRender }">
                <div>Render without Watermark <span class="material-icons">whatshot</span></div>
            </a>
        </div>
        <div class="rtd_renderww_block" v-if="renderedWithWatermark">
            <a href="#" class="rtd_renderww_button"
               @click.prevent="triggerRender('paid')"
               :class="{ 'disabled-btn': isDisabledRender }">
                <div>Render without Watermark <span class="material-icons">whatshot</span></div>
            </a>
        </div>
        <div class="rtd_renderww_block" v-if="renderedWithoutWatermark || !rendered">
            <a href="#" class="rtd_renderww_button"
               @click.prevent="triggerRender()"
               :class="{ 'disabled-btn': isDisabledRenderWatermark }">
                <div>Render with Watermark <span class="material-icons">whatshot</span></div>
            </a>
        </div>
    </div>
    <div v-if="isOwner && !isAdmin && ['rendered','not-rendered'].includes(animationStatus) && !showLimitReachedPopUp">
        <div class="rtd_renderww_block" v-if="renderedWithWatermark && owner.has_credits">
            <a href="#" class="rtd_renderww_button"
               @click.prevent="triggerRender('paid')"
               :class="{ 'disabled-btn': isDisabledRender }">
                <div>Render without Watermark <span class="material-icons">whatshot</span></div>
            </a>
        </div>

        <div class="rtd_renderww_block" v-else-if="animationStatus==='rendered' && !owner.has_credits">
            <a href="/pricing" class="rtd_renderww_button">
                <div>Render without Watermark <span class="material-icons">whatshot</span></div>
            </a>
        </div>

        <div class="vid_data_primary vid_data_primary_owner" v-else-if="animationStatus==='not-rendered'">
            <div class="rtd_block">
                <a href="#"
                   class="rtd_render_button"
                   :class="{ 'disabled-btn': owner.has_credits ? isDisabledRender : isDisabledRenderWatermark}"
                   @click.prevent="triggerRender(owner.has_credits ? 'paid' : 'watermark')"
                >
                    <div>Render to Download <span class="material-icons">whatshot</span></div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Rendered",
    emits: ['triggerRender'],
    props: {
        isAdmin: {
            type: [Number, Boolean],
        },
        isGuest: {
            type: Boolean,
            default: true,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        owner: {
            type: Object,
            default: () => ({})
        },
        aspectRatio: {
            type: String,
            default: 'square',
        },
        animationStatus: {
            type: String,
            default: 'not-rendered',
        },
        animation: {
            type: Object,
            default: () => ({})
        },
        areRenderingSpotsAvailable: {
            type: Boolean,
            default: false,
        },
        showLimitReachedPopUp: {
            type: Boolean,
            default: false,
        },
        reactivityTrigger: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            isDisabledDownload: false, // Controls the disabled-btn class
            isDisabledRender: false,
            isDisabledRenderWatermark: false,
            isDisabledRenderTimoutId: null,
        };
    },
    computed: {
        uppercaseAspectRatio() {
            return this.aspectRatio.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('-');
        },
        videoDownloadLink() {
            return 'download/' + this.animation.name + '/' + this.aspectRatio;
        },
        renderedWithoutWatermark() {
            const path = this.animation[this.aspectRatio + '_path'];
            return path && path.includes('storage.renderlion.com');
        },
        renderedWithWatermark() {
            const path = this.animation[this.aspectRatio + '_path'];
            return path && !path.includes('storage.renderlion.com');
        },
        rendered() {
            return this.animation[this.aspectRatio + '_path'] !== '' && this.animationStatus === 'rendered';
        }
    },
    mounted() {
        // console.log(this.animation);
        // console.log('Renderd::' + this.animationStatus)
        // console.log('renderedWithoutWatermark::' + this.renderedWithoutWatermark);
        // console.log('renderedWithWatermark::'+ this.renderedWithWatermark);
        // console.log('render_path::'+ this.animation[this.aspectRatio + '_path']);
    },
    watch: {
        aspectRatio() {
            this.isDisabledRender = false;
            this.isDisabledRenderWatermark = false;
            this.isDisabledDownload = false;
        },
        showLimitReachedPopUp() {
            this.isDisabledRender = false;
        }
    },
    methods: {
        triggerRender(renderType = 'watermark') {
            this.disableRenderLink(renderType);
            this.$emit('triggerRender', renderType);
        },
        disableDownloadLink() {
            // Immediately disable the link
            this.isDisabledDownload = true;

            // Enable the link after 5 seconds
            setTimeout(() => {
                this.isDisabledDownload = false;
            }, 5000); // 5000 milliseconds = 5 seconds
        },
        disableRenderLink(type = 'watermark') {
            // Determine the target based on the type
            const target = type === 'watermark' ? 'isDisabledRenderWatermark' : 'isDisabledRender';

            // Immediately disable the link
            this[target] = true;

            // Clear any existing timeout to prevent overlaps
            clearTimeout(this.isDisabledRenderTimoutId);

            // Enable the link after 6 seconds (as per the corrected comment)
            this.isDisabledRenderTimoutId = setTimeout(() => {
                this[target] = false;
            }, 6000); // Corrected the comment to match the code (6 seconds)
        }
    }
}
</script>
