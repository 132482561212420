<template>
    <div class="popup_block active">
        <div class="popup_container">
            <div
                class="popup_close_button"
                title="Close"
                @click="toggleShowPricingSuggestion()"
            >
                <div><span class="material-icons">close</span></div>
            </div>

            <div class="popup_content">
                <h2>Pricing Suggestion</h2>
                <div class="txts_block">
                    <ul>
                        <li v-for="(pricing, index) in pricingSuggestion" @click="selectPricingSuggestion(index)">
                            <div class="txts_button" :class="{active: index === selectedIndex}">
                                <div class="txts_title_block" v-if="pricing.new_price" v-html="pricing.new_price"></div>
                                <div class="txts_text_block" v-if="pricing.old_price" v-html="pricing.old_price"></div>
                                <div class="txts_text_block" v-if="pricing.discount" v-html="pricing.discount"></div>
                                <div class="txts_text_block" v-if="pricing.price" v-html="pricing.price"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="popup_footer">
                <div class="popup_footer_block">
                    <!--FLORINXXXX -> ON CLICK .popup_button_update -> UPDATE-->
                    <!--FLORINXXXX -> ADD/REMOVE .disable ACCORDING-->
                    <div class="popup_button_update"
                         :class="{disable:selectedIndex===null}"
                         @click="updateThePricingSuggestion"
                    >
                        <div style="padding-right: 12px;">
                            Update
                            <span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span>
                        </div>
                    </div>
                    <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                    <div class="popup_button_cancel"
                         @click="toggleShowPricingSuggestion()"
                    >
                        <div style="padding-left: 16px;">
                            Cancel
                            <span class="material-icons" style="top: 14px; left: -10px;">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PricingSuggestion",
    props: {
        pricingSuggestion: {
            type: Array,
            data: () => {
                return [];
            }
        },
        index: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selectedPricingSuggestion: null,
            selectedIndex: null
        }
    },
    methods: {
        selectPricingSuggestion(index) {
            this.selectedPricingSuggestion = this.pricingSuggestion[index];
            this.selectedIndex = index;
        },
        toggleShowPricingSuggestion() {
            this.$emit('toggleShowPricingSuggestion')
        },
        updateThePricingSuggestion() {
            if (this.selectedIndex === null) {
                return;
            }
            if (this.index !== null) {
                this.$emit('updateThePricingSuggestion', this.selectedPricingSuggestion, this.index)
            } else {
                this.$emit('updateThePricingSuggestion', this.selectedPricingSuggestion)
            }
            this.$emit('toggleShowPricingSuggestion')
        }
    }
}
</script>

<style scoped>

</style>
