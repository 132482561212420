<template>
    <div class="vid_pending_render mt-6">
        Safari Spot
        <strong>{{ pendingSpot }}</strong>
        <div class="lds-ring lds-ring4-superwhite">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Pending",
    props: {
        animation: {
            type: Object,
            default: () => ({}),
        },
        aspectRatio: {
            type: String,
            default: 'square',
        },
        pendingSpot: {
            type: Number,
            default: 10,
        },
        reactivityTrigger: {
            type: Number,
            default: 0,
        },
    },

}
</script>
