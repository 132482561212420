<template>
    <!--START POPUP -> COLOR PALETTE-->
    <!--FLORINXXXX -> ADD .active ON .popup_block TO ACTIVATE POPUP-->
    <!--FLORINXXXX -> ON CLICK .popup_close_button CLOSE POPUP -> REMOVE .active-->
    <div class="popup_block active">
        <div class="popup_container userselect">
            <div class="popup_close_button" title="Close" @click="$emit('togglePaletteSelector')">
                <div><span class="material-icons">close</span></div>
            </div>

            <div class="popup_content">
                <h2>Color Palette</h2>

                <div class="cpalette">
                    <ul class="clr">
                        <li v-for="(palette, index) in palettes" :key="index" @click="setActivePalette(index)">
                            <div class="cpalette_button" :class="{ active: index == activePaletteIndex }">
                                <span class="cpalette_title"></span>
                                <!--C1-->
                                <div :style="{'background':palette.background}" class="cpalette_block">

                                    <!--C2-->
                                    <div :style="{'color':palette.text}" class="material-icons cpalette_c2">play_arrow</div>
                                    <!--C2-->
                                    <div :style="{'background':palette.text}" class="cpalette_t1"></div>
                                    <!--C2-->
                                    <div :style="{'background':palette.text}" class="cpalette_t2"></div>
                                    <!--C2-->
                                    <div :style="{'background':palette.text}" class="cpalette_t3"></div>

                                    <!--C3-->
                                    <div :style="{'background':palette.elements}" class="cpalette_c cpalette_c3"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>

            <div class="popup_footer">
                <div class="popup_footer_block">
                    <!--FLORINXXXX -> ON CLICK .popup_button_update -> UPDATE-->
                    <div class="popup_button_update" @click="updateColors">
                        <div style="padding-right: 12px;">
                            <strong>Update</strong>
                            <span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span>
                        </div>
                    </div>
                    <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                    <div class="popup_button_cancel" @click="$emit('togglePaletteSelector')">
                        <div style="padding-left: 16px;">
                            Cancel
                            <span class="material-icons" style="top: 14px; left: -10px;">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--START POPUP -> COLOR PALETTE-->
</template>

<script>
export default {
    name: "PaletteSelector",
    props: {
        palettes: {
            type: Array,
            default: () => [
{
    background: '#FF1493',
    text: '#FFFFFF',
    elements: '#911D5B'
},
{
    background: '#0091ad',
    text: '#fff4e4',
    elements: '#6efafb'
}, {
    background: '#3b5998',
    text: '#ffffff',
    elements: '#dfe3ee'
}, {
    background: '#F8F7F8',
    text: '#A92858',
    elements: '#E598BB'
}, {
    background: '#484064',
    text: '#F2E2D5',
    elements: '#8D8A9F'
}, {
    background: '#EA938E',
    text: '#E04863',
    elements: '#F4FBF9'
}, {
    background: '#DBB353',
    text: '#E6F1F2',
    elements: '#9F9274'
}, {
    background: '#898891',
    text: '#F7F7EF',
    elements: '#E85844'
}, {
    background: '#F2EDE6',
    text: '#123149',
    elements: '#5DA6BD'
}, {
    background: '#16151D',
    text: '#726B7C',
    elements: '#F9F9F8'
}, {
    background: '#62AACC',
    text: '#F7F8F9',
    elements: '#1E1B3B'
}, {
    background: '#9AAED8',
    text: '#3B51D5',
    elements: '#F8F6F8'
}, {
    background: '#F8F7F8',
    text: '#A92858',
    elements: '#E598BB'
}, {
    background: '#484064',
    text: '#F2E2D5',
    elements: '#8D8A9F'
}, {
    background: '#EA938E',
    text: '#E04863',
    elements: '#F4FBF9'
}, {
    background: '#DBB353',
    text: '#E6F1F2',
    elements: '#9F9274'
}, {
    background: '#898891',
    text: '#F7F7EF',
    elements: '#E85844'
}, {
    background: '#F2EDE6',
    text: '#123149',
    elements: '#5DA6BD'
}, {
    background: '#16151D',
    text: '#726B7C',
    elements: '#F9F9F8'
}, {
    background: '#62AACC',
    text: '#F7F8F9',
    elements: '#1E1B3B'
}, {
    background: '#9AAED8',
    text: '#3B51D5',
    elements: '#F8F6F8'
}, {
    background: '#E8F5E9',
    text: '#555555',
    elements: '#4CAF50'
}, {
    background: '#FFF3E0',
    text: '#666666',
    elements: '#FFC107'
}, {
    background: '#F44336',
    text: '#FFFFFF',
    elements: '#03A9F4'
}, {
    background: '#4CAF50',
    text: '#FFFFFF',
    elements: '#FF4081'
}, {
    background: '#E91E63',
    text: '#FFFFFF',
    elements: '#00BCD4'
}, {
    background: '#8BC34A',
    text: '#FFFFFF',
    elements: '#FF5722'
}, {
    background: '#009688',
    text: '#FFFFFF',
    elements: '#FF9800'
}, {
    background: '#FDE9E9',
    text: '#4A148C',
    elements: '#FF5722'
}, {
    background: '#FFF3E0',
    text: '#BF360C',
    elements: '#FFD54F'
}, {
    background: '#E6EE9C',
    text: '#1B5E20',
    elements: '#8BC34A'
}, {
    background: '#FCE4EC',
    text: '#880E4F',
    elements: '#E91E63'
}, {
    background: '#DCEDC8',
    text: '#1B5E20',
    elements: '#4CAF50'
}, {
    background: '#FFFFFF',
    text: '#000000',
    elements: '#808080'
}, {
    background: '#ECEFF1',
    text: '#222222',
    elements: '#CCCCCC'
}, {
    background: '#222222',
    text: '#FFFFFF',
    elements: '#A6A6A6'
}, {
    background: '#111111',
    text: '#FFFFFF',
    elements: '#888888'
}, {
    background: '#FF6347',
    text: '#FFFFFF',
    elements: '#47B8FF'
}, {
    background: '#FFD700',
    text: '#333333',
    elements: '#00BFFF'
}, {
    background: '#FF8C00',
    text: '#FFFFFF',
    elements: '#008CFF'
}, {
    background: '#8A2BE2',
    text: '#FFFFFF',
    elements: '#2BE28A'
}, {
    background: '#FF4500',
    text: '#FFFFFF',
    elements: '#4500FF'
}, {
    background: '#F9F5F0',
    text: '#231F20',
    elements: '#336B87'
}, {
    background: '#FFFDF9',
    text: '#2C2926',
    elements: '#E9C46A'
}, {
    background: '#FDFBF7',
    text: '#272422',
    elements: '#F29F05'
},

// Pastel Paradise
{
    background: '#FADBD8',
    text: '#555555',
    elements: '#FFCBA4'
}, 

// Vibrant Fusion
{
    background: '#FF5733',
    text: '#FFFFFF',
    elements: '#FFD700'
}, 

// Serene Serenade
{
    background: '#E6E6FA',
    text: '#333333',
    elements: '#9ACD32'
}, 

// Earthy Elegance
{
    background: '#D2B4DE',
    text: '#333333',
    elements: '#7DCEA0'
},

// Midnight Oasis
{
    background: '#2C3E50',
    text: '#FFFFFF',
    elements: '#3498DB'
}, 

// Autumn Harvest
{
    background: '#F39C12',
    text: '#FFFFFF',
    elements: '#E74C3C'
},

// Coastal Breeze
{
    background: '#EAF2F8',
    text: '#333333',
    elements: '#5DADE2'
}, 

// Enchanting Twilight
{
    background: '#EEEFF2',
    text: '#333333',
    elements: '#B53471'
}, {
    background: '#E6E6E6',
    text: '#222222',
    elements: '#FF416C'
},

// Retro Pop
{
    background: '#FFC300',
    text: '#333333',
    elements: '#FF5733'
}, 


// Pastel Paradise
{
    background: '#F6D8AE',
    text: '#333333',
    elements: '#F7CAC9'
}, 

// Vibrant Fusion
{
    background: '#FF4136',
    text: '#FFFFFF',
    elements: '#FF851B'
}, 


// Midnight Oasis
{
    background: '#17202A',
    text: '#FFFFFF',
    elements: '#7D6608'
}, 

// Autumn Harvest
{
    background: '#A93226',
    text: '#FFFFFF',
    elements: '#F9BF3B'
}, 

// Enchanting Twilight
{
    background: '#EEEFF1',
    text: '#333333',
    elements: '#F7931E'
}, 

// Retro Pop
{
    background: '#FFEAAD',
    text: '#333333',
    elements: '#FF3D7F'
}, 

// Bohemian Chic
{
    background: '#F9E6C6',
    text: '#333333',
    elements: '#D4AF37'
}, 

// Scandinavian Simplicity
{
    background: '#FFFFFF',
    text: '#333333',
    elements: '#CCCCCC'
}, 

// Art Deco Glamour
{
    background: '#E8D7C1',
    text: '#333333',
    elements: '#B57F4D'
}, 

// Rustic Farmhouse
{
    background: '#EAD2AC',
    text: '#333333',
    elements: '#956C4C'
}, 


// Vintage Retro
{
    background: '#FADBD8',
    text: '#333333',
    elements: '#C39BD3'
}, 

// Coastal Nautical
{
    background: '#E0F2F1',
    text: '#333333',
    elements: '#1B4F72'
}, 

// Tropical Paradise
{
    background: '#FFDDC1',
    text: '#333333',
    elements: '#F9690E'
}, 

// Modern Eclectic
{
    background: '#E9D7CA',
    text: '#333333',
    elements: '#9B59B6'
}, 




// Mid-Century Modern
{
    background: '#F4E7D5',
    text: '#333333',
    elements: '#FFC857'
}, 

// Traditional Elegance
{
    background: '#E8E8E8',
    text: '#333333',
    elements: '#9E9E9E'
}, 


// Zen Minimalism
{
    background: '#F4F4F4',
    text: '#333333',
    elements: '#B2B2B2'
}, 

// Country Cottage
{
    background: '#F9E8DC',
    text: '#333333',
    elements: '#AB9471'
}, 

// Hollywood Glam
{
    background: '#F8DDE1',
    text: '#333333',
    elements: '#FFB300'
},

// Victorian Vintage
{
    background: '#F3E8E8',
    text: '#333333',
    elements: '#8A1C0A'
},

// Mediterranean Revival
{
    background: '#E6F7FF',
    text: '#333333',
    elements: '#007BFF'
},

// Japanese Zen
{
    background: '#E6EAE9',
    text: '#333333',
    elements: '#C0D0C9'
},

// French Provincial
{
    background: '#F4EDE6',
    text: '#333333',
    elements: '#F2C14E'
},

// Boho Chic
{
    background: '#F9E7E3',
    text: '#333333',
    elements: '#FFBE40'
},

// Retro 80s
{
    background: '#FCE5CD',
    text: '#333333',
    elements: '#FF5E3A'
},

// Scandinavian Hygge
{
    background: '#F2F2F2',
    text: '#333333',
    elements: '#FF9F55'
},


// Mid-Century Modern
{
    background: '#332D27',
    text: '#F4E7D5',
    elements: '#FFC857'
},

// Traditional Elegance
{
    background: '#1C1C1C',
    text: '#E8E8E8',
    elements: '#9E9E9E'
}, 

// Hollywood Glam
{
    background: '#352B2D',
    text: '#F8DDE1',
    elements: '#FFB300'
},

// Victorian Vintage
{
    background: '#342F2F',
    text: '#F3E8E8',
    elements: '#8A1C0A'
}, 

// Mediterranean Revival
{
    background: '#2B353A',
    text: '#E6F7FF',
    elements: '#007BFF'
}, 

// Coastal Hamptons
{
    background: '#2E3B44',
    text: '#E6F2FF',
    elements: '#4788B3'
}, 

// Retro 80s
{
    background: '#352C25',
    text: '#FCE5CD',
    elements: '#FF5E3A'
}, 

// Scandinavian Hygge
{
    background: '#2E2E2E',
    text: '#F2F2F2',
    elements: '#FF9F55'
}, 

// Industrial Loft
{
    background: '#2B2B2B',
    text: '#E6E6E6',
    elements: '#737373'
}, 



{
    background: '#DB7093',
    text: '#FFFFFF',
    elements: '#FF6347'
}, {
    background: '#FF69B4',
    text: '#FFFFFF',
    elements: '#FFA500'
}, {
    background: '#BA55D3',
    text: '#FFFFFF',
    elements: '#32CD32'
}, {
    background: '#FF00FF',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#8B008B',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#00BFFF',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#FF1493',
    text: '#FFFFFF',
    elements: '#1E90FF'
}, {
    background: '#FFA500',
    text: '#FFFFFF',
    elements: '#8B008B'
}, {
    background: '#FF8C00',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#FF4500',
    text: '#FFFFFF',
    elements: '#9932CC'
}, {
    background: '#FFD700',
    text: '#FFFFFF',
    elements: '#FF8C00'
}, {
    background: '#00CED1',
    text: '#FFFFFF',
    elements: '#DC143C'
}, {
    background: '#32CD32',
    text: '#FFFFFF',
    elements: '#FF00FF'
}, {
    background: '#FFA500',
    text: '#FFFFFF',
    elements: '#8B008B'
}, {
    background: '#FF8C00',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#FF1493',
    text: '#FFFFFF',
    elements: '#00FFFF'
}, {
    background: '#00BFFF',
    text: '#FFFFFF',
    elements: '#FF8C00'
}, {
    background: '#FF4500',
    text: '#FFFFFF',
    elements: '#8A2BE2'
}, {
    background: '#BA55D3',
    text: '#FFFFFF',
    elements: '#32CD32'
}, {
    background: '#FF00FF',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#8B008B',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#1E90FF',
    text: '#FFFFFF',
    elements: '#FF1493'
}, {
    background: '#FF6347',
    text: '#FFFFFF',
    elements: '#008080'
}, {
    background: '#DB7093',
    text: '#FFFFFF',
    elements: '#FF6347'
}, {
    background: '#00BFFF',
    text: '#FFFFFF',
    elements: '#FF8C00'
}, {
    background: '#7B68EE',
    text: '#FFFFFF',
    elements: '#FF6347'
}, {
    background: '#9370DB',
    text: '#FFFFFF',
    elements: '#FF1493'
}, {
    background: '#FF69B4',
    text: '#FFFFFF',
    elements: '#00BFFF'
}, {
    background: '#9370DB',
    text: '#FFFFFF',
    elements: '#FF1493'
}, {
    background: '#00BFFF',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#FF1493',
    text: '#FFFFFF',
    elements: '#1E90FF'
}, {
    background: '#FF00FF',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#8B008B',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#1E90FF',
    text: '#FFFFFF',
    elements: '#FF1493'
}, {
    background: '#FF6347',
    text: '#FFFFFF',
    elements: '#008080'
}, {
    background: '#7B68EE',
    text: '#FFFFFF',
    elements: '#FF6347'
}, {
    background: '#FF4500',
    text: '#FFFFFF',
    elements: '#9932CC'
}, {
    background: '#FF69B4',
    text: '#FFFFFF',
    elements: '#00BFFF'
}, {
    background: '#9370DB',
    text: '#FFFFFF',
    elements: '#FF1493'
}, {
    background: '#00BFFF',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#FF1493',
    text: '#FFFFFF',
    elements: '#1E90FF'
}, {
    background: '#FF6347',
    text: '#FFFFFF',
    elements: '#BA55D3'
}, {
    background: '#9932CC',
    text: '#FFFFFF',
    elements: '#FF69B4'
}, {
    background: '#FFD700',
    text: '#FFFFFF',
    elements: '#FF8C00'
}, {
    background: '#00CED1',
    text: '#FFFFFF',
    elements: '#DC143C'
}, {
    background: '#32CD32',
    text: '#FFFFFF',
    elements: '#FF00FF'
}, {
    background: '#FFA500',
    text: '#FFFFFF',
    elements: '#8B008B'
}, {
    background: '#FF8C00',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#DB7093',
    text: '#FFFFFF',
    elements: '#FF6347'
}, {
    background: '#9932CC',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#FF1493',
    text: '#FFFFFF',
    elements: '#00FFFF'
}, {
    background: '#00BFFF',
    text: '#FFFFFF',
    elements: '#FF8C00'
}, {
    background: '#FF4500',
    text: '#FFFFFF',
    elements: '#8A2BE2'
}, {
    background: '#DC143C',
    text: '#FFFFFF',
    elements: '#00CED1'
}, {
    background: '#FF69B4',
    text: '#FFFFFF',
    elements: '#FFA500'
}, {
    background: '#BA55D3',
    text: '#FFFFFF',
    elements: '#32CD32'
}, {
    background: '#FF00FF',
    text: '#FFFFFF',
    elements: '#FFD700'
}, {
    background: '#8B008B',
    text: '#FFFFFF',
    elements: '#FF4500'
}, {
    background: '#1E90FF',
    text: '#FFFFFF',
    elements: '#FF1493'
}, {
    background: '#FF6347',
    text: '#FFFFFF',
    elements: '#008080'
}, {
    background: '#7B68EE',
    text: '#FFFFFF',
    elements: '#FF6347'
}, {
    background: '#FF4500',
    text: '#FFFFFF',
    elements: '#9932CC'
}, {
    background: '#FF69B4',
    text: '#FFFFFF',
    elements: '#00BFFF'
}
            ]
        }
    },
    data(){
        return({
            activePaletteIndex: null
        });
    },
    methods:{
        setActivePalette(index){
            this.activePaletteIndex = index;
        },
        updateColors() {
            this.$emit('updateColorPalette', this.palettes[this.activePaletteIndex])
        },
    }
}
</script>

<style scoped>

</style>
