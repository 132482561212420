<template>
    <div>
        <!--FLORINXXX -> ANIMATED BACKGROUND LENGHT-->
<!--        <div class="editor_block" style="padding-top: 12px;">-->
<!--            <label class="mylabel_1" for="bgLength"><span>Animation Length in Seconds</span></label>-->
<!--            <custom-input-->
<!--                :value="length"-->
<!--                :id="'VideoLength'"-->
<!--                :inputPlaceholder="'Video Length'"-->
<!--                :min="5"-->
<!--                :max="100"-->
<!--                :increment="5"-->
<!--                :unit="'s'"-->
<!--                @updateInput="updateBgLength"-->
<!--            />-->
<!--        </div>-->

        <upload-single-image-with-opacity
            :image="image"
            :opacity="opacity"
            :backgroundColor="backgroundColor"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateImage="updateImage"
            @updateOpacity="updateOpacity"
            @deleteImage="deleteImage"
        />
    </div>
</template>

<script>
import CustomInput from '../../common/CustomInput.vue';
import UploadAndImageGallery from "../../common/UploadAndImageGallery.vue";
import UploadSingleImageWithOpacity from "../../common/UploadSingleImageWithOpacity.vue";

export default {
    name: "Animated",
    components: {
        UploadSingleImageWithOpacity,
        CustomInput,
        UploadAndImageGallery
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        image: {
            type: String,
            default: 'https://images.unsplash.com/photo-1522441815192-d9f04eb0615c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGJhY2tncm91bmR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60'
        },
        opacity: {
            type: Number,
            default: 75
        },
        length: {
            type: Number,
            default: 30
        },
        backgroundColor: {
            type: String,
            default: null
        }
    },

    computed: {
        imageSrc() {
            if (this.image.includes('unsplash.com') || this.image.includes('https://storage.pixteller.com/')) {
                return this.image;
            } else {
                return 'https://storage.renderlion.com/' + this.image;
            }
        }
    },
    created() {
        this.$emit('setActiveTab', 'Background Anime');
    },
    methods: {
        togglePopUp() {
            this.$emit('togglePopUp');
        },
        updateImage(image) {
            this.$emit('updateImage', image);
        },
        updateOpacity(value) {
            this.$emit('updateOpacity', value)
        },
        updateBgLength(value) {
            this.$emit('updateBgLength', value)
        },
        deleteImage() {
            this.$emit('updateImage', null);
        }
    }
}
</script>

<style scoped>

</style>
