<template>
    <!--START BORDER -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div :class="{on:isSceneActive}"
             class="video_settings_button"
             @click="() => { $emit('setActiveChild',$options.name) ; toggleBorder(true); }"
        >
            <strong>Border</strong> <span class="material-icons">arrow_drop_down</span>
        </div>
        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="price_type_block clr" style="padding-top: 6px;">
                <div class="price_type_left">
                    <div :class="{active:!data.border.active}"
                         class="open_popup_1"
                         @click="() => { $emit('setActiveChild','SpeedAndTiming') ; toggleBorder(false); }"

                    >
                        <div>No Border <span class="material-icons">not_interested</span></div>
                    </div>
                </div>
                <div class="price_type_right">
                    <div :class="{active:data.border.active}"
                         class="open_popup_1"
                    >
                        <div>With Border <span class="material-icons">filter_frames</span></div>
                    </div>
                </div>
            </div>

            <div v-if="data.border.active"
                 class="editor_block editor_block_logo"
            >
                <div class="editor_logo_container_left editor_watermark_display editor_scale_display">
                    <div class="editor_logo_display">
                        <!--START MINI VIDEO FOR BORDER-->
                        <!--FLORINXXX -> FOLLOW CLOSLY WHAT YOU NEED TO UPDATE IN STYLE -> THERE ARE A FEW-->
                        <div class="minivideo_container">
                            <div class="minivideo_bg"></div>
                            <!--FLORINXX -> .minivideo_block_border -> CHECK THE SCALE IN STYLE-->
                            <div :style="{
                                    width: 'calc(100% - ((100 - ('+data.border.scale+' * 1)) * 1px))',
                                    height: 'calc(100% - ((100 - ('+data.border.scale+' * 1)) * 1px))',
                                    left: 'calc(((100 - ('+data.border.scale+' * 1)) / 2) * 1px)',
                                    top: 'calc(((100 - ('+data.border.scale+' * 1)) / 2) * 1px)'
                            }"
                                 class="minivideo_block_border">
                                <!--FLORINXX -> .minivideo_border -> UPDATE OPACITY-->
                                <div :style="{
                                        'border-radius': 'calc('+data.border.opacity+' * 2px)',
                                        height: 'calc(100% - ('+data.border.size+' / 4 * 2px))',
                                        width: 'calc(100% - ('+data.border.size+' / 4 * 2px))',
                                        border: 'calc('+data.border.size+' / 4 * 1px) solid rgba(255,255,255,1)'
                                }"
                                     class="minivideo_border"></div>
                            </div>
                        </div>
                        <!--END MINI VIDEO FOR BORDER-->
                    </div>
                </div>
                <div class="editor_logo_container_right">

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 0;">
                        <label class="mylabel_1" for="borderSize"><span>Border Size</span></label>
                        <custom-input
                            :id="'Size'"
                            :increment="2"
                            :max="360"
                            :min="2"
                            :unit="'px'"
                            :value="data.border.size"
                            @updateInput="updateBorderSize"
                        />
                    </div>

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="borderScale"><span>Scale</span></label>
                        <custom-input
                            :id="'Scale'"
                            :increment="5"
                            :max="160"
                            :min="20"
                            :unit="'%'"
                            :value="data.border.scale"
                            @updateInput="updateBorderScale"
                        />
                    </div>

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="borderOpacity"><span>Border Radius</span></label>
                        <custom-input
                            :id="'Value'"
                            :increment="2"
                            :max="20"
                            :min="0"
                            :unit="'%'"
                            :value="data.border.opacity"
                            @updateInput="updateBorderOpacity"
                        />
                    </div>

                </div>
            </div>
            <!--ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD-->
        </div>
    </li>
    <!--END BORDER -> VIDEO SETTINGS-->
</template>

<script>
import CustomInput from "../../common/CustomInput.vue";

export default {
    name: "Border",
    components: {CustomInput},
    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        selectedScenes: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        },
        isSceneActive() {
            const sceneIdName = this.$options.name.charAt(0).toLowerCase() + this.$options.name.slice(1) + 'IframeId';
            if (sceneIdName in this.selectedScenes) {
                return this.selectedScenes[sceneIdName] !== null;
            }
            return false;
        }
    },
    watch: {
        'selectedScenes.borderIframeId': {
            handler(newValue) {
                if (newValue) {
                    this.$emit('toggleBorder', true);
                } else {
                    this.$emit('toggleBorder', false);
                }
            }
        }
    },
    methods: {
        //border
        toggleBorder(value) {
            this.$emit('toggleBorder', value);
        },
        updateBorderSize(value) {
            this.$emit('updateBorderSize', value);
        },
        updateBorderScale(value) {
            this.$emit('updateBorderScale', value);
        },
        updateBorderOpacity(value) {
            this.$emit('updateBorderOpacity', value);
        },
    }
}
</script>

<style scoped>

</style>
