<template>
    <div class="vid_pending_full pending_spots_not_available">
        <div class="vid_pending_full_small" v-if="limitReachedShown || showLimitReachedPopUp">
            Render Limit Reached &middot; Try Later!
        </div>
        <div class="vid_pending_full_popup" v-if="!areRenderingSpotsAvailable && showLimitReachedPopUp && (!owner.has_credits || !isAdmin)">
            <div class="vid_pending_full_content">
                <h3>
                    Render Limit Reached!
                </h3>
                <p>
                    Skip the Queue, Upgrade for Premium Safari Spots!<br>
                    <strong>No more watermarks, no more waiting.</strong>
                </p>
                <div class="vid_pending_full_buttons">
                    <ul class="clr">
                        <li><a class="upgrade_vid_pending_full_popup" href="/pricing">Upgrade Plan</a></li>
                        <li><span class="close_vid_pending_full_popup" @click="close">Try Again Later</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LimitReachedPopUp",
    props:{
        areRenderingSpotsAvailable:{
            type: Boolean,
            default: false,
        },
        owner:{
            type: Object,
            default: () => ({})
        },
        isAdmin:{
            type: Boolean,
            default: false,
        },
        showLimitReachedPopUp:{
            type: Boolean,
            default: false,
        },
        limitReachedShown:{
            type: Boolean,
            default: false,
        }
    },
    methods:{
        close(){
            this.$emit('closeLimitReachedPopUp');
        }
    }
}
</script>
