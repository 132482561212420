<template>
    <div
        class="vid_data_secondary"
        :class="{ 'disable': animation.is_rendering }"
    >
        <ul class="owner_to_datalist clr">
            <li>
                <div class="owner_to_use">
                    <a :href="useLink"
                       class="owner_to_button owner_to_use_button">
                        <div><span class="material-icons">color_lens</span> Use</div>
                    </a>
                </div>
            </li>
            <li>
                <div class="owner_to_edit">
                    <a :href="editLink"
                       class="owner_to_button owner_to_edit_button">
                        <div><span class="material-icons">gesture</span> Edit</div>
                    </a>
                </div>
            </li>
            <li class="owner_delete_block">
                <div class="owner_to_delete">
                    <div class="owner_to_button owner_to_delete_button">
                        <div><span class="material-icons">delete</span> Delete?</div>
                    </div>
                </div>
                <div class="owner_to_delete_yes">
                    <a :href="deleteLink"
                       class="owner_to_button owner_to_delete_yes_button">
                        <div><span class="material-icons">done</span>Yes</div>
                    </a>
                </div>
                <div class="owner_to_delete_no">
                    <div class="owner_to_button owner_to_delete_no_button">
                        <div><span class="material-icons">close</span></div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "ActionButtons",
    props: {
        isOwner: {
            type: Boolean,
            default: false,
        },
        animation: {
            type: Object,
            default: () => ({}),
        },
        category: {
            type: Object,
            default: () => ({}),
        },
    },
    computed:{
        useLink(){
            return `${this.category.slug}/${this.animation.name}/use`;
        },
        editLink(){
            return `${this.category.slug}/${this.animation.name}/edit`;
        },
        deleteLink(){
            return `${this.animation.name}/delete`;
        }
    }
}
</script>
