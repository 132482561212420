<template>
    <upload-single-image-with-opacity
        :image="image"
        :opacity="opacity"
        :backgroundColor="backgroundColor"
        :uniqueID="uniqueID"
        @togglePopUp="togglePopUp"
        @updateImage="updateImage"
        @updateOpacity="updateOpacity"
        @deleteImage="deleteImage"
    />
</template>

<script>
import UploadAndImageGallery from "../../common/UploadAndImageGallery.vue";
import CustomInput from '../../common/CustomInput.vue';
import UploadSingleImageWithOpacity from "../../common/UploadSingleImageWithOpacity.vue";

export default {
    name: "Static",
    components: {
        UploadSingleImageWithOpacity,
        CustomInput,
        UploadAndImageGallery
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        image: {
            type: String,
            default: 'https://images.unsplash.com/photo-1522441815192-d9f04eb0615c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGJhY2tncm91bmR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60'
        },
        opacity: {
            type: Number,
            default: 75
        },
        backgroundColor: {
            type: String,
            default: null
        },
        length: {
            type: Number,
            default: 30
        }
    },
    computed: {
        imageSrc() {
            if (this.image.includes('unsplash.com') || this.image.includes('https://storage.pixteller.com/')) {
                return this.image;
            } else {
                return 'https://storage.renderlion.com/' + this.image;
            }
        }
    },
    methods: {
        togglePopUp() {
            this.$emit('togglePopUp')

        },
        updateOpacity(value) {
            this.$emit('updateOpacity', value)
        },
        updateImage(image) {
            this.$emit('updateImage', image);
        },
        deleteImage() {
            this.$emit('updateImage', null);
        },
    }
}
</script>

<style scoped>

</style>
