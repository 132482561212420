<template xmlns="http://www.w3.org/1999/html">
    <div>
        <!--START PROFILE COLORS-->
        <div class="editor_block">
            <label class="mylabel_1" for=""><span>Color Palette</span></label>
            <div class="open_palette_popup open_popup_1" @click="togglePaletteSelector">
                <div>
                    Select from Color Palette
                    <span class="material-icons">color_lens</span>
                </div>
            </div>
            <!--FLORINXXX -> ON .my_logo_bgcolor_solid CLICK OPEN COLOR PICKER POPUP-->
            <div class="my_palette_block">
                <ul class="my_palette_list clr">
                    <!--COLOR [1] -> BACKGROUND-->
                    <li>
                        <div class="my_logo_bgcolor_container my_logo_bgcolor_solid" title="Change color"
                             @click="toggleColorPicker('background')"
                        >
                            <div :style="{'background': data.background}" class="my_logo_bg_is_solid"></div>
                            <div class="editor_color_title_block">
                                <div class="editor_color_title">Background</div>
                            </div>
                        </div>
                    </li>
                    <!--COLOR [2] -> HEADLINE+TEXT-->
                    <li>
                        <div class="my_logo_bgcolor_container my_logo_bgcolor_solid" title="Change color"
                             @click="toggleColorPicker('text')"
                        >
                            <div :style="{'background': data.text}" class="my_logo_bg_is_solid"></div>
                            <div class="editor_color_title_block">
                                <div class="editor_color_title">Text Color</div>
                            </div>
                        </div>
                    </li>
                    <!--COLOR [3] -> ELEMENTS-->
                    <li>
                        <div class="my_logo_bgcolor_container my_logo_bgcolor_solid" style="" title="Change color"
                             @click="toggleColorPicker('elements')"
                        >
                            <div :style="{'background': data.elements}" class="my_logo_bg_is_solid"></div>
                            <div class="editor_color_title_block">
                                <div class="editor_color_title">Elements</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!--END PROFILE COLORS-->
        <!--Insert the color picker -->
        <ColorPicker
            v-if="showColorPicker"
            @toggleShowColorPicker="toggleColorPicker"
            @updateBackgroundColor="updateColor"
            :color="color"
        />
        <!-- Insert palette selector -->
        <palette-selector
            v-if="showPaletteSelector"
            @togglePaletteSelector="togglePaletteSelector"
            @updateColorPalette="updateColorPalette"
        />
    </div>
</template>

<script>
import ColorPicker from "../popups/ColorPicker.vue";
import PaletteSelector from "../popups/PaletteSelector.vue";
export default {
    name: "ColorPalette",
    components: {
        PaletteSelector,
        ColorPicker
    },
    props: {
        data:{
            type:Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            showPaletteSelector: false,
            showColorPicker: false,
            type: 'background'
        }
    },
    computed: {
        color() {
            switch (this.type) {
                case 'background':
                    return this.data.background;
                    break;
                case 'text':
                    return this.data.text;
                    break;
                case 'elements':
                    return this.data.elements;
                    break;
                default:
                    return this.data.background;
            }
        }
    },
    methods: {
        updateColor(color) {
            this.$emit('updateColorPaletteColor', this.type, color);
            this.toggleColorPicker();
        },
        toggleColorPicker(type) {
            this.type = type;
            this.showColorPicker = !this.showColorPicker;
            this.$emit('togglePopUp');
        },
        togglePaletteSelector() {
            this.showPaletteSelector = !this.showPaletteSelector;
            this.$emit('togglePopUp');
        },
        updateColorPalette(palette){
            this.$emit('updateColorPaletteColor', 'background', palette.background);
            this.$emit('updateColorPaletteColor', 'text', palette.text);
            this.$emit('updateColorPaletteColor', 'elements', palette.elements);
            this.togglePaletteSelector();
        }
    }
}
</script>

