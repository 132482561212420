<template>
    <div>
        <h1 v-if="showHead">Add Images</h1>
        <div class="editor_block"
             :style="{ 'padding-top': paddingTop + 'px' }"
             :class="{mandatory:ui.errorMessage}"
        >
            <label class="mylabel_1" for=""><span>{{ title }}</span></label>

            <!--FLORINXXX -> ADD .active WHEN UPLOADING-->
            <!--FLORINXXX -> ADD .error WHEN FILETYPE IS WRONG OR OTHER ERRORS ON UPLOADING-->
            <div class="my_upload_block"
                 :class="[ui.isUploading ? 'active' : '',ui.errorMessage ? 'error' : '']"
            >
                <div class="my_upload_content">
                    <div class="my_upload_content_inner">
                        <div class="my_upload_content_left">
                            <div class="my_upload_content_txt my_upload_content_normal">
                                Use high-resolution images<br/>
                                for best results.
                            </div>
                            <div class="my_upload_content_txt my_upload_content_error">
                                Error: Invalid file type!<br/>
                                Please try again.
                            </div>
                        </div>
                        <div class="my_upload_content_right">
                            <div class="my_upload_content_button">Upload</div>
                        </div>
                        <input
                            type="file"
                            class="my_upload_content_input"
                            accept="image/*"
                            name="images[]"
                            multiple
                            @change="uploadImage">
                    </div>
                    <div class="my_upload_content_inner_uploading">
                        <div class="my_upload_content_left">
                            <div class="my_upload_content_txt">
                                Keep calm!<br/>
                                We are processing your data.
                            </div>
                        </div>
                        <div class="my_upload_content_right">
                            <div class="lds-ring lds-ring5">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--FLORINXXX -> ADD .active WHEN UPLOADING-->
            <div style="height: 6px;" class="clear"></div>
            <div class="open_gallery_popup open_popup_1"
                 @click="toggleImageGallery"
            >
                <div>
                    Add from Gallery
                    <span class="material-icons">add_a_photo</span>
                </div>
            </div>
            <remove-background v-if="!isIOS()"
                :processing-background="processingBackground"
                :background-removed-successfully="backgroundRemovedSuccessfully"
                :file="removeBgImages"
                @triggerBackgroundRemove="handleBackgroundRemove"
                @triggerUndoBackground="handleUndoBackground"
            />
        </div>
        <!--Insert image gallery -->
        <image-gallery
            v-if="ui.showImageGallery"
            :type="'multiple'"
            @toggleShowImageGallery="toggleImageGallery"
            @addSelectedImages="addSelectedImages"
        />
    </div>
</template>

<script>
import ImageGallery from "../components/popups/ImageGallery.vue";
import {
    compressImage, downloadFromStorage, extractFilename,
    getFileSizeInMb,
    getInvalidFile, imglyConfig, isIOS,
    isSvg, isVector, makeUniqueByProp,
} from "../helpers/index.js";
import RemoveBackground from "../components/RemoveBackground/Index.vue";
// import imglyRemoveBackground from "@imgly/background-removal";
import { removeBackground } from '@imgly/background-removal';

export default {
    name: "UploadMultipleImages",
    components: {
        RemoveBackground,
        ImageGallery
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        selectedImages: {
            type: Array,
            default: []
        },
        paddingTop: {
            type: Number,
            default: 12
        },
        showHead: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'Upload Images'
        },
        deletedImageIndex: {
            type: [Number, String],
            default: null
        },
        index: {
            type: Number,
            default: null
        }
    },
    watch: {
        // removeBgImages(newVal, oldVal){
        //         console.log('removeBgImages newVal',newVal);
        //     console.log('removeBgImages oldVal',oldVal);
        //
        // },
        deletedImageIndex(newVal, oldVal) {
            if (!this.selectedImages) {
                return
            }
            if (!this.selectedImages) {
                this.removeBgImages = null;
                this.backupFiles = null;
                this.files = null;
                return;
            }
            if (this.removeBgImages) {
                this.removeBgImages = this.checkServerFile(this.removeBgImages);
            }
            if (this.backupFiles) {
                this.backupFiles = this.checkServerFile(this.backupFiles);
            }
            if (!this.removeBgImages || !Object.keys(this.removeBgImages).length) {
                this.removeBgImages = null;
            }
            if (!this.backupFiles || !Object.keys(this.backupFiles).length) {
                this.backupFiles = null;
                this.backgroundRemovedSuccessfully = false;
            }


        }
    },
    inject: ['triggerBackgroundRemoval'],
    data: () => {
        return {
            files: null,
            backupFiles: null,
            removeBgImages: null,
            processingBackground: false,
            backgroundRemovedSuccessfully: false,
            ui: {
                showImageGallery: false,
                isUploading: false,
                errorMessage: null
            }
        };
    },

    created() {
        this.setBackgroundRemovalFiles(); // Reset file to null when component is created
    },
    methods: {
        isIOS,

        checkServerFile(uploadedFiles) {
           const selected_files= this.selectedImages.map(file=>extractFilename(file));
            return makeUniqueByProp(uploadedFiles.filter(file => selected_files.includes(extractFilename(file.server_file_name))),'server_file_name')
        },
        async setBackgroundRemovalFiles() {
            try {
                this.backgroundRemovedSuccessfully = false
                if (!this.selectedImages || (isVector(this.selectedImages) && this.selectedImages.every(image=>image.includes('removed_bg_')))) {
                    this.removeBgImages = null;
                    this.backupFiles = null;
                    return;
                }
                this.removeBgImages = await Promise.all(this.selectedImages.map(async image => {
                    const server_file = await downloadFromStorage(image);
                    server_file.server_file_name = image
                    return server_file;
                }));
                this.backupFiles = this.removeBgImages;
            } catch (e) {
                console.log('setBackgroundRemovalFiles', e)
            }
        },

        toggleImageGallery() {
            this.$emit('togglePopUp')
            this.ui.showImageGallery = !this.ui.showImageGallery;
        },
        togglePreloader() {
            this.ui.isUploading = !this.ui.isUploading;
        },
        async uploadImage(event) {

            this.ui.errorMessage = null;
            this.togglePreloader();
            this.files = event.target.files;

            const invalidFile = getInvalidFile(this.files);
            if (invalidFile) {
                this.togglePreloader();
                this.ui.hasErrors = true;
                this.ui.errorMessage = `Invalid format. ${invalidFile.name} Accepted only: jpg, jpeg, png, svg, webp`;
                return; // Stop execution
            }

            // Process all files
            const maxFileSize = typeof __MAX_FILE_SIZE_UPLOAD__ !== 'undefined' ? parseInt(__MAX_FILE_SIZE_UPLOAD__) : 3; // Size limit in MB
            const processed_images = [];
            const processFile = (file) => {

                return new Promise((resolve) => {
                    const fileSizeMB = getFileSizeInMb(file);
                    if (fileSizeMB <= maxFileSize) {

                        processed_images.push(file);
                        resolve();
                        return
                    }
                    compressImage(file, 2400, 2400, 1, (compressedBlob) => {
                        const compressedFile = new File([compressedBlob], "compressed_" + file.name, {
                            type: compressedBlob.type,
                            lastModified: new Date().getTime()
                        });

                        processed_images.push(compressedFile);
                        // Calculate and log the new size in MB
                        resolve();
                    });
                });
            };
            const fileProcessingPromises = Array.from(this.files).map(processFile);
            await Promise.all(fileProcessingPromises);


            await this.processServerRequest(processed_images)
            this.backgroundRemovedSuccessfully = false
        },

        async processServerRequest(processed_images, replace_images = null) {
            let formData = new FormData();
            for (const image_file of processed_images) {
                formData.append("images[]", image_file);

            }
            let server_images = await this.processAndUploadImages(formData, replace_images);
            if (!server_images) {
                server_images = []
            }
            const noSvgImages = [];

            processed_images.forEach((image_file, index) => {
                if (!isSvg(image_file.type) && server_images[index] !== undefined) {
                    image_file.server_file_name = server_images[index]
                    noSvgImages.push(image_file);
                }
            });
            this.setFileForBgRemoval(noSvgImages)
        },
        async processAndUploadImages(formData, replace_images = null) {

            formData.append('uniqueID', this.uniqueID);
            // Proceed to upload
            try {
                const response = await axios.post('/api/resources/upload-images', formData);
                this.togglePreloader();
                this.files = null;
                this.triggerBackgroundRemoval(false)
                this.$emit('addSelectedImages', response.data,this.index, replace_images);
                if (replace_images) {
                    this.syncBackupFiles(replace_images, response.data)

                    this.removeBgImages = null;
                    this.processingBackground = false;
                }
                return response.data

            } catch (error) {
                this.triggerBackgroundRemoval(false)
                this.togglePreloader();
                this.ui.hasErrors = true;
                this.ui.errorMessage = 'Error: Invalid file type!';
                if (replace_images) {
                    this.removeBgImages = null;
                    this.processingBackground = false;
                    this.backgroundRemovedSuccessfully = false;

                }
                if (error.response) {
                    this.ui.errorMessage = error.response.data;
                    console.log(error.response);
                }
                console.log('FAILURE!!',error);
            }
        },
        syncBackupFiles(replace_images, response_images) {
            if(!isVector(this.backupFiles)||!this.backupFiles.length){
                return;
            }

            replace_images.forEach((replace_image,index)=>{
                const file=this.backupFiles.find(backup_file=>extractFilename(backup_file.server_file_name)===extractFilename(replace_image))

                if(file && response_images[index]){
                    file.server_file_name=response_images[index];
                }
            })
        },
        async handleBackgroundRemove() {
            if (!this.removeBgImages) {
                return;
            }
            this.ui.errorMessage = null;
            this.togglePreloader();
            this.processingBackground = true;
            this.triggerBackgroundRemoval(true)

            try {
                const removalPromises = this.removeBgImages.map(image => {
                    return removeBackground(image, imglyConfig()).then(blob => {
                        return new File([blob], `removed_bg_${image.name}`, {
                            type: blob.type,
                            lastModified: new Date().getTime()
                        });
                    });
                });

                const processedImages = await Promise.all(removalPromises);

                let formData = new FormData();
                processedImages.forEach(image => {
                    formData.append("images[]", image);
                });
                await this.processAndUploadImages(formData, this.removeBgImages.map(file => file.server_file_name));
                this.backgroundRemovedSuccessfully = true;

            } catch (error) {
                this.ui.errorMessage = 'Failed to remove background from images.';
                this.togglePreloader();
                this.processingBackground = false;
            }
        },
        async handleUndoBackground() {
            this.backgroundRemovedSuccessfully = false;
            this.togglePreloader();
            this.removeBgImages = null;
            this.backupFiles = await Promise.all(this.backupFiles.map(async image => {
                return !image instanceof File ? await downloadFromStorage(image) : image;
            }));
            await this.processServerRequest(this.backupFiles, this.backupFiles.map(file => file.server_file_name));
            this.backgroundRemovedSuccessfully = false;

        },
        setFileForBgRemoval(noSvgImages) {
            if (noSvgImages.length) {
                this.removeBgImages = isVector(this.removeBgImages) ? makeUniqueByProp(this.removeBgImages.concat(noSvgImages),'server_file_name') : noSvgImages;
                this.backupFiles = isVector(this.backupFiles) ?  makeUniqueByProp(this.backupFiles.concat(noSvgImages),'server_file_name') : noSvgImages;
            }
        },
        async addSelectedImages(images) {
            const noSvgImages = images.filter(image => !image.endsWith('.svg'));
            const imgBgRemoval = await Promise.all(noSvgImages.map(async image => {
                const file=  await downloadFromStorage(image);
                file.server_file_name=image;
                return file;
            }));
            //await Promise.all(noSvgImages.map(image => addStoragePath(image)));
            this.setFileForBgRemoval(imgBgRemoval);
            if (imgBgRemoval.length) {
                this.backgroundRemovedSuccessfully = false
            }
            // Simplified conditional emit
            this.$emit('addSelectedImages', images,this.index);
        }
    },
}
</script>

<style scoped>

</style>
