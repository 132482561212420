<template>
    <!--START POPUP -> COLOR PICKER-->
    <!--FLORINXXXX -> ADD .active ON .popup_block TO ACTIVATE POPUP-->
    <!--FLORINXXXX -> ON CLICK .popup_close_button CLOSE POPUP -> REMOVE .active-->
    <div class="popup_block active">
        <div class="popup_container">
            <div
                class="popup_close_button"
                title="Close"
                @click="toggleShowColorPicker()"
            >
                <div><span class="material-icons">close</span></div>
            </div>

            <div class="popup_content">
                <h2>Choose Color</h2>
                <div class="clear" style="height: 12px;"></div>
                <div class="popup_color_picker popup_florin_content">
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--FLORINXXXX ADD COLOR PICKER CODE HERE-->
                    <!--                    <color-picker-plugin-->
                    <!--                        :startColor="color"-->
                    <!--                        v-model="selectedColor"-->
                    <!--                        v-on:color-change="colorChanged"-->
                    <!--                    />-->
                    <!--                    <color-picker-plugin-->
                    <!--                        v-model="selectedColor"-->
                    <!--                        @select="colorChanged"-->
                    <!--                    ></color-picker-plugin>-->
                    <color-picker-plugin
                        :color="color"
                        @color-change="colorChanged"
                        :visible-formats="['hex']"
                        default-format="hex"
                        alpha-channel="hide"
                    />
                    <!--
                    <p v-if="!validColor">Invalid hex color</p>
                    <input type="text"
                           name="hexColor"
                           placeholder="Hex color"
                           v-model="selectedColor"
                           pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                    >
                    -->
                </div>
            </div>

            <div class="popup_footer">
                <div class="popup_footer_block">
                    <!--FLORINXXXX -> ON CLICK .popup_button_update -> UPDATE-->
                    <div class="popup_button_update"
                         @click="updateTheBGColor"
                    >
                        <div style="padding-right: 12px;">
                            <strong>Update</strong>
                            <span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span>
                        </div>
                    </div>
                    <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                    <div class="popup_button_cancel"
                         @click="toggleShowColorPicker()"
                    >
                        <div style="padding-left: 16px;">
                            Cancel
                            <span class="material-icons" style="top: 14px; left: -10px;">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--START POPUP -> COLOR PICKER-->
</template>

<script>
// import ColorPickerPlugin from 'vue-color-picker-wheel';
// import ColorPickerPlugin from '@radial-color-picker/vue-color-picker';
import {ColorPicker as ColorPickerPlugin} from 'vue-accessible-color-picker'

export default {
    name: "ColorPicker",
    components: {
        ColorPickerPlugin
    },
    props: {
        color: {
            type: String,
            default: '#FFFF00'
        }
    },
    data() {
        return {
            validColor: true,
            selectedColor: null
        }
    },
    watch: {
        selectedColor(newValue) {
            if (this.validateColor(newValue)) {
                this.validColor = true;
            } else {
                this.validColor = false;
            }
        }
    },
    mounted() {
        this.selectedColor = this.color;
    },
    methods: {
        updateTheBGColor() {
            if (!this.validColor) {
                return;
            }
            this.$emit('updateBackgroundColor', this.selectedColor)
        },
        toggleShowColorPicker() {
            this.$emit('toggleShowColorPicker')
        },
        colorChanged(color) {
            this.selectedColor = color.cssColor;
        },
        validateColor(color) {
            let $div = $("<div>");
            $div.css("border", "1px solid " + color);
            return ($div.css("border-color") != "")
        }
    },
}
</script>

<style scoped>

</style>
