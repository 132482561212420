/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries.
 */

import './bootstrap';
import {createApp} from 'vue';
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

// Import Trumbowyg plugins and styles
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js';
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css';
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js';
import '@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';

// Import your components
import SceneTemplates from './pages/Admin/SceneTemplates.vue';
import AnimatedWallpaper from "./pages/AnimatedWallpaper.vue";
import VideoIntro from "./pages/VideoIntro.vue";
import VideoOutro from "./pages/VideoOutro.vue";
import TextToVideo from "./pages/TextToVideo.vue";
import ShopOfferToVideo from "./pages/ShopOfferToVideo.vue";
import AnimatedLogo from "./pages/AnimatedLogo.vue";
import ImagesToVideo from "./pages/ImagesToVideo.vue";
import AnimationView from "./platforme/pages/AnimationView.vue";
import GenerateAiVideoForm from "./platforme/pages/GenerateAiVideoForm.vue";

// Create Vue app
const app = createApp({});

// Register components
app.component('scene-templates', SceneTemplates);
app.component('animated-wallpaper', AnimatedWallpaper);
app.component('video-intro', VideoIntro);
app.component('video-outro', VideoOutro);
app.component('images-to-video', ImagesToVideo);
app.component('text-to-video', TextToVideo);
app.component('shop-offer-to-video', ShopOfferToVideo);
app.component('animated-logo', AnimatedLogo);

//Register platforme components
app.component('animation-view', AnimationView);
app.component('generate-ai-video-form', GenerateAiVideoForm);

// Use plugins
app.use(VueTrumbowyg);

//nova

// Disable inspect mode in browser
// if (__APP_ENV__ === 'production') {
//     // Disable right-click context menu
//     document.addEventListener('contextmenu', event => event.preventDefault());
//
//     // Disable F12 key and Ctrl+Shift+I combination
//     document.addEventListener('keydown', function (event) {
//         if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')) {
//             event.preventDefault();
//         }
//     });
//
//     //Disable console error messages in production
//     // List of console methods to be disabled
//     const consoleMethods = ["warn", "error", "info", "table", "trace"];
//
//     // Override each method with a noop function
//     consoleMethods.forEach(method => {
//         console[method] = function () {
//         };
//     });
//
// }

// Mount Vue app
app.mount('#app');



// Disable Vue DevTools in production and set a nice console log message
if (process.env.NODE_ENV === 'production') {
    app.config.devtools = false;

    // Console message
    console.log(
        "%c Welcome to RenderLion! 🦁\n\n" +
        "Hey there, curious explorer! 👋\n" +
        "We're glad you're taking a closer look. If you love creativity, technology, and innovation as much as we do, you're in the right place. \n\n" +
        "Feel free to reach out if you have any questions, ideas, or just want to say hi! \n\n" +
        "Happy exploring! 🌟\n\n" +
        "The RenderLion Team!",
        "color: #ff1493; font-size: 16px;"
    );
}

