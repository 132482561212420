<template>
    <div>
        <!--START PROFILE FONTS-->
        <!--FLORINXXX -> ON .button CLICK OPEN THE SELECT FONT POPUP -> DEFAULT IS "Josefin Sans" SAME AS HERE ... -->
        <!--FLORINXXX -> DEPENDING ON THE FONTS YOU MUST LOAD THEM ALSO HERE IN HEAD-->
        <!--FLORINXXX -> YOU ARE SMART I WILL NOT WRITE MORE HERE-->
        <div class="editor_block">
            <label class="mylabel_1" for=""><span>Choose Fonts</span></label>
            <ul class="editor_my_fonts_list">
                <!--CHOOSE HEADLINES FONTS-->
                <li>
                    <div class="choose_font_button choose_headline_font"
                         @click="showPreloaderSelectorFor(headline = true)"
                    >
                        <div class="choose_font_button_inner">
                            <div class="choose_font_label">Headline font</div>
                            <div class="choose_font_headline"
                                 v-bind:style="{ fontFamily: data.headline === 'Josefin Sans' ? 'Josefin Sans, sans-serif' : data.headline + ', sans-serif' }"
                            >
                                {{ data.headline }}
                            </div>
                            <span class="material-icons">font_download</span>
                        </div>
                    </div>
                </li>
                <!--CHOOSE TEXT FONTS-->
                <li>
                    <div class="choose_font_button choose_headline_font"
                         @click="showPreloaderSelectorFor(headline = false)"
                    >
                        <div class="choose_font_button_inner">
                            <div class="choose_font_label">Text font</div>
                            <div
                                v-bind:style="{ fontFamily: data.headline === 'Josefin Sans' ? 'Josefin Sans, sans-serif' : data.text + ', sans-serif' }"
                                class="choose_font_txt"
                                :class="{'font-picker-text': data.text !=='Josefin Sans'}"
                            >
                                {{ data.text }}
                            </div>
                            <span class="material-icons">font_download</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!--END PROFILE FONTS-->
        <!--Insert font selector -->
        <font-family
            v-if="showFontSelector"
            :headline="headline"
            :activeFontHeadline="data.headline"
            :activeFontText="data.text"
            @toggleShowFontSelector="toggleShowFontSelector"
            @changeActiveFont="changeActiveFont"
        />
    </div>
</template>

<script>
import FontFamily from "../popups/FontFamily.vue";
export default {
    name: "ChooseFonts",
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    headline: {
                        type: String,
                        default: 'Josefin Sans'
                    },
                    text: {
                        type: String,
                        default: 'Josefin Sans'
                    }
                }
            }
        }
    },
    data() {
        return {
            headline: true,
            showFontSelector: false,
            fontSelectorFor: 'headline',
        };
    },
    components: {
        FontFamily
    },
    methods: {
        changeActiveFont(fontName, headline) {
            if (headline) {
                this.$emit('updateFontHeadline', fontName);
            } else {
                this.$emit('updateFontText', fontName);
            }
        },
        showPreloaderSelectorFor(headline) {
            this.headline = headline;
            this.toggleShowFontSelector();
        },
        toggleShowFontSelector() {
            this.showFontSelector = !this.showFontSelector;
            this.$emit('togglePopUp');
        }
    }
}
</script>

<style scoped>

</style>
