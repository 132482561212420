<template>
    <!--START LOGO / AVATAR SETTINGS-->
    <!--FLORINXXX -> ADD .none WHEN THE PROFILE HAS NO LOGO-->
    <div class="editor_block editor_block_logo"
         v-if="logoSrc"
    >
        <div class="editor_logo_container_left">
            <div class="editor_logo_display"
                 :class="{isRotating: isRotating}"
            >
                <!--FLORINXXX -> UPDATE LOGO BACKGROUND COLOR #hex OR ADD none-->
                <div class="editor_logo_display_bgcolor"
                     :class="{none:logo.isTransparentBg}"
                     :style="{background: backgroundColor}"
                ></div>

                <!--FLORINXXX -> UPDATE LOGO SCALE VALUE TO STYLE (I USED 80 TO UNDERSTAND)-->
                <!--FLORINXXX -> .editor_img_button_delete DELETE THE IMAGE-->
                <div
                    :style="{
                        'background-image': 'url(' + logoSrc + ')',
                         width: logo.scale + '%',
                         height: logo.scale + '%',
                         top: 'calc(( 100% - '+logo.scale + '%) / 2)',
                         left: 'calc(( 100% - '+logo.scale + '%) / 2)'
                    }"
                    class="editor_logo_display_scale">
                </div>
                <div class="editor_logo_guideline"></div>

                <div class="editor_img_rotate_preloader">
                    <div class="lds-ring lds-ring6">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <div
                    class="editor_img_button_rotateleft"
                    title="Rotate left"
                    @click="rotateImageLeft()"
                >
                        <span class="material-icons">
                            rotate_left
                        </span>
                </div>
                <div
                    class="editor_img_button_rotateright"
                    title="Rotate right"
                    @click="rotateImageRight()"
                >
                        <span class="material-icons">
                            rotate_right
                        </span>
                </div>
                <div
                    class="editor_img_button_delete"
                    title="Delete logo/avatar"
                    @click="$emit('deleteLogoImage')"
                ><span
                    class="material-icons">clear</span></div>
            </div>
        </div>
        <div class="editor_logo_container_right">

            <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
            <div class="editor_block" style="padding-top: 0;">
                <label class="mylabel_1" for="scaleLogo"><span>Logo appearance</span></label>
                <div class="input_numb_block">
                    <custom-input
                        :value="logo.scale"
                        :id="'Scale'"
                        :min="0"
                        :max="200"
                        :increment="5"
                        :unit="'%'"
                        @updateInput="updateLogoScale"
                    />
                </div>
            </div>

            <div class="editor_block" style="padding-top: 18px;">
                <label class="mylabel_1" for=""><span>Logo background</span></label>
                <div class="my_logo_bgcolor_block">
                    <!--FLORINXXX -> ADD .active WHEN LOGO BACKGRAUND IS SELECTED-->
                    <!--FLORINXXX -> ADD #color IN STYLE FOR .my_logo_bg_is_solid-->
                    <!--FLORINXXX -> ON .my_logo_bgcolor_solid CLICK OPEN COLOR PICKER POPUP-->
                    <div class="my_logo_bgcolor_container my_logo_bgcolor_transparent"
                         title="Without background"
                         :class="{active:logo.isTransparentBg}"
                         @click="setBgTransparent"
                    >
                        <div class="my_logo_bg_is_transparent"></div>
                        <span class="material-icons">check</span>
                    </div>
                    <div class="my_logo_bgcolor_container my_logo_bgcolor_solid"
                         :class="{active: !logo.isTransparentBg}"
                         title="Choose color"
                         @click="toggleColorPicker"
                    >
                        <div class="my_logo_bg_is_solid"
                             :style="{background: backgroundColor}"
                        ></div>
                        <span class="material-icons">check</span>
                    </div>
                </div>
            </div>
        </div>
        <!--Insert the color picker -->
        <ColorPicker
            v-if="showColorPicker"
            @toggleShowColorPicker="toggleColorPicker"
            @updateBackgroundColor="updateColor"
            :color="backgroundColor"
        />
    </div>
    <!--END LOGO / AVATAR SETTINGS-->
</template>

<script>
import ColorPicker from "../../popups/ColorPicker.vue";
import CustomInput from "../../../common/CustomInput.vue";

export default {
    name: "Show",
    components: {
        ColorPicker,
        CustomInput
    },
    props: {
        logo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data: () => {
        return {
            isRotating: false,
            showColorPicker: false
        }
    },
    computed: {
        logoSrc() {
            if (this.logo.src && this.logo.src.includes('..')) {
                return 'https://dev2.renderlion.com/' + this.logo.src.replace('../','');
            } else if (this.logo.src && (this.logo.src.includes('user') || this.logo.src.includes('unprocessed-images'))) {
                return 'https://storage.renderlion.com/' + this.logo.src;
            } else if (this.logo.src && this.logo.src.includes('http')) {
                return this.logo.src;
            }
            return null;
        },
        backgroundColor() {
            if (this.logo.color) {
                return this.logo.color;
            }
            return '#FFFF00';
        }
    },
    methods: {
        toggleColorPicker() {
            this.showColorPicker = !this.showColorPicker;
            this.$emit('togglePopUp');
        },
        updateColor(color) {
            this.toggleColorPicker();
            this.$emit('changeLogoBgColor', color)
        },
        setBgTransparent() {
            this.$emit('changeLogoBgColorTransparency', true)
        },
        updateLogoScale(value) {
            this.$emit('updateLogoScale', value)
        },
        rotateImageLeft() {
            this.isRotating = true;
            axios.post('/api/resources/rotate-image',
                {
                    url: this.logoSrc,
                    orientation: 'left'
                }
            ).then((response) => {
                //stop the preloader and empty the files
                this.$emit('setActiveLogo', response.data);
                this.isRotating = false;
            }).catch((error) => {

            });
        },
        rotateImageRight() {
            this.isRotating = true;
            axios.post('/api/resources/rotate-image',
                {
                    url: this.logoSrc,
                    orientation: 'right'
                }
            ).then((response) => {
                //stop the preloader and empty the files
                this.$emit('setActiveLogo', response.data);
                this.isRotating = false;
            }).catch((error) => {

            });
        },
    }
}
</script>

<style scoped>

</style>
