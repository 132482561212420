<template>
    <div>
        <div style="height: 42px" class="clear"></div>
        <h1>Intro Setup <span class="optional_step_title">(Optional)</span></h1>
        <choose-text-field
            :showContentSuggestion="showIntroContentSuggestion"
            :activeTextType="data.activeTextType"
            :type="data.type"
            :headline="data.headline"
            :text="data.text"
            :contentSuggestion="contentSuggestionIntro"
            @toggleShowContentSuggestion="toggleShowIntroContentSuggestion"
            @updateTheContentSuggestion="updateTheIntroContentSuggestion"
            @setActiveTextType="setActiveTextType"
            @headlineChanged="introHeadlineChanged"
            @textChanged="introTextChanged"
        />
        <call-to-action-button
            :showContentSuggestion="showCallToActionContentSuggestion"
            :contentSuggestion="contentSuggestionCallToAction"
            :callToActionButton="data.callToActionButton"
            :callToActionText="data.callToActionText"
            @toggleCallToActionButton="toggleCallToActionButton"
            @textChanged="setCallToActionText"
            @toggleShowContentSuggestion="toggleShowCallToActionContentSuggestion"
            @updateTheContentSuggestion="updateTheContentSuggestion"
        />

        <upload-single-image-with-opacity
            :title="'Intro Image'"
            :image="data.image"
            :opacity="data.opacity"
            :backgroundColor="data.backgroundColor"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateImage="updateImage"
            @updateOpacity="updateOpacity"
            @deleteImage="deleteImage"
        />

        <div class="left_side_footer_spacer clear"></div>
    </div>

</template>

<script>
import ChooseTextField from "../../common/ChooseTextField.vue";
import ContentSuggestion from "../popups/ContentSuggestion.vue";
import CallToActionButton from "../../common/CallToActionButton.vue";
import UploadSingleImageWithOpacity from "../../common/UploadSingleImageWithOpacity.vue";

export default {
    name: "Index",
    components: {
        UploadSingleImageWithOpacity,
        CallToActionButton,
        ChooseTextField,
        ContentSuggestion
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        data: {
            type: Object,
            default: () => {
                return {
                    activeTextType: 1,
                }
            }
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName('Intro'));
    },
    data: () => {
        return {
            showIntroContentSuggestion: false,
            contentSuggestionIntro: [
                {
                    type: 1,
                    headline: '<p><strong>Welcome to the Exploration</strong></p>',
                    text: '<p>Dive in as we uncover the secrets and stories waiting to be discovered.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Your journey of curiosity and wonder starts right here, right now.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Join our bargain hunt as we find the best deals in [Product Category]</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>Your Adventure Begins Here</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Unveiled: The Secrets of [Topic]</p>',
                    text: '<p>Prepare for a journey into the heart of [Topic]. We\'re peeling back the layers, offering you a fresh, unprecedented look at what really lies beneath.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>The Ultimate [Topic] Guide</strong></p>',
                    text: '<p>Join us as we delve deep into the fascinating world of [Topic].</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Products that stand out with uniqueness and quality, all in one place.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Whether you\'re a newbie or an expert, there\'s something here for <strong>everyone</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>A Journey of Discovery Begins</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Behind the Scenes: [Topic] Revealed</strong></p>',
                    text: '<p>Ever wondered what goes on behind the scenes of [Topic]?</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us as we explore the best-selling items in [Product Category]</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Discover what shoppers are loving the most!</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us as we lift the curtain to reveal the hidden workings and untold stories.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Embark on a Journey of Discovery</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss the top-ranked selections in our countdown!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Critics\' Choice Highlights</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Ready to deep dive into [Topic]? </p>',
                    text: '<p>Stay tuned as we explore the lesser-known facts and uncover the secrets that will keep you hooked.</p>'
                },
                {
                    type: 1,
                    headline: '<p>The [Topic] Breakdown</p>',
                    text: '<p>Get ready to break down the world of [Topic], dissecting each detail to bring you the insights you\'ve been craving.</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Exploring the Uncharted: [Topic]</strong></p>',
                    text: '<p>Join us as we navigate the complex and intriguing landscape of [Topic].</p>'
                },
                {
                    type: 3,
                    headline: '<p>The Ultimate Guide to [Topic]</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Your [Topic] Adventure</p>',
                    text: '<p>Step into the world of [Topic] as we guide you through the most fascinating <strong>tales</strong> and facts.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Your adventure into the heart of <strong>[Topic]</strong> starts here.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Dive Deeper with [Topic]</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p><strong>Top 10 [Topic] Revealed</strong></p>',
                    text: '<p>Dive in as we unveil the absolute best in [Category]. Don\'t miss the top-ranked selections in our countdown!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Behind the Magic: A Closer Look</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Behind the Scenes: [Topic] Revealed</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>The Best of [Category]</strong></p>',
                    text: '<p>Join us as we explore the cream of the crop in this exclusive roundup.</p>'
                },
                {
                    type: 3,
                    headline: '<p>The Unveiled Secrets of [Topic]</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Viewer\'s Choice: [Category]</strong></p>',
                    text: '<p>We asked, you voted. Discover the top [Category] as chosen by viewers like you.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Critics\' Choice</strong>: [Category] Highlights</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Trust the experts</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Don\'t miss the top-ranked selections in our countdown!</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>We\'re spotlighting the best [Category] acclaimed by critics.</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p>Tune in for the <strong>top recommendations</strong>!</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss the top-ranked selections in our countdown!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Unlocking the Secrets: A Deeper Dive</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover what shoppers are loving the most!</strong></p>'
                },
                {
                    type: 2,
                    headline: '<p>Hidden Gems: [Category] Unveiled</p>',
                    text: '<p>Delve deeper as we reveal the <strong>hidden gems</strong> in [Category]. </p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>These are the best kept secrets, now brought to light!</strong></p>'
                },
                {
                    type: 1,
                    headline: '<p>Best in Class: [Category]</p>',
                    text: '<p>Welcome to a showcase of the finest selections in <strong>[Category]</strong>. Discover what makes these picks the best in class!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Delve into the Details</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>An Exclusive Behind-the-Scenes Journey</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Embarking on a New Journey</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Top Trends: [Category]</strong></p>',
                    text: '<p>Join us as we unveil the top trends dominating [Category] right now.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover what shoppers are loving the most!</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p>Your Gateway to the <strong>Unknown</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Ultimate Guide: [Category]</p>',
                    text: '<p>Your go-to guide for the best in [Category]. Dive in as we showcase the top picks and industry favorites.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Top Deals: Unmissable [Product Category]</p>',
                    text: '<p>Ready for the best deals in [Product Category]?</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Stay tuned as we unveil the top offers you can\'t resist.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Join us as we unveil the top trends dominating [Category] right now.</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>An In-depth Look: [Topic]</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Ready for the best deals in [Product Category]?</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Best Sellers: [Product Category] Edition</strong></p>',
                    text: '<p>Join us as we explore the best-selling items in <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover what shoppers are loving the most!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Step Inside the Adventure</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Editor\'s Picks: The Best of [Product Category]</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss our editor\'s top picks in <strong>[Product Category]</strong>. </p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Handpicked for quality and value, these are the products you need to see.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Hot Trends: [Product Category]</p>',
                    text: '<p>Tune in to discover the hottest trends in [Product Category].</p>'
                },
                {
                    type: 3,
                    headline: '<p>User Favorites: Top-Rated [Product Category]</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover the [Product Category] that users are raving about.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Prepare for a journey into the heart of [Topic]</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Join us for a roundup of the top-rated products according to <strong>shoppers like you</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>New Arrivals: Fresh in [Product Category]</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Be the first to explore the fresh arrivals in [Product Category]</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>From the latest releases to must-have items, we\'ve got it all covered.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Find your next favorite product in our latest collection!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Exploring Uncharted Territories</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Welcome to Our Journey</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Handpicked for quality and value, these are the products you need to see.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Limited Time Offers: [Product Category]</strong></p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t miss out on limited-time offers in <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Grab the best deals before time runs out!</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Exclusive Finds: [Product Category] Specials</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover exclusive finds in our <strong>[Product Category] specials</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Products that stand out with uniqueness and quality, all in one place.</p>'
                },
                {
                    type: 3,
                    headline: '<p>Begin Your Adventure with Us</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Must-Have [Product Category]: Don\'t Miss Out</p>',
                    text: '<p>Dive in as we uncover the must-have products in <strong>[Product Category]</strong>. Stay tuned to find your next great purchase!</p>'
                },
                {
                    type: 2,
                    headline: '<p>Season\'s Picks: The Best of [Product Category]</p>',
                    text: '<p>Get into the season\'s spirit with the top picks in [Product Category]. </p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover what\'s trending this season right here!</p>'
                },
                {
                    type: 3,
                    headline: '<p>Diving Deep: A Closer Look</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>The Ultimate [Product Category] Showcase</p>',
                    text: '<p>Welcome to the ultimate showcase of <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Sit back and enjoy as we bring you the crème de la crème of products to shop.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Insider\'s Perspective: The Full Story</strong></p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Top-Rated [Product Category]</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p><strong>[Product Category]: Top Recommendations</strong></p>',
                    text: '<p>Looking for the best in [Product Category]? We\'ve got you covered with our top recommendations. Tune in for the details!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>We\'ve got you covered with our top recommendations</p>'
                },
                {
                    type: 1,
                    headline: '<p>Your [Product Category] Wishlist</p>',
                    text: '<p>Dream with us as we explore the wish-list worthy items in <strong>[Product Category]</strong>.</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Find products you didn\'t know you needed until now!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Insider Picks: [Product Category]</strong></p>',
                    text: '<p>Get the insider scoop as we unveil the top picks in [Product Category].</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>These are the products that industry insiders are buzzing about!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Discover the Magic Within</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p>Discover the Undiscovered</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>Sneak Peek: Upcoming [Product Category]</p>',
                    text: '<p>Be the first to get a sneak peek of the upcoming releases in [Product Category].</p>'
                },
                {
                    type: 1,
                    headline: '<p>Bargain Hunt: The Best Deals in [Product Category]</p>',
                    text: '<p>Join our bargain hunt as we find the best deals in [Product Category].</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover what shoppers are loving the most!</p>'
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get ready for unbeatable prices and fantastic value!</strong></p>'
                },
            ],
            showCallToActionContentSuggestion: false,
            contentSuggestionCallToAction: [
                {
                    type: 4,
                    headline: null,
                    text: '<p>Enjoy</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>See the Unseen</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Act Now</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Experience</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Become a Member</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Limited Time Offer</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover the Deal</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Experience It Now</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Claim Your Discount</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Witness the Magic</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Play and Learn</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Today Only</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Connect</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover More</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Order Now</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get the Offer</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get the Insights</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Shop the Trend</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Limited Offer</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Join the Fun</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Start Saving</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Buy Now</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Unbeatable Offers</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Unlock the Magic</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Buy More, Save More</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover Discounts</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Feel the Excitement</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Explore the Sale</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Ready, Set, Shop!</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Exclusive Deals</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Exclusive Access</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Indulge Yourself</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Grab the Deal</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>View Collection</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Ignite</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Start Your Journey</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Ignite Curiosity</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Get Yours Now</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Delve In</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get Exclusive Access</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Reveal the Secret</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Treat Yourself</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Explore Now</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Savings Inside</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Snag the Offer</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Seize the Deal</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Let\'s Get Started</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>View the Collection</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Be Inspired</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t Miss Out</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Explore the Unknown</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Reveal the Offer</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Find Your Style</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Shop Smart</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get It First</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Join the Adventure</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Find Out More</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Dive Deeper</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Hot Deals Inside</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Savings Await</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Last Chance</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Unlock Savings</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>See the Deals</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Shop Now</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Be the First</p>',
                },
            ],
        }
    },
    methods: {
        toggleShowIntroContentSuggestion() {
            this.showIntroContentSuggestion = !this.showIntroContentSuggestion;
            this.togglePopUp();
        },
        updateTheIntroContentSuggestion(selectedContentSuggestion) {
            this.$emit('setActiveTextType', selectedContentSuggestion.type)
            this.$emit('introHeadlineChanged', selectedContentSuggestion.headline)
            this.$emit('introTextChanged', selectedContentSuggestion.text)
        },
        togglePopUp() {
            this.$emit('togglePopUp')
        },
        setActiveTextType(value) {
            this.$emit('setActiveTextType', value)
        },
        introHeadlineChanged(event) {
            this.$emit('introHeadlineChanged', event.target.value)
        },
        introTextChanged(event) {
            this.$emit('introTextChanged', event.target.value)
        },
        toggleCallToActionButton() {
            this.$emit('toggleCallToActionButton')
        },
        setCallToActionText(event) {
            this.$emit('setCallToActionText', event.target.value);
        },
        toggleShowCallToActionContentSuggestion() {
            this.togglePopUp();
            this.showCallToActionContentSuggestion = !this.showCallToActionContentSuggestion;
        },
        updateTheContentSuggestion(selectedContent) {
            this.$emit('setCallToActionText', selectedContent.text);
        },
        updateOpacity(value) {
            this.$emit('updateIntroImageOpacity', value)
        },
        updateImage(image) {
            this.$emit('updateIntroImage', image);
        },
        deleteImage() {
            this.$emit('updateIntroImage', null);
        },
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
    }
}
</script>

