<template>
    <div class="platform_content">
        <div class="fake_index">
            <form id="" action="#">
                <div class="content_1">
                    <div class="content_2">
                        <h1>Declare iFrames</h1>
                        <!--iProfile-->
                        <custom-scene-input
                            v-for="(scene,index) in scenes"
                            :key="index"
                            :index="index"
                            :scene="scene"
                            @update:folder="updateFolder"
                            @update:startID="updateStartID"
                            @update:endID="updateEndID"
                        ></custom-scene-input>
                        <div class="clear" style="height: 24px;"></div>
                        <div class="declare_iframes_button"
                             @click="saveSceneData"
                        >
                            Update Scenes Data
                        </div>
                    </div>
                </div>
            </form>
            <!--START TO DECLARE THE IFRAMES / SCENES SOURCES + FOLDERS + IDS-->

            <!--START THE BUILDING SCENE-->
            <div class="content_1">
                <div class="content_2">
                    <h1>Video Scene Build</h1>
                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[0] Animated Background</strong>
                            <span>[for Business or Personal use]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iAnimatedBg<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[1] Animated Logo</strong>
                            <span>[for Business or Personal use]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iLogoData<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[2] Intro</strong>
                            <span>[for Business or Personal use]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[3] Outro</strong>
                            <span>[for Business or Personal use]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>
                    <!------------------------------------------------------------------------------------------------------------------------------------>
                    <div class="declare_iframes_spacer"></div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[4] Images to Video</strong>
                            <span>[for Business or Personal use]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iPhotosToVideoData<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[5] Text to Video</strong>
                            <span>[for Business or Personal use]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iTextToVideoData<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>
                    <!------------------------------------------------------------------------------------------------------------------------------------>
                    <div class="declare_iframes_spacer"></div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[6] Shop Offer to Video</strong>
                            <span>[for Online Shop Owners to promote online shop product or products offer]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iProductToVideoData<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>
                    <!------------------------------------------------------------------------------------------------------------------------------------>
                    <div class="declare_iframes_spacer"></div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[7] Travel Offer to Video</strong>
                            <span>[for Travel Agencies to promote travel offer or offers]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iTravelToVideoData<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[8] Real Estate Offer to Video</strong>
                            <span>[for Real Estate Agencies to promote their offers]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iRealEstateToVideoData<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>

                    <div class="building_scene_block">
                        <div class="building_scene_title">
                            <strong>[9] Event Video</strong>
                            <span>[for Event Creators to promote their events]</span>
                        </div>
                        <p>
                            + iProfile [!Attention]<br/>
                            + iBackgroundAnime [OR] iBackgroundStatic<br/>
                            + iIntro<br/>
                            + iEventToVideoData<br/>
                            + iOutro<br/>
                            + iOverlay<br/>
                            + iBorder<br/>
                            + iWatermark<br/>
                        </p>
                    </div>
                </div>
            </div>
            <!--END THE BUILDING SCENE-->


        </div>
    </div>
</template>

<script>
import CustomSceneInput from "./common/CustomSceneInput.vue";

export default {
    name: "SceneTemplates",
    components: {CustomSceneInput},
    data: () => {
        return {
            scenes: []
        }
    },
    mounted() {
        axios.get('/api/admin/scene-templates')
            .then(response => {
                this.scenes = JSON.parse(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    },
    methods: {
        updateFolder(name, index) {
            this.scenes[index].folder = name;
        },
        updateStartID(id, index) {
            this.scenes[index].startID = parseInt(id);
        },
        updateEndID(id, index) {
            this.scenes[index].endID = parseInt(id);
        },
        saveSceneData() {
            let formData = new FormData();
            formData.append('json', JSON.stringify(this.scenes));
            axios.post('/api/admin/scene-templates', formData)
                .then(response => {
                    this.scenes = JSON.parse(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>

</style>
