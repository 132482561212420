<template>
    <!--START THE BIG RIGHT MESSAGE -> IFRAME CANNOT BE GENERATED-->
    <div class="iframe_loader">
        <div class="iframe_loader_bg">
            <div class="iframe_message_block">
                <div class="iframe_message_txt" v-html="iframeErrorMessage"></div>
                <div class="iframe_message_img"></div>
            </div>
        </div>
    </div>
    <!--END THE BIG RIGHT MESSAGE -> IFRAME CANNOT BE GENERATED-->
</template>

<script>
export default {
    name: "Error",
    props:{
        iframeErrorMessage:{
            type:String,
            default: 'Submit Data to<br/> be the Hunter of Your Videos'
        }
    }
}
</script>

<style scoped>

</style>
