<template>
    <div class="vid_rendering_progress">
        <div class="vid_progress_bar" :style="{ width: progressBarWidth }">
        </div>
        <span>
                <strong id="animation_rendering_time">{{ animationRenderingTime }}</strong> Kingly Rendering ...
            </span>
        <div class="lds-ring lds-ring4-superwhite">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Rendering",
    props: {
        renderingTime: {
            type: Number,
            default: 60,
        },
        aspectRatio: {
            type: String,
            default: 'square',
        },
        animation: {
            type: Object,
            default: () => ({})
        },
        animationStatus: {
            type: String,
            default: 'not-rendered',
        },
        initialRenderingTimeStored: {
            type: Number,
            default: 0,
        },
        reactivityTrigger: {
            type: Number,
            default: 0
        }
    },
    watch: {
        animationStatus(newValue) {
            // console.log('animationStatus rendered :::', newValue);
            if (newValue === 'rendered') {
            }
        }
    },
    computed: {
        animationRenderingTime() {
            const hours = Math.floor(this.renderingTime / 3600);
            const minutes = Math.floor((this.renderingTime % 3600) / 60);
            const secondsLeft = this.renderingTime % 60;

            // Pad the minutes and seconds with leading zeros, if required
            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = secondsLeft.toString().padStart(2, '0');

            // If there are no hours, return MM:SS. Otherwise, return HH:MM:SS.
            return hours > 0
                ? `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
                : `${paddedMinutes}:${paddedSeconds}`;
        },
        progressBarWidth() {
            const initialTime = this.initialRenderingTime;
            if (initialTime > 0) {
                const elapsed = (initialTime - this.renderingTime) / initialTime * 100;
                return `${Math.min(Math.max(elapsed, 0), 100)}%`;
            }
            return '0%';
        },
        initialRenderingTime() {
            // Use stored initial time if available, otherwise fall back to current rendering time
            return this.initialRenderingTimeStored || this.renderingTime;
        },
    },
    methods: {}
}
</script>
