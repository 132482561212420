<template>
    <div class="price_inputs_block">
        <div class="price_inputs_type1"
             :class="{active:product.discounted}"
             v-if="product.discounted"
        >
            <!--NEW PRICE-->
            <div class="price_inputs_type1_col1">
                <label class="mylabel_1" for=""><span>New Price</span></label>
                <div
                    class="textarea_block textarea_block_singleline txa2 txa3 txa4 txa6 txa7 txa8 txa9">
                    <trumbowyg
                        v-model="new_price"
                        :config="textareaConfig"
                        :disabled="new_price_disabled"
                        class="rl_textarea_input"
                        @tbw-change="productNewPriceChanged($event,index)"
                        name="content">
                    </trumbowyg>
                </div>

            </div>
            <!--OLD PRICE-->
            <div class="price_inputs_type1_col2">
                <label class="mylabel_1" for=""><span>Old Price</span></label>
                <div
                    class="textarea_block textarea_block_singleline txa2 txa3 txa4 txa6 txa7 txa8 txa9">
                    <trumbowyg
                        v-model="old_price"
                        :disabled="old_price_disabled"
                        :config="textareaConfig"
                        class="rl_textarea_input"
                        @tbw-change="productOldPriceChanged($event,index)"
                        name="content">
                    </trumbowyg>
                </div>
            </div>
            <!--Disccount-->
            <div class="price_inputs_type1_col3">
                <label class="mylabel_1" for=""><span>Disccount</span></label>
                <div
                    class="textarea_block textarea_block_singleline txa2 txa3 txa4 txa6 txa7 txa8 txa9">
                    <trumbowyg
                        v-model="discount"
                        :disabled="discount_disabled"
                        :config="textareaConfig"
                        class="rl_textarea_input"
                        @tbw-change="productDiscountChanged($event,index)"
                        name="content">
                    </trumbowyg>
                </div>
            </div>
        </div>
        <div class="price_inputs_type2"
             :class="{active:!product.discounted}"
        >
            <label class="mylabel_1" for=""><span>Price Value</span></label>
            <div
                class="textarea_block textarea_block_singleline txa2 txa3 txa4 txa6 txa7 txa8 txa9">
                <trumbowyg
                    v-model="price"
                    :disabled="price_disabled"
                    :config="textareaConfig"
                    class="rl_textarea_input"
                    @tbw-change="productPriceChanged($event,index)"
                    name="content">
                </trumbowyg>
            </div>
        </div>
    </div>
</template>
<script>
import {checkTextAreaLength} from "../helpers/index.js";

export default {
    name: "ProductPrices",
    props: {
        product: {
            type: Object,
            default: {}
        },
        index: {
            type: Number,
            default: 0
        },
        productNewPriceChanged: {
            type: Function,
            default: () => {
            }
        },
        productOldPriceChanged: {
            type: Function,
            default: () => {
            }
        },
        productDiscountChanged: {
            type: Function,
            default: () => {
            }
        },
        productPriceChanged: {
            type: Function,
            default: () => {
            }
        },
        textareaConfig: {
            type: Object,
            default: () => ({
                autogrow: true,
                removeformatPasted: true,
                // Adding color plugin button
                tagsToRemove: ['script', 'link'],
                btns: ['strong', 'em', 'del', 'underline', 'superscript', 'subscript', 'foreColor', 'backColor', '|', 'viewHTML'],
                btnsAdd: ['foreColor', 'backColor'],
            })
        },
    },
    watch: {

        'new_price'(newValue, oldValue) {
            if (this.first_load) {
                return;
            }
            checkTextAreaLength(newValue, this, 'new_price', 'new_price_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.productNewPriceChanged({
                            target: {
                                value
                            }
                        }, this.index)
                    })
                })
            })
        },
        'old_price'(newValue, oldValue) {
            if (this.first_load) {
                return;
            }
            checkTextAreaLength(newValue, this, 'old_price', 'old_price_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.productOldPriceChanged({
                            target: {
                                value
                            }
                        }, this.index)
                    })
                })
            })
        },
        'discount'(newValue, oldValue) {
            if (this.first_load) {
                return;
            }
            checkTextAreaLength(newValue, this, 'discount', 'discount_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.productDiscountChanged({
                            target: {
                                value
                            }
                        }, this.index)
                    })
                })
            })
        },
        'price'(newValue, oldValue) {
            if (this.first_load) {
                return;
            }
            checkTextAreaLength(newValue, this, 'price', 'price_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.productPriceChanged({
                            target: {
                                value
                            }
                        }, this.index)
                    })
                })
            })
        },
    },
    created() {
        this.new_price = this.product.new_price;
        this.old_price = this.product.old_price;
        this.discount = this.product.discount;
        this.price = this.product.price;
        setTimeout(() => {
            this.first_load = false
        })
    },
    data: () => {
        return {
            new_price: '',
            new_price_disabled: false,
            old_price: '',
            old_price_disabled: false,
            discount: '',
            discount_disabled: false,
            price: '',
            price_disabled: false,
            first_load: true
        }
    },
}
</script>
