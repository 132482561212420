<template>
    <!--START MINI VIDEO [IS COMMON ON SPEED & TIMING / SCALE / FILTERS] -> USED ON SPEED & TIMING / SCALE / FILTERS-->
    <!--FLORINXXX -> FOLLOW CLOSLY WHAT YOU NEED TO UPDATE IN STYLE -> THERE ARE A FEW-->
    <!--FLORINXXX -> .minivideo_container -> CHECK THE FILTERS VALUES IN STYLLE-->
    <div :style="{filter: 'brightness(' + (data.filters.brightness/100) + ') contrast(' + (data.filters.contrast/100) + ') grayscale(' + (data.filters.grayscale/100) + ') hue-rotate(' + data.filters.hue + 'deg) invert(' + (data.filters.invert/100) + ') saturate(' + (data.filters.saturate/100) + ') sepia(' + (data.filters.sepia/100) + ')'}"
         class="minivideo_container">
        <div class="minivideo_bg"></div>

        <!--FLORINXX -> .minivideo_wm -> UPDATE WATERMARK (DISPLAY BRND DATA OVER VIDEO) VALUES-->
        <!--FLORINXX -> ADD .none IF WE DON'T HAVE WATERMARK-->
        <div class="minivideo_wm_container">
            <div
                v-if="data.watermark.active"
                :style="{
                width: 'calc(28px + (('+data.watermark.scale+' - 100) * 0.1px))',
                height: 'calc(28px + (('+data.watermark.scale+' - 100) * 0.1px))',
                'margin-top': 'calc((28px + (('+data.watermark.scale+' - 100) * 0.1px)) * -0.5)',
                'margin-left': 'calc((28px + (('+data.watermark.scale+' - 100) * 0.1px)) * -0.5)',
                left: data.watermark.x+'%',
                top: data.watermark.y+'%'}"
                class="minivideo_wm">
                <!--                                <div class="minivideo_wm" style="-->
                <!--                            /*100 (1ST VALUE) is Watermark Scale Value*/ width: calc(28px + ((100 - 100) * 0.1px));-->
                <!--                            /*100 (1ST VALUE) is Watermark Scale Value*/ height: calc(28px + ((100 - 100) * 0.1px));-->
                <!--                            /*100 (1ST VALUE) is Watermark Scale Value*/ margin-top: calc((28px + ((100 - 100) * 0.1px)) * -0.5);-->
                <!--                            /*100 (1ST VALUE) is Watermark Scale Value*/ margin-left: calc((28px + ((100 - 100) * 0.1px)) * -0.5);-->
                <!--                            /*88% is Watermark Horizontal Position*/ left: 88%;-->
                <!--                            /*88% is Watermark Vertical Position*/ top: 88%;-->
                <!--                        ">-->
                <div>
                    <strong>My</strong>
                    <span>Logo</span>
                </div>
            </div>
        </div>

        <!--FLORINXX -> .minivideo_block -> CHECK THE SCALE IN STYLE-->
        <div :style="{
                width: 'calc(100% - ((100 - ('+data.scale.x+' * 1)) * 1px))',
                height: 'calc(100% - ((100 - ('+data.scale.y+' * 1)) * 1px))',
                left: 'calc(((100 - ('+data.scale.x+' * 1)) / 2) * 1px)',
                top: 'calc(((100 - ('+data.scale.y+' * 1)) / 2) * 1px)'
             }"
             class="minivideo_block">
            <!--                                <div class="minivideo_block" style="-->
            <!--                            /*80 is ScaleX Value*/ width: calc(100% - ((100 - (80 * 1)) * 1px));-->
            <!--                            /*80 is ScaleY Value*/ height: calc(100% - ((100 - (80 * 1)) * 1px));-->
            <!--                            /*80 is ScaleX Value*/ left: calc(((100 - (80 * 1)) / 2) * 1px);-->
            <!--                            /*80 is ScaleY Value*/ top: calc(((100 - (80 * 1)) / 2) * 1px);-->
            <!--                        ">-->
            <!--FLORINXX -> .minivideo_animation -> CHECK THE SPEED & TIMING-->
            <div :style="{
                    'animation-duration': 'calc((6 * (1 / '+data.speed_and_timing.speed+')) * 1s)',
                    'animation-timing-function': animationTimingFunction
                 }"
                 class="minivideo_animation">
                <!--                                    &lt;!&ndash;FLORINXX -> .minivideo_animation -> CHECK THE SPEED & TIMING&ndash;&gt;-->
                <!--                                    <div class="minivideo_animation" style="-->
                <!--                                /*1.1 is the speed value*/ animation-duration: calc((6 * (1 / 1.1)) * 1s);-->
                <!--                                /*ease-in-out -> ADJUS TIMING ACCORDING*/ animation-timing-function: ease-in-out;-->
                <!--                            ">-->
            </div>
        </div>
    </div>
    <!--END MINI VIDEO [IS COMMON ON SPEED & TIMING / SCALE / FILTERS] -> USED ON SPEED & TIMING / SCALE / FILTERS-->
</template>

<script>
export default {
    name: "MiniVideo",
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        animationTimingFunction() {
            switch (this.data.speed_and_timing.timing) {
                case 'Default':
                    // code block
                    return 'ease-in-out';
                case 'Quad':
                    return 'cubic-bezier(0.455, 0.030, 0.515, 0.955)';
                case 'Cubic':
                    return 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
                case 'Quart':
                    return 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
                case 'Quint':
                    return 'cubic-bezier(0.860, 0.000, 0.070, 1.000)';
                case 'Sine':
                    return 'cubic-bezier(0.445, 0.050, 0.550, 0.950)';
                case 'Expo':
                    return 'cubic-bezier(1.000, 0.000, 0.000, 1.000)';
                case 'Circ':
                    return 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
                case 'Back':
                    return 'cubic-bezier(0.680, -0.550, 0.265, 1.550)';
                default:
                    return 'ease-in-out';
            }
        }
    }
}
</script>

<style scoped>

</style>
