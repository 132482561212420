<template>
    <!--START FILTERS -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div class="video_settings_button"
             @click="$emit('setActiveChild',$options.name)"
        >
            <strong>Filters</strong> <span class="material-icons">arrow_drop_down</span>
        </div>
        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="editor_block editor_block_logo editor_filters_block">
                <div class="editor_logo_container_left editor_filters_display">
                    <div class="editor_logo_display">
                        <mini-video
                            :data="data"
                        />
                    </div>
                </div>

                <div class="reset_settings_filters"
                     @click="resetFilters"
                >
                    Reset Filters
                </div>

                <div class="clear" style="height: 12px"></div>

                <!--
                    BRIGHTNESS
                        EDITOR VALUES: min: 0 / max: 300 / default: 100 / step: 10
                        CSS VALUES: min: 0 / max: 3 / default: 1
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE / 100)
                -->
                <!--brightness(1)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vbrightness"><span>Brightness</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'brightness'"
                            :increment="10"
                            :max="300"
                            :min="0"
                            :unit="'%'"
                            :value="data.filters.brightness"
                            @onInputChange="updateFiltersBrightness"
                        />
                    </div>
                </div>

                <!--
                    CONTRAST
                        EDITOR VALUES: min: 0 / max: 300 / default: 100 / step: 10
                        CSS VALUES: min: 0 / max: 3 / default: 1
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE / 100)
                -->
                <!--contrast(1)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vcontrast"><span>Contrast</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'contrast'"
                            :increment="10"
                            :max="300"
                            :min="0"
                            :unit="'%'"
                            :value="data.filters.contrast"
                            @onInputChange="updateFiltersContrast"
                        />
                    </div>
                </div>

                <!--
                    GRAYSCALE
                        EDITOR VALUES: min: 0 / max: 100 / default: 0 / step: 10
                        CSS VALUES: min: 0 / max: 1 / default: 0
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE / 100)
                -->
                <!--grayscale(0)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vgrayscale"><span>Grayscale</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'grayscale'"
                            :increment="10"
                            :max="100"
                            :min="0"
                            :unit="'%'"
                            :value="data.filters.grayscale"
                            @onInputChange="updateFiltersGrayscale"
                        />
                    </div>
                </div>

                <!--
                    HUE
                        EDITOR VALUES: min: -360 / max: 360 / default: 0 / step: 10
                        CSS VALUES: min: -360 / max: 360 / default: 0
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE)
                -->
                <!--hue-rotate(0deg)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vhue"><span>Hue</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'grayscale'"
                            :increment="10"
                            :max="360"
                            :min="-360"
                            :unit="'%'"
                            :value="data.filters.hue"
                            @onInputChange="updateFiltersHue"
                        />
                    </div>
                </div>

                <!--
                    INVERT
                        EDITOR VALUES: min: 0 / max: 100 / default: 0 / step: 10
                        CSS VALUES: min: 0 / max: 1 / default: 0
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE / 100)
                -->
                <!--invert(0)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vinvert"><span>Invert</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'invert'"
                            :increment="10"
                            :max="100"
                            :min="0"
                            :unit="'%'"
                            :value="data.filters.invert"
                            @onInputChange="updateFiltersInvert"
                        />
                    </div>
                </div>

                <!--
                    SATURATE
                        EDITOR VALUES: min: 0 / max: 300 / default: 100 / step: 10
                        CSS VALUES: min: 0 / max: 3 / default: 1
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE / 100)
                -->
                <!--saturate(1)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vsaturate"><span>Saturate</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'saturate'"
                            :increment="10"
                            :max="100"
                            :min="0"
                            :unit="'%'"
                            :value="data.filters.saturate"
                            @onInputChange="updateFiltersSaturate"
                        />
                    </div>
                </div>

                <!--
                    SEPIA
                        EDITOR VALUES: min: 0 / max: 100 / default: 0 / step: 10
                        CSS VALUES: min: 0 / max: 1 / default: 0
                        CALUCLATE DISPLAY VALUES: calc(EDITOR VALUE / 100)
                -->
                <!--sepia(0)-->
                <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                <!--FLORINXXX -> WE ALSO HAVE A SLIDER HERE -> BETTER FOR USER EXPERIENCE-->
                <div class="editor_block" style="padding-top: 12px;">
                    <label class="mylabel_1" for="vsepia"><span>Sepia</span></label>
                    <div class="filter_input_block">
                        <input-slider
                            :id="'sepia'"
                            :increment="10"
                            :max="100"
                            :min="0"
                            :unit="'%'"
                            :value="data.filters.sepia"
                            @onInputChange="updateFiltersSepia"
                        />
                    </div>
                </div>
            </div>
            <!--ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD-->
        </div>
    </li>
    <!--END FILTERS -> VIDEO SETTINGS-->
</template>

<script>
import InputSlider from "./common/InputSlider.vue";
import MiniVideo from "./common/MiniVideo.vue";

export default {
    name: "Filters",
    components: {
        InputSlider,
        MiniVideo
    },
    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            filters: {
                brightness: 100,
                contrast: 100,
                grayscale: 0,
                hue: 0,
                invert: 0,
                saturate: 100,
                sepia: 0
            }
        }
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        }
    },
    methods: {
        //filters
        resetFilters() {
            this.$emit('resetFilters');
        },
        updateFiltersBrightness(value) {
            this.$emit('updateFiltersBrightness', value);
        },
        updateFiltersContrast(value) {
            this.$emit('updateFiltersContrast', value);
        },
        updateFiltersGrayscale(value) {
            this.$emit('updateFiltersGrayscale', value);
        },
        updateFiltersHue(value) {
            this.$emit('updateFiltersHue', value);
        },
        updateFiltersInvert(value) {
            this.$emit('updateFiltersInvert', value);
        },
        updateFiltersSaturate(value) {
            this.$emit('updateFiltersSaturate', value);
        },
        updateFiltersSepia(value) {
            this.$emit('updateFiltersSepia', value);
        }
    }
}
</script>

<style scoped>

</style>
