<template>
    <div class="editor_rightside_inner">


        <!--START THE BIG IFRAMES PRELOADER/LOADER-->
        <iframe-preloader v-if="loading"/>
        <!--END THE BIG IFRAMES PRELOADER/LOADER-->


        <!--START THE NOTIFICATIONS -> MAY APPEAR IN SOME CASES -> NOT CLEAR YET THIS CASES-->
        <div class="iframe_notif error none">
            Error Occured!
        </div>
        <div class="iframe_notif notification none">
            Notification Occured!
        </div>
        <div class="iframe_notif success none">
            Success Occured!
        </div>
        <!--END THE NOTIFICATIONS -> MAY APPEAR IN SOME CASES -> NOT CLEAR YET THIS CASES-->

        <!--START THE BIG RIGHT MESSAGE -> IFRAME CANNOT BE GENERATED-->
        <iframe-error v-if="iframeError" :iframeErrorMessage="iframeErrorMessage"/>
        <!--END THE BIG RIGHT MESSAGE -> IFRAME CANNOT BE GENERATED-->

        <!--START IFRAME ITEM-->
        <div class="iframe_block">
            <div class="iframe_block_content">
                <div class="iframe_item_block">
                    <iframe
                        class="iframe_as_item"
                        ref="florinIframe"
                        :src="currentIframeUrl"
                        :key="iframeKey"
                        @load="checkAndExtractTimeInSeconds"
                        @error="iframeError = true"
                    />

                    <div class="iframe_as_list_option_container clr"
                         v-if="shouldShowIframeOptions"
                    >
                        <!--ALEX HELP - SCENE ID-->
                        <div
                            class="cs_button_guide"
                            :class="'sn_' + currentIframeIndexValue"
                        >
                            25{{ currentIframeIndexValue }}52 /
                            {{ currentIframeIndexValueStart }} /
                            {{ currentIframeIndexValueEnd }}
                        </div>

                        <!--PREV GENERATE-->
                        <div class="cs_button_gen"
                             :class="{'cs_button_prev': true, 'active': hasPreviousIframe }"
                             @click="navigateToPreviousIframe
                        ">
                            <span class="material-icons">chevron_left</span>
                        </div>

                        <!--ADD TO FAV-->
                        <div class="cs_button_fav"
                             @click="toggleCurrentIframeFavoritedStatus"
                             v-if="!isCurrentIframeFavored && !showFromFavorites"
                             title="Add to Favorites"
                        >
                            <span class="cs_icon1 material-icons">favorite_border</span>
                            <span class="cs_line1"
                                  :class="'cs_line1_' + currentIframeIndexValueEnd"
                            ></span>
                            <span
                                class="cs_line2"
                                :class="'cs_line2_' + currentIframeIndexValueStart"
                            ></span>
                        </div>

                        <!--REMOVE FROM FAV-->
                        <div class="cs_button_unfav"
                             v-if="isCurrentIframeFavored || showFromFavorites"
                             @click="toggleCurrentIframeFavoritedStatus"
                             title="Remove from Favorites"
                        >
                            <span class="cs_icon1 material-icons">favorite</span>
                            <span class="cs_line1"
                                  :class="'cs_line1_' + currentIframeIndexValueEnd"
                            ></span>
                            <span
                                class="cs_line2"
                                :class="'cs_line2_' + currentIframeIndexValueStart"
                            ></span>
                        </div>

                        <!--PREV GENERATE-->
                        <div class="cs_button_gen"
                             :class="{'cs_button_next': true, 'active': hasNextIframe }"
                             @click="navigateToNextIframe"
                        >
                            <span class="material-icons">chevron_right</span>
                        </div>

                        <!--LOAD FROM-->
                        <div class="cs_load_from">
                            <span class="cs_load_from_title">Load from</span>
                            <span class="cs_load_from_ai_default">AI</span>
                            <span class="cs_load_from_favs_default">Favs</span>

                            <div class="cs_load_ai"
                                 :class="{'active': !showFromFavorites }"
                                 @click="toggleFavoritesDisplay"
                                 v-if="showFromFavorites"
                            >
                                <span class="cs_load_from_ai">AI</span>
                                <span class="cs_load_from_favs">Favs</span>
                            </div>
                            <div class="cs_load_favs"
                                 :class="{
                                    'active': showFromFavorites,
                                    'hasFavorites': favored.length
                                }"
                                 @click="toggleFavoritesDisplay"
                                 v-if="favored.length && !showFromFavorites"
                            >
                                <span class="cs_load_from_ai">AI</span>
                                <span class="cs_load_from_favs">Favs</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--END IFRAME ITEM-->
    </div>
</template>
<script>
import IframePreloader from "../Iframes/common/Preloader.vue";
import IframeError from "../Iframes/common/Error.vue";


export default {
    name: "Index",
    components: {
        IframePreloader,
        IframeError,
    },
    data: () => {
        return {
            currentIframeIndex: 0, // Tracks the index of the currently displayed iframe
            showFromFavorites: false, // Flag to determine if favorites should be shown,
        }
    },
    props: {
        currentScene: {
            type: Object,
            default: () => {
            }
        },
        iframeUrl: {
            type: String,
            default: ''
        },
        iframes: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        iframeError: {
            type: Boolean,
            default: false
        },
        iframeErrorMessage: {
            type: String,
            default: 'Submit Data to<br/> be the Hunter of Your Videos'
        },
        favored: {
            type: Array,
            default: () => []
        },
        selectedSceneIndex: {
            type: Number,
            default: null
        },
        sceneIdName: {
            type: String,
            default: null
        },
        iframeKey: {
            type: String,
            default: 'florin'
        },
        initialData: {
            type: Object,
            default: function () {
                return {
                    type: 'create',
                };
            }
        }
    },

    created() {
        this.initializeDisplayedIframe(); // Set up the initial iframe display
    },
    computed: {
        // Check if the current iframe is in the list of favorites
        isCurrentIframeFavored() {

            return this.iframes.length && this.favored.some(favoredItem => Number(favoredItem.index) === Number(this.iframes[this.currentIframeIndex].index));
        },
        // Determine the list of iframes to be displayed, based on whether favorites are being shown
        displayedIframeList() {
            return this.showFromFavorites ? this.favored : this.iframes;
        },
        // Check if there is a previous iframe to navigate to
        hasPreviousIframe() {
            return this.currentIframeIndex > 0 && this.displayedIframeList.length;
        },
        // Check if there is a next iframe to navigate to
        hasNextIframe() {
            return this.currentIframeIndex < this.displayedIframeList.length - 1;
        },
        // Determine if iframe options should be displayed
        shouldShowIframeOptions() {
            return this.currentScene && this.currentScene.name && this.currentScene.name !== 'Profile' && this.currentScene.name !== 'Settings' && !this.loading;
        },
        // Compute the URL for the current iframe
        currentIframeUrl() {
            if (this.iframeKey && this.iframeUrl) {
                return this.iframeUrl;
            }
            const indexInList = this.displayedIframeList.findIndex(iframe => iframe.index === this.selectedSceneIndex);

            if (this.currentScene && this.currentScene.name !== 'Profile' && (this.currentScene.name !== 'Logo Data' && this.initialData.type === 'create') && this.displayedIframeList[indexInList]) {
                return this.displayedIframeList[indexInList].url;
            } else {
                return this.iframeUrl;
            }
        },
        // Get the index of the current iframe
        currentIframeIndexValue() {
            if (this.displayedIframeList.length) {
                return Number(this.displayedIframeList[this.currentIframeIndex].index);
            } else {
                return null;
            }
        },
        // Get START and END SCENE VALUES
        currentIframeIndexValueStart() {
            return this.currentIframeIndexValue.toString().slice(0, 3);
        },
        currentIframeIndexValueEnd() {
            let valueStr = this.currentIframeIndexValue.toString();
            return valueStr.slice(Math.max(valueStr.length - 2, 0));
        }
    },
    watch: {
        'favored': {
            handler(newList, oldList) {
                if (newList.length === 0) {
                    this.showFromFavorites = false;
                    // this.currentIframeIndex = 0;
                    // this.emitSelectedIframe();
                }
                if (newList.length < oldList.length) {
                    if (this.currentIframeIndex > 0) {
                        // this.currentIframeIndex += this.currentIframeIndex;
                        this.currentIframeIndex -= 1;
                    } else if (newList.length === 0) {
                        this.showFromFavorites = false;
                        this.currentIframeIndex = 0;
                        this.emitSelectedIframe();
                    }
                }
            }
        },
        'favored.length': function (newLength, oldLength) {
            if (newLength < oldLength && this.showFromFavorites) {
                this.emitSelectedIframe();
            }
        },
        'iframes.length': function (newLength, oldLength) {
            if (newLength !== oldLength && !this.showFromFavorites) {
                this.emitSelectedIframe();
            }
        },
        'sceneIdName': function (newSceneId, oldSceneId) {
            if (newSceneId !== oldSceneId) {
                this.currentIframeIndex = 0;
                this.showFromFavorites = false;
                this.emitSelectedIframe();
            }
        }
    },
    methods: {
        // Set up the index for the currently displayed iframe based on the selected scene
        initializeDisplayedIframe() {
            if (this.selectedSceneIndex !== null) {
                const indexInList = this.displayedIframeList.findIndex(iframe => iframe.index === this.selectedSceneIndex);

                if (indexInList !== -1) {
                    this.currentIframeIndex = indexInList;
                    this.emitSelectedIframe();
                }
            }
        },
        // Emit an event with the currently selected iframe information
        emitSelectedIframe() {
            if (this.displayedIframeList.length && this.currentScene) {
                this.$emit('selectIframe', {
                    type: this.currentScene.name,
                    iframe: parseInt(this.displayedIframeList[this.currentIframeIndex].index),
                    favored: true
                }, true);
            }
        },
        // Emit an error event for the iframe
        iframeSetError() {
            this.$emit('iframeError');
        },
        handleIframeError() {
            this.$emit('iframeError');
        },
        // Toggle the display of favorites
        toggleFavoritesDisplay() {
            this.showFromFavorites = !this.showFromFavorites;
            this.currentIframeIndex = 0;
            this.initializeDisplayedIframe();
            this.emitSelectedIframe();
        },
        // Add or remove the current iframe from favorites
        toggleCurrentIframeFavoritedStatus() {
            const currentIframe = this.displayedIframeList[this.currentIframeIndex];
            this.$emit('toggleFavored', currentIframe.index);

            this.$nextTick(() => {
                if (this.showFromFavorites) {
                    const indexInList = this.displayedIframeList.findIndex(iframe => iframe.index === currentIframe.index);
                    if (indexInList === -1) {
                        // Current item was removed from favorites
                        this.currentIframeIndex = Math.max(0, this.currentIframeIndex - 1);
                    }
                }
            });
        },
        // Navigate to the next iframe
        navigateToNextIframe() {
            if (this.hasNextIframe) {
                this.currentIframeIndex += 1;
                this.$emit('iframeLoading');
                this.emitSelectedIframe();
            }
        },
        // Navigate to the previous iframe
        navigateToPreviousIframe() {
            if (this.hasPreviousIframe) {
                this.currentIframeIndex -= 1;
                this.$emit('iframeLoading');
                this.emitSelectedIframe();
            }
        },
        // Extract the number of frames from the current iframe
        checkAndExtractTimeInSeconds() {
            if (!this.loading) return;
            const iframe = this.$refs.florinIframe;

            if (iframe && iframe.contentWindow && typeof iframe.contentWindow.getVideoTimeInSconds === 'function') {
                const result = iframe.contentWindow.getVideoTimeInSconds();

                if (result !== 'loading') {
                    // If the frame number is available, emit the event with the frame number
                    this.$emit('iframeLoaded', result);
                } else {
                    // If the result is still 'loading', set a timeout to retry
                    setTimeout(() => {
                        this.checkAndExtractTimeInSeconds();
                    }, 1000); // Retry after 1 second
                }
            } else {
                // Handle the case where iframe or its contentWindow is not accessible
                console.error('Iframe or its content is not accessible.');
            }
        },
    }
}

</script>
