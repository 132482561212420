<template>
    <div class="popup_block active">
        <div class="popup_container">
            <div
                class="popup_close_button"
                title="Close"
                @click="toggleShowContentSuggestion()"
            >
                <div><span class="material-icons">close</span></div>
            </div>

            <div class="popup_content">
                <h2>Content Suggestion</h2>
                <div class="txts_block">
                    <ul>
                        <li v-for="(content, index) in contentSuggestion" @click="selectContentSuggestion(index)">
                            <div class="txts_button" :class="{active: index === selectedIndex}" v-if="content.type === 1 || content.type === 3">
                                <div class="txts_title_block" v-if="content.headline" v-html="content.headline"></div>
                                <div class="txts_text_block" v-if="content.text" v-html="content.text"></div>
                            </div>
                            <div class="txts_button" :class="{active: index === selectedIndex}" v-else>
                                <div class="txts_text_block" v-if="content.text" v-html="content.text"></div>
                                <div class="txts_title_block" v-if="content.headline" v-html="content.headline"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="popup_footer">
                <div class="popup_footer_block">
                    <!--FLORINXXXX -> ON CLICK .popup_button_update -> UPDATE-->
                    <!--FLORINXXXX -> ADD/REMOVE .disable ACCORDING-->
                    <div class="popup_button_update"
                         :class="{disable:selectedIndex===null}"
                         @click="updateTheContentSuggestion"
                    >
                        <div style="padding-right: 12px;">
                            Update
                            <span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span>
                        </div>
                    </div>
                    <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                    <div class="popup_button_cancel"
                         @click="toggleShowContentSuggestion()"
                    >
                        <div style="padding-left: 16px;">
                            Cancel
                            <span class="material-icons" style="top: 14px; left: -10px;">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentSuggestion",
    props: {
        contentSuggestion: {
            type: Array,
            data: () => {
                return [];
            }
        },
        index: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selectedContentSuggestion: null,
            selectedIndex: null
        }
    },
    methods: {
        selectContentSuggestion(index) {
            this.selectedContentSuggestion = this.contentSuggestion[index];
            this.selectedIndex = index;
        },
        toggleShowContentSuggestion() {
            this.$emit('toggleShowContentSuggestion')
        },
        updateTheContentSuggestion() {
            if (this.selectedIndex === null) {
                return;
            }
            if(this.index !== null){
                this.$emit('updateTheContentSuggestion', this.selectedContentSuggestion,this.index)
            }else{
                this.$emit('updateTheContentSuggestion', this.selectedContentSuggestion)
            }
            this.$emit('toggleShowContentSuggestion')
        }
    }
}
</script>

<style scoped>

</style>
