<template>
    <!--START POPUP -> PHOTO GALLERY-->
    <!--FLORINXXXX -> ADD .active ON .popup_block TO ACTIVATE POPUP-->
    <!--FLORINXXXX -> ON CLICK .popup_close_button CLOSE POPUP -> REMOVE .active-->
    <div class="popup_block active">
        <div class="popup_container"
             ref="listEl"
             @scroll="onDivScroll"
             style="max-height: 100%; overflow-y: auto;"
        >
            <div class="popup_close_button"
                 title="Close"
                 @click="$emit('toggleShowImageGallery')"
            >
                <div><span class="material-icons">close</span></div>
            </div>

            <div class="popup_content">
                <label for="searchPhoto">
                    <h2>Photo Gallery</h2>
                </label>
                <div class="input_search_block">
                    <input
                        type="text"
                        id="searchPhoto"
                        name="searchPhoto"
                        v-model="searchTerm"
                        placeholder="Search photo"
                        class="input_search"
                    >
                    <div class="input_search_button" title="Search for Image"><span class="material-icons">search</span>
                    </div>

                    <!--FLORINXXXX -> ADD .active IF NO PHOTO FOUND-->
                    <div class="editor_notif editor_red"
                         :class="{active:(images.length === 0 && isLoading)}"
                    ><p>
                        <span class="material-icons">error_outline</span>
                        No photo found!
                    </p></div>
                </div>

                <div class="gallery_block">
                    <ul class="clr">
                        <li v-for="(image,index) in images" :key="index">
                            <div class="gallery_button"
                                 :class="{active:image ? isImageSelected(image.imageUrl):false}"
                                 @click="selectImage(image ? image.imageUrl:null)"
                            >
                                <div class="gallery_transbg">
                                    <div
                                        :style="{'background-image': 'url('+image.thumbUrl+')'}"
                                        class="gallery_photo">
                                            <!--<div class="gallery_photo_test_container"><img class="gallery_photo_test" :src="image.thumbUrl" /></div>-->
                                    </div>
                                </div>
                                <div class="gallery_title"></div>
                            </div>
                        </li>
                    </ul>

                    <!--FLORINXXXX -> ON LOADING MORE PHOTOS ADD CLASS .active-->
                    <div class="load_more_photos active"
                         :class="{active:isLoading}"
                         v-if="!finished"
                    >
                        <div style="width: 64px; height: 64px; left: 50%; margin-left: -32px;"
                             class="lds-ring lds-ring6">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="popup_footer">
                <div class="popup_footer_block">
                    <!--FLORINXXXX -> ON CLICK .popup_button_update -> UPDATE-->
                    <div class="popup_button_update"
                         @click="updateImage"
                    >
                        <div style="padding-right: 12px;">
                            <strong>Update</strong>
                            <span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span>
                        </div>
                    </div>
                    <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                    <div class="popup_button_cancel"
                         @click="$emit('toggleShowImageGallery')"
                    >
                        <div style="padding-left: 16px;">
                            Cancel
                            <span class="material-icons" style="top: 14px; left: -10px;">close</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--START POPUP -> PHOTO GALLERY-->
</template>

<script>

export default {
    name: "ImageGallery",
    props: {
        image: {
            type: String,
            default: 'https://images.unsplash.com/photo-1534188753412-3e26d0d618d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGxpb258ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60'
        },
        type: {
            type: String,
            default: 'single'
        }
    },
    data() {
        return {
            searchTerm: '',
            isLoading: false,
            page: 1,
            finished: false,
            images: [],
            selectedImage: null,
            selectedImages: []
        }
    },
    watch: {
        searchTerm: {
            handler() {
                this.handleSearchInput();
            },
            immediate: true
        }
    },
    mounted() {

    },

    methods: {
        onDivScroll() {
            const listElm = this.$refs.listEl;
            const distanceFromBottom =
                listElm.scrollHeight - listElm.scrollTop - listElm.clientHeight;

            if (distanceFromBottom < 1 && !this.finished && !this.isLoading) {
                this.isLoading = true;
                this.page++;
                this.loadImagesFromApi();
            }
        },
        isImageSelected(imgUrl) {
            if (this.type === 'single') {
                return imgUrl === this.selectedImage
            } else {
                return this.selectedImages.includes(imgUrl)
            }
        },
        handleSearchInput() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.page = 1;
                this.isLoading = true;
                this.images = [];
                this.loadImagesFromApi();
            }, 800);
        },
        loadImagesFromApi() {
            axios.get('/api/images-gallery?q=' + this.searchTerm + '&page=' + this.page + '&per_page=20&image_type=all&orientation=all&order=popular').then(response => {
                if (response.status !== 200 || !response.data.hits) {
                    this.images = [];
                    this.finished = true;

                    return;
                }

                let newImages = response.data.hits;
                this.images = [].concat(this.images, newImages);
                if (response.data.lastPage === this.page) {
                    this.finished = true;
                }
                this.isLoading = false;
            }).catch(err => {
                console.log(err)
            });
        },
        selectImage(imagePath) {
            if (this.type === 'single') {
                this.selectedImage = imagePath;
            } else {
                if (this.selectedImages.includes(imagePath)) {
                    const index = this.selectedImages.indexOf(imagePath);
                    this.selectedImages.splice(index, 1);
                } else {
                    this.selectedImages.push(imagePath);
                }
            }
        },
        updateImage() {
            if (this.type === 'single') {
                this.$emit('updateImage', this.selectedImage);
            } else {
                this.$emit('addSelectedImages', this.selectedImages);
            }
            this.$emit('toggleShowImageGallery');
        }
    }
}
</script>

<style scoped>

</style>
