<template>
    <!--START HANDLE THE IMAGES-->
    <div class="editor_block editor_images_block">
        <div class="editor_images_inner clr">
            <ol class="editor_images_list clr">
                <!--FLORINXXX -> UPDATE IMAGES URLS-->
                <!--FLORINXXX -> .editor_img_button_delete DELETE THE IMAGE-->
                <!--FLORINXXX -> .editor_img_button_goleft MOVE IMAGE TO THE LEFT-->
                <!--FLORINXXX -> .editor_img_button_goright MOVE IMAGE TO THE RIGHT-->
                <li v-for="(image,index) in images" :key="index">
                    <div class="editor_img_list_inner">
                        <div class="editor_img_list_photo">
                            <div
                                :style="{'background-image': 'url('+imageSrc(image)+')'}"
                                class="editor_img_list_photo_now">
                            </div>
                        </div>
                        <div class="editor_img_list_number"></div>
                        <div
                            class="editor_img_button_go editor_img_button_goleft"
                            @click="moveImageLeft(index)"
                        >
                            <span class="material-icons">navigate_before</span>
                        </div>
                        <div
                            class="editor_img_button_go editor_img_button_goright"
                            @click="moveImageRight(index)"
                        >
                            <span class="material-icons">navigate_next</span>
                        </div>

                        <div
                            class="editor_img_button_rotateleft"
                            title="Rotate left"
                            @click="rotateImageLeft(image,index)"
                        >
                            <span class="material-icons">
                                rotate_left
                            </span>
                        </div>
                        <div
                            class="editor_img_button_rotateright"
                            title="Rotate right"
                            @click="rotateImageRight(image,index)"
                        >
                            <span class="material-icons">
                                rotate_right
                            </span>
                        </div>

                        <div
                            class="editor_img_button_delete"
                            title="Delete photo"
                            @click="deleteImage(index)"
                        >
                            <span class="material-icons">clear</span>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
    <!--END HANDLE THE IMAGES-->
</template>

<script>
export default {
    name: "ImagesList",
    props: {
        images: {
            type: Array,
            default: () => ([])
        },
        index: {
            type: Number,
            default: null
        }
    },
    methods: {
        imageSrc(image) {
            if (image.includes('unsplash.com') || image.includes('https://storage.pixteller.com/')) {
                return image;
            } else {
                return 'https://storage.renderlion.com/' + image;
            }
        },
        deleteImage(index) {
            if (this.index !== null) {
                this.$emit('deleteImage', index, this.index);
            } else {
                this.$emit('deleteImage', index);
            }
        },
        moveImageLeft(index) {
            if (this.index !== null) {
                this.$emit('moveImageLeft', index, this.index);
            } else {
                this.$emit('moveImageLeft', index);
            }
        },
        moveImageRight(index) {
            if (this.index !== null) {
                this.$emit('moveImageRight', index, this.index);
            } else {
                this.$emit('moveImageRight', index);
            }
        },
        rotateImageLeft(image, index) {
            let imageUrl = image;

            if (!image.includes('https://')) {
                imageUrl = 'https://storage.renderlion.com/' + image;
            }
            axios.post('/api/resources/rotate-image',
                {
                    url: imageUrl,
                    orientation: 'left'
                }
            ).then((response) => {
                if (this.index !== null) {
                    this.$emit('updateImage', response.data, index, this.index);
                } else {
                    this.$emit('updateImage', response.data, index);
                }

            }).catch((error) => {

            });
        },
        rotateImageRight(image, index) {
            let imageUrl = image;

            if (!image.includes('https://')) {
                imageUrl = 'https://storage.renderlion.com/' + image;
            }
            axios.post('/api/resources/rotate-image',
                {
                    url: imageUrl,
                    orientation: 'right'
                }
            ).then((response) => {
                if (this.index !== null) {
                    this.$emit('updateImage', response.data, index, this.index);
                } else {
                    this.$emit('updateImage', response.data, index);
                }
            }).catch((error) => {

            });
        }
    }
}
</script>

<style scoped>

</style>
