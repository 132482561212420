<template>
    <!--START UPLOAD LOGO / AVATAR / IMAGES / PHOTOS / AND MORE-->
    <div class="editor_block" style="padding-top: 12px;"
         :class="{ mandatory : ui.errorMessage }"
    >
        <label class="mylabel_1" for=""><span>Upload Logo/Avatar</span></label>

        <!--FLORINXXX -> ADD .active WHEN UPLOADING-->
        <!--FLORINXXX -> ADD .error WHEN FILETYPE IS WRONG OR OTHER ERRORS ON UPLOADING-->
        <div class="my_upload_block"
             :class="{ active : ui.isUploading, error: errorMessage }"
        >
            <div class="my_upload_content">
                <div class="my_upload_content_inner">
                    <div class="my_upload_content_left">
                        <div class="my_upload_content_txt my_upload_content_normal">
                            Use high-resolution images<br/>
                            for best results.
                        </div>
                        <div class="my_upload_content_txt my_upload_content_error">
                            Error: {{ errorMessage }}
                        </div>
                    </div>
                    <div class="my_upload_content_right">
                        <div class="my_upload_content_button">Upload</div>
                    </div>
                    <input type="file"
                           class="my_upload_content_input"
                           ref="userLogo"
                           accept=".png, .jpg, .jpeg, .svg, .webp"
                           @change="uploadLogo"
                    >
                </div>

                <div class="my_upload_content_inner_uploading">
                    <div class="my_upload_content_left">
                        <div class="my_upload_content_txt">
                            Keep calm!<br/>
                            We are processing your data.
                        </div>
                    </div>
                    <div class="my_upload_content_right">
                        <div class="lds-ring lds-ring5">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <remove-background v-if="!isIOS()"
            :processing-background="processingBackground"
            :background-removed-successfully="backgroundRemovedSuccessfully"
            :file="file"
            @triggerBackgroundRemove="handleBackgroundRemove"
            @triggerUndoBackground="handleUndoBackground"
        />
    </div>
    <!--END UPLOAD LOGO / AVATAR / IMAGES / PHOTOS / AND MORE-->
</template>

<script>
import {
    compressImage, extractFilename, getDefaultLogoPath,
    getFileSizeInMb,
    imageUrlToFile, imglyConfig,
    isIOS,
    isSvg
} from "../../../helpers/index.js";
// import imglyRemoveBackground from "@imgly/background-removal";
import { removeBackground } from '@imgly/background-removal';
import RemoveBackground from "./../../RemoveBackground/Index.vue";

export default {
    name: "LogoUpload",
    components: {
        RemoveBackground
    },
    inject: ['triggerBackgroundRemoval'],
    data: () => {
        return {
            file: null,
            errorMessage: null,
            processingBackground: false,
            backgroundRemovedSuccessfully: false,
            backupFile: null,
            ui: {
                isUploading: false,
                hasErrors: false,
            }
        }
    },
    props: {
        logoDeleted: {
            type: Boolean,
            default: false
        },
        logo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    watch: {
        logoDeleted(newVal, oldVal) {
            if (newVal) {
                this.file = null;
                this.backupFile = null;
                this.backgroundRemovedSuccessfully = false;

            }
        },
        'logo.src'(newValue, oldValue) {
            if(newValue!==oldValue){
                this.settLogo(newValue)
            }
        }
    },
    created() {
        this.settLogo(this.logo.src);
    },
    methods: {
        isIOS,
        async settLogo(src = null) {
            let url;
            let filename;
            if (src) {
                url = src;
                filename = extractFilename(src);
            } else {
                url = '../images/editor/yourlogo.png';
                filename = 'yourlogo.png';
            }
            this.file = await imageUrlToFile(url, filename)
        },
        async uploadLogo(event) {
            const logoImage = this.$refs.userLogo.files[0];
            await this.processLogo(logoImage);
            event.target.value = '';

        },
        async processLogo(logoImage) {
            const self = this;
            self.errorMessage = null;

            const processFile = file => {
                return new Promise((resolve) => {
                    const fileSizeMB = getFileSizeInMb(file);
                    const maxFileSize = typeof __SINGLE_MAX_FILE_SIZE_UPLOAD__ !== 'undefined' ? parseInt(__SINGLE_MAX_FILE_SIZE_UPLOAD__) : 20;
                    if (fileSizeMB < maxFileSize) {
                        resolve(file);
                        return;
                    }
                    compressImage(file, 2400, 2400, 1, (compressedBlob) => {
                        const compressedFile = new File([compressedBlob], "compressed_" + file.name, {
                            type: compressedBlob.type,
                            lastModified: new Date().getTime()
                        });

                        // Calculate and log the new size in MB
                        resolve(compressedFile);
                    }, maxFileSize);
                });
            }
            logoImage = await processFile(logoImage);
            this.setFileForBgRemoval(logoImage);
            this.ui.isUploading = true;
            this.backgroundRemovedSuccessfully = false;

            let formData = new FormData();
            formData.append('logoImage', logoImage)

            axios.post('/api/user-profile/upload-logo', formData, {
                headers: {
                    'Accept': 'application/json'
                }
            }).then(response => {
                self.ui.isUploading = false;
                this.$emit('setActiveLogo', response.data);
            }).catch((error) => {
                self.uploading = false;
                self.ui.isUploading = false;
                if (error.response) {
                    self.errorMessage = error.response.data.message;
                    return;
                }
                console.log('error',error)
                self.errorMessage = "Invalid file type!\nPlease try again.";
            });
        },
        setFileForBgRemoval(file){
            this.file = null;
            this.backupFile = null;
            if (!isSvg(file.type)) {
                this.file = file;
                this.backupFile = file;
            }
        },
        handleBackgroundRemove() {
            try {
                this.triggerBackgroundRemoval(true)
                this.uploading = true;
                this.ui.isUploading = true;
                const file_name = this.file;
                this.processingBackground = true;
                this.backupFile = this.file;

                // imglyConfig()
                removeBackground(this.file, imglyConfig()).then((blob) => {
                    // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
                    // this.file = URL.createObjectURL(blob);
                    this.file = new File([blob], "removed_bg_" + file_name, {
                        type: blob.type,
                        lastModified: new Date().getTime()
                    });
                    let formData = new FormData();
                    formData.append('logoImage', this.file)

                    axios.post('/api/user-profile/upload-logo', formData, {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }).then(response => {
                        this.handleBackgroundFinished(true)
                        this.$emit('setActiveLogo', response.data);
                    }).catch((error) => {
                       this.handleBackgroundFinished(false);
                        if (error.response) {
                            this.errorMessage = error.response.data.message;
                            return;
                        }
                        this.errorMessage = "Invalid file type!\nPlease try again.";
                    });
                })
            }catch (e) {
                this.handleBackgroundFinished(false);
                alert(e)
            }
        },
        handleBackgroundFinished(backgroundRemovedSuccessfully){
            this.triggerBackgroundRemoval(false)
            this.backgroundRemovedSuccessfully = backgroundRemovedSuccessfully;
            this.processingBackground = false;
            this.uploading = false;
            this.ui.isUploading = false;
        },
        async handleUndoBackground() {
            this.backgroundRemovedSuccessfully = false;

            await this.processLogo(this.backupFile);
        }
    }
}
</script>

<style scoped>

</style>
