<template>
    <div class="input_numb_block">
        <input
            type="text"
            :id="id"
            v-model="numericInput"
            :min="min"
            :max="max"
            class="input_numb"
            @input="handleInput"
        >
        <label class="input_numb_label_name" :for="id">{{ computedPlaceholder }}:</label>
        <label class="input_numb_label_unit" :for="id" v-html="unit"></label>
        <div class="input_numb_button input_numb_button_minus" @click="decrementValue"></div>
        <div class="input_numb_button input_numb_button_plus" @click="incrementValue"></div>
    </div>
</template>

<script>
export default {
    name: "CustomInput",
    props: {
        id: {
            type: String,
            default: 'custom_input'
        },
        value: {
            type: Number,
            default: 100
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        increment: {
            type: Number,
            default: 5
        },
        unit: {
            type: String,
            default: '%'
        },
        inputPlaceholder: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            localValue: this.value
        };
    },
    watch: {
        value(newValue) {
            this.localValue = newValue;
        }
    },
    computed: {
        computedPlaceholder() {
            return this.inputPlaceholder || this.id;
        },
        numericInput: {
            get() {
                return this.localValue.toString();
            },
            set(val) {
                let numericValue = parseFloat(parseFloat(val).toFixed(2));
                if (isNaN(numericValue) || numericValue < this.min) {
                    numericValue = this.min;
                } else if (numericValue > this.max) {
                    numericValue = this.max;
                }

                this.localValue = numericValue;
                this.$emit('updateInput', this.localValue);
            }
        }
    },
    methods: {
        handleInput(event) {
            let numericValue = parseFloat(event.target.value);

            if (isNaN(numericValue)) {
                numericValue = this.min;
            } else if (numericValue < this.min) {
                numericValue = this.min;
            } else if (numericValue > this.max) {
                numericValue = this.max;
            }

            this.localValue = numericValue;
            this.$emit('updateInput', numericValue);
            event.target.value = numericValue; // Update the input field's displayed value
        },
        incrementValue() {
            const newValue = parseFloat((parseFloat(this.localValue) + this.increment).toFixed(2));
            if (newValue <= this.max) {
                this.localValue = newValue;
                this.$emit('updateInput', this.localValue);
            }
        },
        decrementValue() {
            const newValue = parseFloat((parseFloat(this.localValue) - this.increment).toFixed(2));
            if (newValue >= this.min) {
                this.localValue = newValue;
                this.$emit('updateInput', this.localValue);
            }
        }
    }
}
</script>

