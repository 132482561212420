<template>
    <div>
        <div class="editor_block"
             :class="{mandatory:ui.errorMessage}"
        >
            <label class="mylabel_1" for=""><span>{{ title }}</span></label>
            <!--FLORINXXX -> ADD .active WHEN UPLOADING-->
            <!--FLORINXXX -> ADD .error WHEN FILETYPE IS WRONG OR OTHER ERRORS ON UPLOADING-->
            <div class="my_upload_block"
                 :class="[ui.isUploading ? 'active' : '',ui.errorMessage ? 'error' : '']"
            >
                <div class="my_upload_content">
                    <div class="my_upload_content_inner">
                        <div class="my_upload_content_left">
                            <div class="my_upload_content_txt my_upload_content_normal">
                                Use high-resolution images<br/>
                                for best results.
                            </div>
                            <div class="my_upload_content_txt my_upload_content_error">
                                {{ ui.errorMessage }}
                            </div>
                        </div>
                        <div class="my_upload_content_right">
                            <div class="my_upload_content_button">Upload</div>
                        </div>
                        <input
                            type="file"
                            class="my_upload_content_input"
                            accept="image/*"
                            @change="uploadImage">
                    </div>
                    <div class="my_upload_content_inner_uploading">
                        <div class="my_upload_content_left">
                            <div class="my_upload_content_txt">
                                Keep calm!<br/>
                                We are processing your data.
                            </div>
                        </div>
                        <div class="my_upload_content_right">
                            <div class="lds-ring lds-ring5">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--FLORINXXX -> ADD .active WHEN UPLOADING-->
            <div style="height: 6px;" class="clear"></div>
            <div class="open_gallery_popup open_popup_1"
                 @click="toggleImageGallery"
            >
                <div>
                    Add from Gallery
                    <span class="material-icons">add_a_photo</span>
                </div>
            </div>
        </div>
        <!--Insert image gallery -->
        <image-gallery
            v-if="ui.showImageGallery"
            @toggleShowImageGallery="toggleImageGallery"
            @updateImage="updateImage"
        />
        <remove-background v-if="!isIOS()"
            :processing-background="processingBackground"
            :background-removed-successfully="backgroundRemovedSuccessfully"
            :file="file"
            @triggerBackgroundRemove="handleBackgroundRemove"
            @triggerUndoBackground="handleUndoBackground"
        />
    </div>
</template>

<script>
import ImageGallery from "../components/popups/ImageGallery.vue";
import {
    compressImage, downloadFromStorage,
    getFileSizeInMb,
    imglyConfig, isIOS,
    isSvg, processGalleryImage,
    validImageType
} from "../helpers/index.js";
import RemoveBackground from "../components/RemoveBackground/Index.vue";
// import imglyRemoveBackground from "@imgly/background-removal";
import { removeBackground } from '@imgly/background-removal';
export default {
    name: "UploadAndImageGallery",
    components: {
        RemoveBackground,
        ImageGallery
    },
    inject: ['triggerBackgroundRemoval'],
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Upload Background Image'
        },
        logoDeleted: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default:null
        },
    },
    watch: {
        logoDeleted(newVal, oldVal) {
            if (newVal) {
                this.file = null;
                this.backupFile = null;
                this.backgroundRemovedSuccessfully = false;

            }
        },
    },
    data: () => {
        return {
            files: null,
            file: null,
            backupFile: null,
            processingBackground: false,
            backgroundRemovedSuccessfully: false,
            ui: {
                showImageGallery: false,
                isUploading: false,
                errorMessage: null
            }
        };
    },
    created() {
        this.setBackgroundRemovalFiles(); // Reset file to null when component is created
    },
    methods: {
        isIOS,
       async setBackgroundRemovalFiles() {
           this.backgroundRemovedSuccessfully = false;
           try {

               if (!this.image || this.image.includes('removed_bg_')) {
                   this.file = null;
                   this.backupFile = null;
                   this.backgroundRemovedSuccessfully = false;
                   return
               }
               this.file = await downloadFromStorage(this.image);
               if (this.file) {
                   this.backupFile = this.file
               }
           }catch (e){
               this.file = null;
               this.backupFile = null;
           }
        },
        handleBackgroundRemove() {
            this.ui.errorMessage = null;
            this.togglePreloader();
            this.triggerBackgroundRemoval(true)
            const file_name = this.file.name;
            this.processingBackground = true;
            removeBackground(this.file, imglyConfig()).then((blob) => {
                // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
                this.file = new File([blob], "removed_bg_" + file_name, {
                    type: blob.type,
                    lastModified: new Date().getTime()
                });
                this.uploadBackgroundImage(this.file, true)

            })
        },
        async handleUndoBackground() {
            this.backgroundRemovedSuccessfully = false;
            this.togglePreloader();

            await this.uploadBackgroundImage(this.backupFile);
        },
        toggleImageGallery() {
            this.$emit('togglePopUp')
            this.ui.showImageGallery = !this.ui.showImageGallery;
        },
        togglePreloader() {
            this.ui.isUploading = !this.ui.isUploading;
        },
        async uploadImage(event) {
            this.ui.errorMessage = null;
            this.togglePreloader();
            this.files = event.target.files;
            const uploadedFile = this.files[0];
            this.setFileForBgRemoval(uploadedFile)
            if (!uploadedFile || !validImageType(uploadedFile.type)) {
                this.togglePreloader();
                this.ui.hasErrors = true;
                this.ui.errorMessage = "Invalid format. Accepted only: jpg, jpeg, png, svg, webp";
                return;
            }
            const processFile = file => {
                return new Promise((resolve) => {
                    const fileSizeMB = getFileSizeInMb(file);
                    const maxFileSize = typeof __SINGLE_MAX_FILE_SIZE_UPLOAD__ !== 'undefined' ? parseInt(__SINGLE_MAX_FILE_SIZE_UPLOAD__) : 20;
                    if (fileSizeMB < maxFileSize) {
                        resolve(file);
                        return;
                    }
                    compressImage(file, 2400, 2400, 1, (compressedBlob) => {
                        const compressedFile = new File([compressedBlob], "compressed_" + file.name, {
                            type: compressedBlob.type,
                            lastModified: new Date().getTime()
                        });

                        // Calculate and log the new size in MB
                        resolve(compressedFile);
                    }, maxFileSize);
                });
            }
            const file = await processFile(uploadedFile);
            event.target.value = '';
            this.uploadBackgroundImage(file)
        },
        setFileForBgRemoval(file) {
            this.file = null;
            this.backupFile = null;
            if (!isSvg(file.type)) {
                this.file = file;
                this.backupFile = file;
            }
        },
        uploadBackgroundImage(file, for_background = false) {
            this.backgroundRemovedSuccessfully = false;

            let formData = new FormData();
            formData.append('backgroundImage', file);
            formData.append('uniqueID', this.uniqueID);
            axios.post('/api/resources/upload-background',
                formData
            ).then((response) => {
                this.triggerBackgroundRemoval(false)

                this.togglePreloader();
                this.files = null;
                if (for_background) {
                    this.processingBackground = false;
                    this.backgroundRemovedSuccessfully = true;
                }
                this.$emit('updateImage', response.data)
            }).catch((error) => {
                //set error message
                this.triggerBackgroundRemoval(false)
                this.togglePreloader();
                this.ui.hasErrors = true;
                if (for_background) {
                    this.processingBackground = false;
                    this.backgroundRemovedSuccessfully = false;
                }
                if (error.response) {
                    this.ui.errorMessage = error.response.data.message;
                    console.log(error.response);
                    return;
                }
                this.ui.errorMessage = "Error: Invalid file type!\nPlease try again.";

                console.log('FAILURE!!');
            });
        },
        async updateImage(image) {


            this.file = await processGalleryImage(image)
            this.backupFile = this.file;
            this.backgroundRemovedSuccessfully = false;

            this.$emit('updateImage', image);
        }
    }
}
</script>

<style scoped>

</style>
