<script>
export default {
    name: "TextToVoice",
    emits: ['triggerVoiceUpdate'],
    props: {
        owner: {
            type: Object,
            default: () => ({})
        },
        animation: {
            type: Object,
            default: () => ({})
        },
        iframeAnimationTime: {
            type: Number,
            default: 15
        },
        animationStatus: {
            type: String,
            default: 'not-rendered',
        },
        aspectRatio: {
            type: String,
            default: 'square',
        },
    },
    data() {
        return {
            voiceOn: this.getCookie("voiceOn") ? this.getCookie("voiceOn") === 'true' : true,
            openSettings: false,
            voices: [
                {name: 'Alloy', url: 'https://cdn.openai.com/API/docs/audio/alloy.wav', sex: 'M'},
                {name: 'Echo', url: 'https://cdn.openai.com/API/docs/audio/echo.wav', sex: 'F'},
                {name: 'Fable', url: 'https://cdn.openai.com/API/docs/audio/fable.wav', sex: 'F'},
                {name: 'Onyx', url: 'https://cdn.openai.com/API/docs/audio/onyx.wav', sex: 'M'},
                {name: 'Nova', url: 'https://cdn.openai.com/API/docs/audio/nova.wav', sex: 'F'},
                {name: 'Shimmer', url: 'https://cdn.openai.com/API/docs/audio/shimmer.wav', sex: 'F'}
            ],
            selectedVoice: JSON.parse(this.getCookie("selectedVoice")) || {
                sex: 'M',
                name: 'Alloy',
                url: 'https://cdn.openai.com/API/docs/audio/alloy.wav'
            },
            selectVoiceDropDown: false,
            currentAudio: null,      // Tracks the audio instance currently playing
            playingVoice: null,      // Tracks the voice currently playing
            textToSpeech: {
                slidesNumber: 1,
                minNumber: 1
            },
            slideTexts: []
        };
    },
    created() {

        this.setupTextToSpeech();
        this.initializeSlideTexts();
        this.initializeVoiceDataTexts();
        if (this.voiceOn) {
            this.$emit('triggerVoiceUpdate', {
                voice: this.selectedVoice.name,
                slideTexts: this.slideTexts
            });
        } else {
            this.$emit('triggerVoiceUpdate', null);
        }
    },
    watch: {
        voiceOn(newValue) {
            this.setCookie("voiceOn", newValue, 365);
        },
        selectedVoice(newValue) {
            this.setCookie("selectedVoice", JSON.stringify(newValue), 365);
        }
    },
    computed: {
        maxInputSlideCharLength() {
            let animationTime = this.animationStatus === 'rendered'
                ? this.renderedWithWatermark
                    ? this.animation.time - 6
                    : this.animation.time
                : this.iframeAnimationTime;

            return Math.ceil((animationTime * 17) / this.textToSpeech.slidesNumber);
        },
        renderedWithWatermark() {
            const path = this.animation[`${this.aspectRatio}_path`];
            return path && !path.includes('storage.renderlion.com');
        },
    },
    methods: {
        setupTextToSpeech() {
            const typesWithText = ['text-to-video', 'images-to-video'];
            const slidesNumber = typesWithText.includes(this.animation.type)
                ? (this.animation.decoded_json?.data?.texts?.length || this.animation.decoded_json?.products?.length || 1)
                : 1;
            this.textToSpeech.slidesNumber = slidesNumber;
        },
        initializeSlideTexts() {
            const {text_to_speech: textToSpeechData} = this.animation;

            if (this.animation.type === 'shop-offer-to-video') {
                // Fill slideTexts with the `resume` text split across slides or as a single entry if needed
                if (this.animation.decoded_json.slideshowToSpeech) {
                    this.slideTexts = Array(this.textToSpeech.slidesNumber).fill(this.animation.decoded_json.slideshowToSpeech);
                } else {
                    // Fallback if resume is empty or undefined
                    this.slideTexts = Array(this.textToSpeech.slidesNumber).fill('');
                }
            }
            if (this.animation.decoded_json.slideshowToSpeech) {
                this.slideTexts[0] = this.animation.decoded_json.slideshowToSpeech;
            } else {
                // For other types, use `textToSpeechData` as before
                this.slideTexts = textToSpeechData && typeof textToSpeechData === 'object'
                    ? Object.values(textToSpeechData).slice(0, this.textToSpeech.slidesNumber)
                    : Array(this.textToSpeech.slidesNumber).fill('');
            }
        },
        initializeVoiceDataTexts() {
            const textToSpeechData = this.animation?.decoded_json?.voiceData?.slideTexts ?? [];
            if (Array.isArray(textToSpeechData) && textToSpeechData.length > 0) {
                this.slideTexts = Array.isArray(textToSpeechData) && textToSpeechData.length > 0
                    ? textToSpeechData.slice(0, this.textToSpeech.slidesNumber)
                    : [];
            }
        },
        toggleVoice() {
            this.voiceOn = !this.voiceOn;
            if (this.voiceOn) {
                this.$emit('triggerVoiceUpdate', {
                    voice: this.selectedVoice.name,
                    slideTexts: this.slideTexts
                });
            } else {
                this.$emit('triggerVoiceUpdate', null);
            }
        },
        toggleSettings() {
            this.openSettings = !this.openSettings;
        },
        toggleSelectVoiceDropdown() {
            this.selectVoiceDropDown = !this.selectVoiceDropDown;
            if (!this.selectVoiceDropDown) {
                this.stopAudio();
            }
        },
        handleClickOutsideOfDropdown(event) {
            const dropdown = this.$refs.huntOptionsList;
            if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.hunt_options_button')) {
                this.selectVoiceDropDown = false;
                this.stopAudio();
            }
        },
        selectVoice(voice) {
            this.selectedVoice = voice;
            this.selectVoiceDropDown = false;
            this.stopAudio();
        },
        playVoice(voice) {
            if (this.playingVoice === voice.name) {
                this.stopAudio();
            } else {
                this.startAudio(voice);
            }
        },
        startAudio(voice) {
            this.stopAudio();
            this.currentAudio = new Audio(voice.url);
            this.currentAudio.play();
            this.playingVoice = voice.name;
            this.currentAudio.onended = this.stopAudio;
        },
        stopAudio() {
            if (this.currentAudio) {
                this.currentAudio.pause();
                this.currentAudio.currentTime = 0;
            }
            this.playingVoice = null;
            this.currentAudio = null;
        },
        updateVoiceData() {
            this.openSettings = false;
            this.$emit('triggerVoiceUpdate', {
                voice: this.selectedVoice.name,
                slideTexts: this.slideTexts
            });
        },
        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        },
        deleteCookie(name) {
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutsideOfDropdown);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutsideOfDropdown);
    }
};
</script>


<template>
    <div class="hunt_voice_block">
        <div class="hunt_voice_content">
            <!--START SPEACH ON/OFF BUTTON
                - IF USER HAS CREDITS/RENDERS
                    - ADD .active if Text to Speech is ON
                    - onClick set Text to Speech ON/OFF
                - IF USER HAS NOT CREDITS/RENDERS
                    - ADD .inactive as DEFAULT
                    - onClick go to Pricing Page
            -->
            <div class="hunt_voice_onoff_button" title="Text to Speech" :class="{'active':voiceOn}"
                 @click="toggleVoice">
                <div class="hunt_voice_onoff_inner">
                    <span class="material-icons material-icons-mic_on">mic</span>
                    <span class="material-icons material-icons-mic_off">mic_off</span>
                    <!--UPDATE VOICE NAME-->
                    <strong>{{ selectedVoice.name }} <em>({{ selectedVoice.sex }})</em></strong>
                </div>
            </div>
            <!--END SPEACH ON/OFF BUTTON-->

            <!--START SPEACH SETTINGS BUTTONS - IF USER HAS CREDITS/RENDERS-->
            <div class="hunt_voice_settings_button" title="Text to Speech Settings"
                 @click="toggleSettings"
            >
                <span class="material-icons">settings</span> Settings
            </div>
            <!--END SPEACH SETTINGS BUTTONS-->

            <!--START SPEACH SETTINGS BLOCK - IF USER HAS CREDITS/RENDERS
                - ADD .active to open Popup
            -->
            <div class="hunt_voice_popup popup_block"
                 :class="{ 'active': openSettings }"
            >
                <div class="popup_container">
                    <!--UPDATE THE CLOSE POPUP BUTTON ACTION-->
                    <div class="popup_close_button"
                         title="Close"
                         @click="toggleSettings"
                    >
                        <div><span class="material-icons">close</span></div>
                    </div>
                    <div class="popup_content">
                        <h2>Text to Speech Settings</h2>

                        <div class="speech_block">

                            <!--START to CHOOSE VOICE-->
                            <div class="speech_voice">
                                <!--ADD .active TO OPEN DROPDOWN MENU-->
                                <div class="hunt_options_block hunt_sound_block"
                                     :class="{'active':selectVoiceDropDown}"
                                >
                                    <!--CHOOSE VOICE BUTTON-->
                                    <div class="hunt_options_button hunt_sound_button clr"
                                         @click="toggleSelectVoiceDropdown"
                                    >
                                        <dfn>Select Voice:</dfn>
                                        <strong>{{ selectedVoice.name }} <em>({{ selectedVoice.sex }})</em></strong>
                                        <span class="material-icons">arrow_drop_down</span>
                                    </div>

                                    <!--VOICE LIST-->
                                    <div class="hunt_options_list" ref="huntOptionsList">
                                        <div class="hunt_options_scroll">
                                            <ul class="header_menu_expand_list">
                                                <li class="hme_item" v-for="voice in voices"
                                                    @click.stop="selectVoice(voice)"
                                                    :class="{'active':selectedVoice.name === voice.name}">
                                                    <div class="hme_link"><strong>{{ voice.name }} <em>({{
                                                            voice.sex
                                                        }})</em></strong>
                                                    </div>
                                                    <!--PLAY THE VOICE -> ADD .active WHEN PLAYING-->
                                                    <div class="hme_play"
                                                         :class="{'active':playingVoice === voice.name}"
                                                         @click.stop="playVoice(voice)"
                                                    >
                                                        <span
                                                            class="material-icons material-icons_play">play_arrow</span>
                                                        <span class="material-icons material-icons_pause">pause</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--END to CHOOSE VOICE-->

                            <!--START to ADD/EDIT/UPDATE EACH SPEACH SLIDE CONTENT-->
                            <div class="speech_content">
                                <!--
                                    UPDATE SPEACH SLIDE ITEMS BY THE FOLLOWING RULES:
                                        1. Shop Offer to Video
                                              - SlideNumber: Number of Products [Min 1x]
                                              - SlideLenght: VideoLenghtSeconds * 15 / SlideNumber
                                          2. Images to Video
                                              - SlideNumber: 1x
                                              - SlideLenght: VideoLenghtSeconds * 15
                                          3. Text to Video
                                              - SlideNumber: Number of TextBlocks [Min 1x]
                                              - SlideLenght: VideoLenghtSeconds * 15 / SlideNumber
                                          4. Animated Logo
                                              - SlideNumber: 1x
                                              - SlideLenght: VideoLenghtSeconds * 15
                                          6. Animated Wallpaper
                                              - SlideNumber: 1x
                                              - SlideLenght: VideoLenghtSeconds * 15
                                          7. Video Intro
                                              - SlideNumber: 1x
                                              - SlideLenght: VideoLenghtSeconds * 15
                                          8. Video Outro
                                              - SlideNumber: 1x
                                              - SlideLenght: VideoLenghtSeconds * 15
                                      Apllied Rule:
                                          - 750-1000 characters per 60 second video
                                        - 900 characters per 60 seconds video [Choosed]
                                        - 15 characters (inluding spaces) per 1 second video [Choosed]
                                -->
                                <ul>
                                    <li v-for="(text, index) in textToSpeech.slidesNumber" :key="index">
                                        <label>Text to Speech - Slide {{ index + 1 }}</label>
                                        <textarea
                                            v-model="slideTexts[index]"
                                            minlength="0"
                                            :maxlength="textToSpeech.slideMaxLength"
                                            rows="3"
                                            class="speech_content_input"
                                            placeholder="Enter text for the slide"
                                        ></textarea>
                                        <div class="speech_content_slidelenght">
                                            <strong>{{ slideTexts[index]?.length || 0 }}</strong> /
                                            <span>{{ maxInputSlideCharLength }}</span>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!--END to ADD/EDIT/UPDATE EACH SPEACH SLIDE CONTENT-->

                        </div>
                    </div>
                    <div class="popup_footer">
                        <div class="popup_footer_block">
                            <!--UPDATE TEXT TO SPEACH POPUP BUTTON-->
                            <div class="popup_button_update" @click="updateVoiceData">
                                <div style="padding-right: 12px;"><strong>Update</strong><span class="material-icons"
                                                                                               style="top: 14px; right: -10px;">chevron_right</span>
                                </div>
                            </div>

                            <!--CANCEL TEXT TO SPEACH POPUP BUTTON-->
                            <div class="popup_button_cancel" @click="toggleSettings">
                                <div style="padding-left: 16px;"> Cancel <span class="material-icons"
                                                                               style="top: 14px; left: -10px;">close</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--END SPEACH SETTINGS BLOCK - IF USER HAS CREDITS/RENDERS-->
        </div>
    </div>
</template>

<style scoped>

</style>
