<template>
    <div class="declare_iframes">
        <p>
            <a :href="scene.url"> {{ scene.name }}</a>
            <em>[{{ scene.type }}] [{{ scene.folder }}]</em>
        </p>
        <div class="declare_iframes_inputs clr">
            <ul>
                <li>
                    <label :for="scene.folder+'-folder'">Folder:</label>
                    <input :id="scene.folder+'-folder'"
                           v-model="scene.folder"
                           :name="scene.folder+'-folder'"
                           class="input_type2"
                           type="text"
                           @input="updateFolder"
                    >
                </li>
                <li>
                    <label :for="scene.folder+'-startID'">startID:</label>
                    <input
                        :id="scene.folder+'-startID'"
                        v-model="scene.startID"
                        :name="scene.folder+'-startID'"
                        class="input_type2"
                        type="number"
                        @input="updateStartID"
                    >
                </li>
                <li>
                    <label :for="scene.folder+'-endID'">endID:</label>
                    <input
                        :id="scene.folder+'-endID'"
                        v-model="scene.endID"
                        :name="scene.folder+'-endID'"
                        class="input_type2"
                        type="number"
                        @input="updateEndID"
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomSceneInput",
    props: {
        scene: {
            type: Object,
            default: () => ({})
        },
        index: {
            type: Number,
            default: 1
        }
    },
    methods: {
        updateFolder() {
            this.$emit('update:folder', this.scene.folder, this.index)
        },
        updateStartID() {
            this.$emit('update:startID', this.scene.startID, this.index)
        },
        updateEndID() {
            this.$emit('update:endID', this.scene.endID, this.index)
        }
    }
}
</script>

<style scoped>

</style>
