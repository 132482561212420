<template>
    <div>
        <background-header
            :showBgHeader="showBgHeader"
            :backgroundType="backgroundType"
            @changeBgType="changeBgType"
        />
        <static-background
            v-if="data.type==='static'"
            :image="data.image"
            :opacity="data.opacity"
            :backgroundColor="backgroundColor"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateOpacity="updateOpacity"
            @updateImage="updateImage"
        />

        <animated-background
            v-if="data.type==='animated'"
            :image="data.image"
            :opacity="data.opacity"
            :length="data.length"
            :backgroundColor="backgroundColor"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateImage="updateImage"
            @updateOpacity="updateOpacity"
            @updateBgLength="updateBgLength"
        />
    </div>
</template>

<script>
import BackgroundHeader from './Header.vue'
import StaticBackground from './Static.vue'
import AnimatedBackground from "./Animated.vue";

export default {
    name: "Index",
    components: {
        BackgroundHeader,
        StaticBackground,
        AnimatedBackground
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        data: {
            type: Object,
            default: () => {
                return {
                    type: 'static',
                    opacity: 75,
                    length: 30,
                    image: null
                }
            }
        },
        backgroundColor: {
            type: String,
            default: null
        },
        showBgHeader: {
            type: Boolean,
            default: true
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
        currentScene: {
            type: String,
            default: 'Background Anime'
        }
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName(this.currentScene));
    },
    computed:{
        backgroundType(){
            if(this.currentScene === 'Background Anime'){
                return 'animated';
            }
            return 'static';
        }
    },
    methods: {
        togglePopUp() {
            this.$emit('togglePopUp')
        },
        changeBgType(type) {
            this.$emit('changeBgType', type);
        },
        updateImage(imagePath) {
            this.$emit('updateImageBg', imagePath)
        },
        updateOpacity(value) {
            this.$emit('updateBgOpacity', value)
        },
        updateBgLength(value) {
            this.$emit('updateBgLength', value)
        },
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
    }
}
</script>

<style scoped>

</style>
