<template>
    <!--START FOREACH TITLE-BLOCK -> PRODUCTS / TEXT / ETC-->
    <div class="editor_foreach_block"
         :style="{ 'padding-top': paddingTop + 'px' }"
    >
        <ol class="editor_foreach_list">
            <!--START FOREACH ITEM #1-->
            <li class="editor_foreach_item" v-for="(dataText,index) in texts" :key="index">
                <div class="editor_foreach_header">
                    <div class="editor_foreach_title">
                        <span><strong>Text Block <em>#</em>{{ index + 1 }}</strong></span>
                    </div>
                    <div class="editor_foreach_actions clr">
                        <!--Move Down-->
                        <div
                            title="Move Down"
                            class="efa_action efa_movedown"
                            @click="moveDownTextBlock(index)"
                        >
                            <div>
                                <span class="material-icons">keyboard_arrow_down</span>
                            </div>
                        </div>

                        <!--Move Up-->
                        <div
                            title="Move Up"
                            class="efa_action efa_moveup"
                            @click="moveUpTextBlock(index)"
                        >
                            <div>
                                <span class="material-icons">keyboard_arrow_up</span>
                            </div>
                        </div>

                        <!--Duplicate-->
                        <div
                            title="Duplicate"
                            class="efa_action efa_duplicate"
                            @click="duplicateTextBlock(index)"
                        >
                            <div>
                                <span class="material-icons">queue</span>
                            </div>
                        </div>

                        <!--Delete-->
                        <div
                            title="Delete"
                            class="efa_action efa_delete"
                            @click="deleteTextBlock(index)"
                        >
                            <div>
                                <span class="material-icons">delete_forever</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--FLORINXXX -> ADD .active ACCORDINGLY -> BASED ON IT THE TEXT INPUTS WILL DISPLAY-->
                <choose-text-field
                    :index="index"
                    :showContentSuggestion="showContentSuggestion"
                    :activeTextType="dataText.activeTextType"
                    :type="'data'"
                    :headline="dataText.headline"
                    :text="dataText.text"
                    :contentSuggestion="contentSuggestion"
                    :indexForContentSuggestion="indexForContentSuggestion"
                    @toggleShowContentSuggestion="toggleShowContentSuggestion"
                    @updateTheContentSuggestion="updateTheContentSuggestion"
                    @setActiveTextType="setActiveTextType"
                    @headlineChanged="headlineChanged"
                    @textChanged="textChanged"
                    @moveUpTextBlock="moveUpTextBlock"
                    @moveDownTextBlock="moveDownTextBlock"
                />
                <!--END HOW TO ADD MULTIPLE TYPES OF TEXT ELEMENTS-->
            </li>
            <!--END FOREACH ITEM #1-->

            <!--START FOREACH NEW ITEM #+++++++++-->
            <li class="editor_foreach_item" style="padding-top: 54px;">
                <div class="editor_foreach_header">
                    <div class="editor_foreach_title">
                        <span><strong>New Text Block?</strong></span>
                    </div>
                </div>
                <div class="editor_block" style="padding-top: 0px;">
                    <div
                        class="open_popup_1"
                        @click="addTextBlock"
                    >
                        <div>Add Text Block <span class="material-icons">add_box</span></div>
                    </div>
                </div>
            </li>
            <!--END FOREACH NEW ITEM #+++++++++-->
        </ol>

    </div>
    <!--END FOREACH TITLE-BLOCK -> PRODUCTS / TEXT / ETC-->
</template>

<script>
import ChooseTextField from "./ChooseTextField.vue";
import {v4 as uuidv4} from 'uuid';

export default {
    name: "TextList",
    components: {ChooseTextField},
    props: {
        texts: {
            type: Array,
            default: () => ([])
        },
        contentSuggestion: {
            type: Array,
            default: () => ([])
        },
        paddingTop: {
            type: Number,
            default: 0
        }
    },
    data: () => {
        return {
            showContentSuggestion: false,
            indexForContentSuggestion: null,
            selectedIndex: null
        };
    },

    methods: {
        toggleShowContentSuggestion(index) {
            this.$emit('togglePopUp')
            this.showContentSuggestion = !this.showContentSuggestion;
            if (this.showContentSuggestion) {
                this.indexForContentSuggestion = index;
            } else {
                this.indexForContentSuggestion = null;
            }
        },
        addTextBlock() {
            this.$emit('addTextBlock', {
                activeTextType: 3,
                headline: '<p>Your Title Here</p>',
                text: '<p>Write Your Text Here</p>',

            })
        },
        deleteTextBlock(index) {
            this.$emit('deleteTextBlock', index)
        },
        duplicateTextBlock(index) {
            this.$emit('addTextBlock', this.texts[index])
        },
        moveUpTextBlock(index) {
            this.$emit('moveUpTextBlock', index)
        },
        moveDownTextBlock(index) {
            this.$emit('moveDownTextBlock', index)
        },
        setActiveTextType(value, index) {
            this.$emit('setActiveTextType', value, index)
        },
        headlineChanged(value, index) {
            this.$emit('headlineChanged', value, index)
        },
        textChanged(value, index) {
            this.$emit('textChanged', value, index)
        },
        updateTheContentSuggestion(data, index) {
            this.$emit('updateTheContentSuggestion', data, index)
        },
    }
}
</script>

<style scoped>

</style>
