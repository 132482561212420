<template>
    <div class=”remove_img_bg_container” @click="removeBackground"
         v-if="!processingBackground && !backgroundRemovedSuccessfully && file">
        <div style="height: 6px;" class="clear"></div>
        <div class="remove_img_bg_button  open_popup_1">
            <div> Remove Image Bg<span class="material-icons">texture</span></div>
        </div>
    </div>
    <div class=”remove_img_bg_container” @click="undoBackground"
         v-if="!processingBackground && backgroundRemovedSuccessfully">
        <div style="height: 6px;" class="clear"></div>
        <div class="undo_remove_img_bg_button open_popup_1">
            <div> Undo <span class="material-icons">keyboard_backspace</span></div>
        </div>
    </div>
    <div class=”remove_img_bg_container” v-if="processingBackground">
        <div style="height: 6px;" class="clear"></div>
        <div class="remove_img_bg_pending  open_popup_1">
            <div>Removing Bg...</div>
        </div>
    </div>
</template>
<script>

export default {
    name: "RemoveBackground",
    emits: ["triggerBackgroundRemove", 'triggerUndoBackground'],
    props: {
        processingBackground: {
            type: Boolean,
            default: false
        },
        backgroundRemovedSuccessfully: {
            type: Boolean,
            default: false
        },
        file: {
            type: Object,
            default: {}
        },
    },
    methods: {
        removeBackground() {
            this.$emit('triggerBackgroundRemove');

        },
        undoBackground() {
            this.$emit('triggerUndoBackground');

        }
    }
}
</script>
