<template>
    <!--START EDITOR BOTTOM SIDE-->
    <div class="editor_bottomside clr">
        <div class="editor_bottomside_inner">


            <div class="editor_steps_block">
                <div class="editor_steps_title">{{ activeStepTitle }}</div>
                <div class="editor_steps_list">
                    <ol>
                        <li v-for="(step,index) in steps"
                            :key="index"
                            :class="[{ 'active': index === activeStep }, step.additionalClasses]"
                        >
                            <div v-if="step.html" v-html="step.html"></div>
                        </li>
                    </ol>
                </div>
            </div>

            <!--START NEXT / BACK / SKIP BUTTONS-->
            <div class="editor_next_block">
                <!--FLORINXXXX -> [BACK] [SKIP] [NEXT]-->
                <ul class="editor_nb_list clr">

                    <!--[SKIP]-->
                    <!--FLORINXXXX -> .none IN THE MOST STEPS / AVAILABLE ONLY FOR SOME STEPS-->
                    <li class="editor_nb_skip_item"
                        v-if="steps[activeStep] && steps[activeStep].skippable"
                    >
                        <!--FLORINXXXX -> ON CLICK .editor_nb_button_skip -> GO SKIP-->
                        <!--FLORINXXXX -> ADD/REMOVE .disable ACCORDING-->
                        <div class="editor_nb_button editor_nb_button_skip"
                            @click="$emit('skipStep')"
                        >
                            <div>
                                <em>Skip</em>
                                <span class="material-icons skip_icon_1">navigate_next</span>
                                <span class="material-icons skip_icon_2">navigate_next</span>
                            </div>
                        </div>
                    </li>

                    <!--[NEXT]-->
                    <!--FLORINXXXX -> ALWAYS AVAILABLE-->
                    <li class="editor_nb_next_item">
                        <!--FLORINXXXX -> ON CLICK .editor_nb_button_next -> GO NEXT-->
                        <!--FLORINXXXX -> ADD/REMOVE .disable ACCORDING-->
                        <div class="editor_nb_button editor_nb_button_next"
                             v-if="hasNextStep"
                             @click="goToTheNextStep"
                             :class="{disable: ((steps.length !== activeStep+1 && !canGoToNextStep) || this.loading)}"
                        >
                            <div>
                                <em v-if="steps.length === activeStep+1">Finish</em>
                                <em v-else>Next</em>
                                <span class="material-icons">navigate_next</span>
                            </div>
                        </div>
                    </li>

                    <!--[BACK]-->
                    <!--FLORINXXXX -> .none FOR FIRST STEP [MY PROFILE]-->
                    <li class="editor_nb_prev_item"
                        v-if="activeStep > 0"
                    >
                        <!--FLORINXXXX -> ON CLICK .popup_button_cancel -> CANCEL-->
                        <div class="editor_nb_button editor_nb_button_back"
                            @click="goToPreviousStep"
                        >
                            <div>
                                <span class="material-icons">navigate_before</span>
                                <em>Back</em>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!--END NEXT / PREV / SKIP BUTTONS-->
        </div>
    </div>
    <!--END EDITOR BOTTOM SIDE-->
</template>

<script>
export default {
    name: "Footer",
    props: {
        activeStepTitle: {
            type: String,
            default: 'Steps title not defined'
        },
        loading:{
            type:Boolean,
            default: true
        },
        steps: {
            type: Array,
            default() {
                return [];
            }
        },
        activeStep: {
            type: Number,
            default: 0
        },
        canGoToNextStep: {
            type: Boolean,
            default: true
        }
    },
    data: () => {
        return {
            finishButtonCLicked: false
        }
    },
    computed: {
        hasNextStep() {
            if (this.steps.length >= this.activeStep + 1) {
                return true;
            }
            return false;
        }
    },
    methods: {
        goToTheNextStep() {
            if(this.loading){
                return ;
            }
            if(!this.canGoToNextStep){
                return;
            }
            if (this.steps.length > this.activeStep + 1) {
                this.$emit('goToNextStep');
            } else if (!this.finishButtonCLicked) {
                this.$emit('allStepsAreFinished');
                this.finishButtonCLicked = true;
            }
        },
        goToPreviousStep(){
            this.$emit('goToPreviousStep');
        }
    }
}
</script>

<style scoped>

</style>
