<template>
    <!--START SPEED & TIMING -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div class="video_settings_button"
             @click="$emit('setActiveChild',$options.name)"
        >
            <strong>Speed &amp; Timing</strong> <span class="material-icons">arrow_drop_down</span>
        </div>
        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="editor_block editor_block_logo">
                <div class="editor_logo_container_left editor_speed_timing_display">
                    <div class="editor_logo_display">
                        <mini-video
                            :data="data"
                        />
                    </div>
                </div>
                <div class="editor_logo_container_right">

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 0;">
                        <label class="mylabel_1" for="videoSpeed"><span>Video Speed</span></label>
                        <div class="input_numb_block">
                            <custom-input
                                :id="'Speed'"
                                :increment="0.1"
                                :max="4"
                                :min="0.2"
                                :unit="'×'"
                                :value="data.speed_and_timing.speed"
                                @updateInput="updateVideoSpeed"
                            />
                        </div>
                    </div>

                    <div class="editor_block" style="padding-top: 18px;">
                        <label class="mylabel_1" for=""><span>Timing/Acceleration</span></label>
                        <ul class="video_timing_list clr">
                            <li v-for="(value,index) in video_timing" :key="index">
                                <div :class="{active: (data.speed_and_timing.timing === value)}"
                                     class="video_timing_button"
                                     @click="setActiveTiming(value)"
                                >
                                    {{ value }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD-->
        </div>
    </li>
    <!--END SPEED & TIMING -> VIDEO SETTINGS-->
</template>

<script>
import CustomInput from "../../common/CustomInput.vue";
import MiniVideo from "./common/MiniVideo.vue";

export default {
    name: "SpeedAndTiming",
    components: {
        CustomInput,
        MiniVideo
    },
    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        video_timing: {
            type: Array,
            default: () => {
                return [
                    'Default', 'Quad', 'Cubic', 'Quart', 'Quint', 'Sine', 'Expo', 'Circ', 'Back'
                ]
            }
        }
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        }
    },
    methods: {
        updateVideoSpeed(value) {
            this.$emit('updateVideoSpeed', Number(value.toFixed(1)));
        },
        setActiveTiming(value) {
            this.$emit('updateVideoTiming', value);
        }
    }
}
</script>

<style scoped>

</style>
