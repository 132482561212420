<template>
    <!--START SCALE -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div class="video_settings_button"
             @click="$emit('setActiveChild',$options.name)"
        >
            <strong>Scale</strong> <span class="material-icons">arrow_drop_down</span>
        </div>
        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="editor_block editor_block_logo">
                <div class="editor_logo_container_left editor_scale_display">
                    <div class="editor_logo_display">
                        <mini-video
                            :data="data"
                        />
                    </div>
                </div>
                <div class="editor_logo_container_right">

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="scaleX"><span>Horizontal Scaling</span></label>
                        <div class="input_numb_block">
                            <custom-input
                                :id="'ScaleX'"
                                :increment="5"
                                :max="160"
                                :min="20"
                                :unit="'%'"
                                :value="data.scale.x"
                                @updateInput="updateScaleX"
                            />
                        </div>
                    </div>

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="scaleY"><span>Vertical Scaling</span></label>
                        <div class="input_numb_block">
                            <custom-input
                                :id="'ScaleY'"
                                :increment="5"
                                :max="160"
                                :min="20"
                                :unit="'%'"
                                :value="data.scale.y"
                                @updateInput="updateScaleY"
                            />
                        </div>
                    </div>

                </div>
            </div>
            <!--ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD-->
        </div>
    </li>
    <!--END SCALE -> VIDEO SETTINGS-->
</template>

<script>
import CustomInput from "../../common/CustomInput.vue";
import MiniVideo from "./common/MiniVideo.vue";

export default {
    name: "Scale",
    components: {
        CustomInput,
        MiniVideo
    },
    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data: () => {
        return {
            scale: {
                x: 100,
                y: 100
            }
        }
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        }
    },
    methods: {
        updateScaleX(value) {
            this.$emit('updateVideoScaleX', value);
        },
        updateScaleY(value) {
            this.$emit('updateVideoScaleY', value);
        }
    }
}
</script>

<style scoped>

</style>
