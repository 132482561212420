<template>
    <div>
        <!--START CALL TO ACTION V1-->
        <!--FLORINXXX -> ADD .active ACCORDINGLY -> BASED ON IT THE TEXT INPUTS WILL DISPLAY-->
        <div class="editor_block">
            <label class="mylabel_1" for=""><span>Call to Action Field</span></label>
            <!--FLORINXXX -> ADD .active ACCORDINGLY-->
            <div class="open_palette_popup open_popup_1"
                 :class="{active:callToActionButton}"
                 @click="$emit('toggleCallToActionButton',index)">
                <div>
                    Call to Action Button
                    <span class="material-icons">touch_app</span>
                </div>
            </div>
            <div class="show_brand_data">
                <ul>
                    <!--FLORINXXX -> ADD .active ACCORDINGLY-->
                    <li class="show_brand_name" :class="{active:callToActionButton}">
                        <div class="textarea_block textarea_block_singleline txa5 txa6 txa9">
                            <trumbowyg
                                :modelValue="callToActionText"
                                :config="textareaConfig"
                                ref="callToActionTextarea"
                                class="rl_textarea_input"
                                @tbw-change="$emit('textChanged',$event,index)"
                                name="content">
                            </trumbowyg>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="text_inspiration_block" v-if="callToActionButton">
                <div class="button open_text_content_popup clr" @click="toggleShowContentSuggestion">
                    <strong>Content Suggestion</strong>
                </div>
            </div>
        </div>
        <!--END CALL TO ACTION V1-->
        <content-suggestion
            v-if="showContentSuggestion && index === indexForContentSuggestion"
            :contentSuggestion="contentSuggestion"
            :index="index"
            @toggleShowContentSuggestion="toggleShowContentSuggestion(index)"
            @updateTheContentSuggestion="updateTheContentSuggestion"
        />
    </div>
</template>

<script>
import ContentSuggestion from "../components/popups/ContentSuggestion.vue";
// Import this component
import 'trumbowyg/dist/ui/trumbowyg.css';

export default {
    name: "CallToActionButton",
    components: {
        ContentSuggestion
    },
    props: {
        index: {
            type: Number,
            default: null
        },
        indexForContentSuggestion: {
            type: Number,
            default: null
        },
        showContentSuggestion: {
            type: Boolean,
            default: false
        },
        contentSuggestion: {
            type: Array,
            default: () => ([])
        },
        textareaConfig: {
            type: Object,
            default: () => ({
                autogrow: true,
                removeformatPasted: true,
                // Adding color plugin button
                tagsToRemove: ['script', 'link'],
                btns: ['strong', 'em', 'del', 'underline', 'superscript', 'subscript', 'foreColor', 'backColor', '|', 'viewHTML'],
                btnsAdd: ['foreColor', 'backColor'],
            })
        },
        callToActionButton: {
            type: Boolean,
            default: false
        },
        callToActionText: {
            type: String,
            default: '<p>Buy Now!</p>'
        }
    },
    mounted() {
        this.setupTextLimit(); // Call the setupTextLimit method on mount
    },
    methods: {
        toggleShowContentSuggestion() {
            if (!this.callToActionButton) {
                return;
            }
            if (this.index !== null) {
                this.$emit('toggleShowContentSuggestion', this.index)
            } else {
                this.$emit('toggleShowContentSuggestion')
            }
        },
        updateTheContentSuggestion(selectedContentSuggestion) {
            if (this.index !== null) {
                this.$emit('updateTheContentSuggestion', selectedContentSuggestion, this.index)
            } else {
                this.$emit('updateTheContentSuggestion', selectedContentSuggestion)
            }
        },
        setupTextLimit() {
            // Set up text limit for headline and text editors
            const callToActionEditor = $(this.$refs.callToActionTextarea.$el);

            const maxLength = 40; // Set maximum character length


            const limitContent = (editor) => {
                const text = editor.trumbowyg('html');
                const strippedText = $('<div>').html(text).text(); // Strip HTML tags
                if (strippedText.length > maxLength) {
                    const truncatedText = strippedText.slice(0, maxLength); // Truncate to maxLength
                    editor.trumbowyg('html', truncatedText);
                }
            };

            callToActionEditor.on('tbwchange', () => {
                limitContent(callToActionEditor);
            });


            // Handle pasting content in the editor
            [callToActionEditor].forEach(editor => {
                editor.on('paste', () => {
                    setTimeout(() => {
                        limitContent(editor);
                    }, 0);
                });
            });
        }
    }
}
</script>

<style scoped>

</style>
