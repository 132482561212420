<template>
    <div>
        <!--FLORINXXX -> ADD .active ACCORDINGLY -> BASED ON IT THE TEXT INPUTS WILL DISPLAY-->
        <div class="editor_block" style="padding-top: 12px;">
            <label class="mylabel_1" for=""><span>Choose Text Fields</span></label>
            <div class="addtext_type">
                <ul class="addtext_list">
                    <!--TYPE1 -> HEADLINE + TEXT-->
                    <li class="addtext_item addtext_item_type1">
                        <div class="addtext_button"
                             :class="{active:activeTextType===1}"
                             @click="setActiveTextType(1)"
                        >
                            <div class="addtext_btype"><strong>T</strong><span>t</span></div>
                            <div class="addtext_bdisplay"><strong>Headline</strong><span>RegularText</span></div>
                        </div>
                    </li>
                    <!--TYPE2 -> TEXT + HEADLINE-->
                    <li class="addtext_item addtext_item_type2">
                        <div class="addtext_button"
                             :class="{active:activeTextType===2}"
                             @click="setActiveTextType(2)"
                        >
                            <div class="addtext_btype"><span>t</span><strong>T</strong></div>
                            <div class="addtext_bdisplay"><span>RegularText</span><strong>Headline</strong></div>
                        </div>
                    </li>
                    <!--TYPE3 -> HEADLINE-->
                    <li class="addtext_item addtext_item_type3">
                        <div class="addtext_button"
                             :class="{active:activeTextType===3}"
                             @click="setActiveTextType(3)"
                        >
                            <div class="addtext_btype" style="top: 24px;"><strong>T</strong></div>
                            <div class="addtext_bdisplay" style="top: 44px;"><strong>Headline</strong></div>
                        </div>
                    </li>
                    <!--TYPE4 -> TEXT-->
                    <li class="addtext_item addtext_item_type4">
                        <div class="addtext_button"
                             :class="{active:activeTextType===4}"
                             @click="setActiveTextType(4)"
                        >
                            <div class="addtext_btype" style="top: 24px;"><span>t</span></div>
                            <div class="addtext_bdisplay" style="top: 46px;"><span>RegularText</span></div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="text_inspiration_block">
                <div class="button open_text_content_popup clr"
                     @click="toggleShowContentSuggestion"
                >
                    <strong>Content Suggestion</strong>
                </div>
            </div>

            <div class="addtext_show_block">
                <ul class="addtext_show_list">
                    <!--TYPE1 -> HEADLINE + TEXT-->
                    <li class="addtext_show_item addtext_show_item_type1"
                        :class="{active:activeTextType===1}"
                    >
                        <div class="addtext_show_item_block">
                            <label class="mylabel_1" for=""><span>{{
                                    capitalizeFirstLetter(type)
                                }} Headline {{ index ? index + 1 : 1 }}</span></label>
                            <div class="textarea_block textarea_block_twoline textarea_block_headline txa5 txa6 txa9">
                                <trumbowyg
                                    ref="headlineEditor"
                                    :modelValue="headline"
                                    :config="textareaConfig"
                                    class="rl_textarea_input"
                                    @tbw-change="headlineChanged($event)"
                                    name="content">
                                </trumbowyg>
                            </div>

                        </div>
                        <div class="addtext_show_item_block">
                            <label class="mylabel_1" for="multiline_textarea"><span>{{ capitalizeFirstLetter(type) }} Text {{
                                    index ? index + 1 : 1
                                }}</span></label>
                            <div class="textarea_block textarea_block_multiline txa9">
                                <trumbowyg
                                    ref="textEditor"
                                    :modelValue="text"
                                    :config="textareaConfig"
                                    class="rl_textarea_input"
                                    @tbw-change="textChanged($event)"
                                    name="content">
                                </trumbowyg>
                            </div>

                        </div>
                    </li>

                    <!--TYPE2 -> TEXT + HEADLINE-->
                    <li class="addtext_show_item addtext_show_item_type2"
                        :class="{active:activeTextType===2}"
                    >
                        <div class="addtext_show_item_block">
                            <label class="mylabel_1" for="multiline_textarea"><span>{{ capitalizeFirstLetter(type) }} Text {{
                                    index ? index + 1 : 1
                                }}</span></label>
                            <div class="textarea_block textarea_block_multiline txa9">
                                <trumbowyg
                                    ref="textEditor"
                                    :modelValue="text"
                                    :config="textareaConfig"
                                    class="rl_textarea_input"
                                    @tbw-change="textChanged($event)"
                                    name="content">
                                </trumbowyg>
                            </div>

                        </div>
                        <div class="addtext_show_item_block">
                            <label class="mylabel_1" for=""><span>{{
                                    capitalizeFirstLetter(type)
                                }} Headline {{ index ? index + 1 : 1 }}</span></label>
                            <div class="textarea_block textarea_block_twoline textarea_block_headline txa5 txa6 txa9">
                                <trumbowyg
                                    ref="headlineEditor"
                                    :modelValue="headline"
                                    :config="textareaConfig"
                                    class="rl_textarea_input"
                                    @tbw-change="headlineChanged($event)"
                                    name="content">
                                </trumbowyg>
                            </div>
                            <div class="text-end">

                                <!-- Text length counter -->
                            </div>
                        </div>
                    </li>

                    <!--TYPE3 -> HEADLINE-->
                    <li class="addtext_show_item addtext_show_item_type3"
                        :class="{active:activeTextType===3}"
                    >
                        <div class="addtext_show_item_block">
                            <label class="mylabel_1" for=""><span>{{
                                    capitalizeFirstLetter(type)
                                }} Headline {{ index ? index + 1 : 1 }}</span></label>
                            <div class="textarea_block textarea_block_twoline textarea_block_headline txa5 txa6 txa9">
                                <trumbowyg
                                    ref="headlineEditor"
                                    :modelValue="headline"
                                    :config="textareaConfig"
                                    class="rl_textarea_input"
                                    @tbw-change="headlineChanged($event)"
                                    name="content">
                                </trumbowyg>
                            </div>
                            <div class="text-end">

                                <!-- Text length counter -->
                            </div>
                        </div>
                    </li>

                    <!--TYPE4 -> TEXT-->
                    <li class="addtext_show_item addtext_show_item_type4"
                        :class="{active:activeTextType===4}"
                    >
                        <div class="addtext_show_item_block">
                            <label class="mylabel_1" for="multiline_textarea"><span>{{ capitalizeFirstLetter(type) }} Text {{
                                    index ? index + 1 : 1
                                }}</span></label>
                            <div class="textarea_block textarea_block_multiline txa9">
                                <trumbowyg
                                    ref="textEditor"
                                    :modelValue="text"
                                    :config="textareaConfig"
                                    class="rl_textarea_input"
                                    @tbw-change="textChanged($event)"
                                    name="content">
                                </trumbowyg>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <!--END HOW TO ADD MULTIPLE TYPES OF TEXT ELEMENTS-->
        <content-suggestion
            v-if="showContentSuggestion && index === indexForContentSuggestion"
            :contentSuggestion="contentSuggestion"
            :index="index"
            @toggleShowContentSuggestion="toggleShowContentSuggestion(index)"
            @updateTheContentSuggestion="updateTheContentSuggestion"
        />
    </div>
</template>

<script>
import ContentSuggestion from "../components/popups/ContentSuggestion.vue";
import 'trumbowyg/dist/ui/trumbowyg.css';
import $ from 'jquery';

export default {
    name: "ChooseTextField",
    components: {
        ContentSuggestion
    },
    props: {
        index: {
            type: Number,
            default: null
        },
        showContentSuggestion: {
            type: Boolean,
            default: false
        },
        textareaConfig: {
            type: Object,
            default: () => ({
                autogrow: true,
                removeformatPasted: true,
                tagsToRemove: ['script', 'link'],
                btns: ['strong', 'em', 'del', 'underline', 'superscript', 'subscript', 'foreColor', 'backColor', '|', 'viewHTML'],
                btnsAdd: ['foreColor', 'backColor'],
            })
        },
        activeTextType: {
            type: Number,
            default: 1,
        },
        type: {
            type: String,
            default: 'outro'
        },
        headline: {
            type: String,
            default: '<p>Headline</p>',
        },
        text: {
            type: String,
            default: '<p>Text</p>'
        },
        contentSuggestion: {
            type: Array,
            default: () => ([])
        },
        indexForContentSuggestion: {
            type: Number,
            default: null
        }
    },
    mounted() {
        this.setupTextLimit(); // Call the setupTextLimit method on mount
    },
    methods: {
        toggleShowContentSuggestion() {
            this.$emit('toggleShowContentSuggestion', this.index);
        },
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        updateTheContentSuggestion(selectedContentSuggestion, index = null) {
            if (this.index !== null) {
                this.$emit('updateTheContentSuggestion', selectedContentSuggestion, index)
            } else {
                this.$emit('updateTheContentSuggestion', selectedContentSuggestion)
            }
        },
        setActiveTextType(value) {
            if (this.index !== null) {
                this.$emit('setActiveTextType', value, this.index)
            } else {
                this.$emit('setActiveTextType', value)
            }
        },
        textChanged(event) {
            if (this.index !== null) {
                this.$emit('textChanged', event, this.index)
            } else {
                this.$emit('textChanged', event)
            }
        },
        headlineChanged(event) {
            if (this.index !== null) {
                this.$emit('headlineChanged', event, this.index)
            } else {
                this.$emit('headlineChanged', event)
            }
        },
        setupTextLimit() {
            // Set up text limit for headline and text editors
            const headlineEditor = $(this.$refs.headlineEditor.$el);
            const textEditor = $(this.$refs.textEditor.$el);
            const headlineLengthCounter = $(this.$refs.headlineLengthCounter);
            const textLengthCounter = $(this.$refs.textLengthCounter);

            const maxLength = 200; // Set maximum character length

            const updateCounter = (editor, counter) => {
                const text = editor.trumbowyg('html');
                const strippedText = $('<div>').html(text).text(); // Strip HTML tags
                const length = strippedText.length;
                const remaining = Math.max(0, maxLength - length); // Ensure remaining is at least 0
                // console.log(remaining);
                // counter.html(`${remaining} characters remaining`);
            };

            const limitContent = (editor) => {
                const text = editor.trumbowyg('html');
                const strippedText = $('<div>').html(text).text(); // Strip HTML tags
                if (strippedText.length > maxLength) {
                    const truncatedText = strippedText.slice(0, maxLength); // Truncate to maxLength
                    editor.trumbowyg('html', truncatedText);
                }
            };

            headlineEditor.on('tbwchange', () => {
                updateCounter(headlineEditor, headlineLengthCounter);
                limitContent(headlineEditor);
            });

            textEditor.on('tbwchange', () => {
                updateCounter(textEditor, textLengthCounter);
                limitContent(textEditor);
            });

            // Handle pasting content in the editor
            [headlineEditor, textEditor].forEach(editor => {
                editor.on('paste', () => {
                    setTimeout(() => {
                        updateCounter(editor, editor === headlineEditor ? headlineLengthCounter : textLengthCounter);
                        limitContent(editor);
                    }, 0);
                });
            });
        }
    }
}
</script>

<style scoped>

</style>
