<template>
    <!--START BRAND DATA - I HOPE THIS IS THE BEST UIxUX-->
    <div class="editor_block">
        <label class="mylabel_1" for=""><span>Add Brand Data</span></label>
        <div class="choose_brand_data">
            <ul class="choose_brand_list clr">
                <li @click="toggleActiveBrandTabs('brand_name')">
                    <div
                        class="choose_brand_button"
                        :class="{active : data.active_tabs?.includes('brand_name')}">
                        <strong>Name</strong>
                    </div>
                </li>
                <li @click="toggleActiveBrandTabs('brand_slogan')">
                    <div
                        class="choose_brand_button"
                        :class="{active : data.active_tabs.includes('brand_slogan')}">
                        <strong>Slogan</strong>
                    </div>
                </li>
                <li @click="toggleActiveBrandTabs('brand_website')">
                    <div
                        class="choose_brand_button"
                        :class="{active : data.active_tabs.includes('brand_website')}">
                        <strong>Website</strong>
                    </div>
                </li>
            </ul>
        </div>

        <div class="show_brand_data">
            <ul>
                <li
                    class="show_brand_name"
                    :class="{active : data.active_tabs.includes('brand_name')}">
                    <label class="mylabel_1" for=""><span>Brand Name</span></label>
                    <div
                        class="textarea_block textarea_block_singleline textarea_block_headline txa3 txa4 txa5 txa6 txa9">
                        <trumbowyg
                            v-model="data.brand_name"
                           :disabled="brand_name_disabled"
                            :config="textareaConfig"
                            @tbw-change="$emit('brand_name_change',$event)"
                            class="rl_textarea_input"
                            name="content">
                        </trumbowyg>
                    </div>
                </li>
                <li
                    class="show_brand_name"
                    :class="{active : data.active_tabs.includes('brand_slogan')}">
                    <label class="mylabel_1" for=""><span>Slogan</span></label>
                    <div class="textarea_block textarea_block_singleline txa5 txa6 txa9">
                        <trumbowyg
                            v-model="data.brand_slogan"
                            :disabled="brand_slogan_disabled"
                            :config="textareaConfig"
                            class="rl_textarea_input"
                            @tbw-change="$emit('brand_slogan_change',$event)"
                            name="content">
                        </trumbowyg>
                    </div>
                </li>
                <li
                    class="show_brand_name"
                    :class="{active : data.active_tabs.includes('brand_website')}">
                    <label class="mylabel_1" for=""><span>Website</span></label>
                    <div class="textarea_block textarea_block_singleline txa3 txa4 txa5 txa6 txa9">
                        <trumbowyg
                            v-model="data.brand_website"
                            :disabled="brand_website_disabled"
                            :config="textareaConfig"
                            class="rl_textarea_input"
                            @tbw-change="$emit('brand_website_change',$event)"
                            name="content">
                        </trumbowyg>
                    </div>
                </li>
            </ul>
        </div>

    </div>
    <!--END BRAND DATA - I HOPE THIS IS THE BEST UIxUX-->
</template>

<script>
// Import this component
import 'trumbowyg/dist/ui/trumbowyg.css';
import {checkTextAreaLength} from "../../helpers/index.js";

export default {
    name: "BrandData",
    props: {
        textareaConfig: {
            type: Object,
            default: () => ({
                autogrow: true,
                removeformatPasted: true,
                // Adding color plugin button
                tagsToRemove: ['script', 'link'],
                btns: ['strong', 'em', 'del', 'underline', 'superscript', 'subscript', 'foreColor', 'backColor', '|', 'viewHTML'],
                btnsAdd: ['foreColor', 'backColor'],
            })
        },
        data: {
            type: Object,
            default: () => ({
                active_tabs: {
                    type: Array,
                    default: () => {
                        return [];
                    }
                },
                brand_name: {
                    type: String,
                    default: '<p>BrandName</p>'
                },
                brand_slogan: {
                    type: String,
                    default: '<p>Write your slogan here</p>'
                },
                brand_website: {
                    type: String,
                    default: '<p>YourWebpage.com</p>'
                }
            })
        }
    },
    data: () => {
        return {
            brand_name_disabled: false,
            brand_slogan_disabled: false,
            brand_website_disabled: false
        }
    },
    watch: {
        'data.brand_name'(newValue, oldValue) {
            checkTextAreaLength(newValue, this, 'brand_name', 'brand_name_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.$emit('brand_name_change', {
                            target: {
                                value
                            }
                        })

                    })
                })
            }, true)
        },
        'data.brand_slogan'(newValue, oldValue) {
            checkTextAreaLength(newValue, this, 'brand_slogan', 'brand_slogan_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.$emit('brand_slogan_change', {
                            target: {
                                value
                            }
                        })
                    })
                })
            }, true)
        },
        'data.brand_website'(newValue, oldValue) {
            checkTextAreaLength(newValue, this, 'brand_website', 'brand_website_disabled', value => {
                setTimeout(() => {
                    setTimeout(() => {
                        this.$emit('brand_website_change', {
                            target: {
                                value
                            }
                        })

                    })
                })
            }, true)
        }
    },
    methods: {
        toggleActiveBrandTabs(brandTab) {
            this.$emit('toggleActiveBrandTabs', brandTab);
        }
    },
}
</script>

<style scoped>

</style>
