<template>
    <div>
        <!--START SETTING THE BACKGROUND -> UPLOAD IMAGE / BACKGROUND COLOR / ETC-->
        <!--START UPLOAD SINGLE IMAGE FORM-->
        <div style="height: 42px" class="clear"></div>
        <h1>Background Selection</h1>


        <div class="price_type_block clr" style="padding-top: 0px;"
            v-if="showBgHeader"
        >
            <div class="price_type_left">
                <div class="open_popup_1"
                     :class="{active:backgroundType==='static'}"
                     @click="$emit('changeBgType','static')"
                >
                    <div>Static Bg <span class="material-icons">blur_off</span></div>
                </div>
            </div>
            <div class="price_type_right">
                <div class="open_popup_1"
                    :class="{active:backgroundType==='animated'}"
                     @click="$emit('changeBgType','animated')"
                >
                    <div>Animated Bg <span class="material-icons">blur_on</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    props: {
        backgroundType: {
            type: String,
            default: 'animated'
        },
        showBgHeader:{
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>

</style>
