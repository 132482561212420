<template>
    <div>
        <div style="height: 42px" class="clear"></div>
        <h1>Background Selection</h1>
        <!--FLORINXXX -> ANIMATED BACKGROUND LENGHT-->
        <div class="editor_block" style="padding-top: 12px;">
            <label class="mylabel_1" for="bgLength"><span>Animation Length in Seconds</span></label>
            <custom-input
                :value="data.length"
                :id="'VideoLength'"
                :inputPlaceholder="'Video Length'"
                :min="5"
                :max="100"
                :increment="5"
                :unit="'s'"
                @updateInput="updateBgLength"
            />
        </div>

        <upload-single-image-with-opacity
            :image="data.image"
            :opacity="data.opacity"
            :backgroundColor="data.backgroundColor"
            :uniqueID="uniqueID"
            @togglePopUp="togglePopUp"
            @updateImage="updateImage"
            @updateOpacity="updateOpacity"
            @deleteImage="deleteImage"
        />
    </div>
</template>

<style scoped>

</style>

<script>
import CustomInput from '../../common/CustomInput.vue';
import UploadAndImageGallery from "../../common/UploadAndImageGallery.vue";
import UploadSingleImageWithOpacity from "../../common/UploadSingleImageWithOpacity.vue";

export default {
    name: "Animated",
    components: {
        UploadSingleImageWithOpacity,
        CustomInput,
        UploadAndImageGallery
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
        currentScene: {
            type: String,
            default: 'Animated Bg'
        }
    },

    computed: {
        imageSrc() {
            if (this.image.includes('unsplash.com') || this.image.includes('https://storage.pixteller.com/')) {
                return this.image;
            } else {
                return 'https://storage.renderlion.com/' + this.image;
            }
        }
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName('AnimatedBg'));
    },
    methods: {
        togglePopUp() {
            this.$emit('togglePopUp');
        },
        updateImage(image) {
            this.$emit('updateImageBg', image);
        },
        updateOpacity(value) {
            this.$emit('updateBgOpacity', value)
        },
        updateBgLength(value) {
            this.$emit('updateBgLength', value)
        },
        deleteImage() {
            this.$emit('updateImageBg', null);
        },
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
    }
}
</script>
