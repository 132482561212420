<template>
    <!--START THE BIG IFRAMES PRELOADER/LOADER-->
    <div class="iframe_loader">
        <div class="iframe_loader_bg">
            <div class="lds-ring lds-ring12">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span class="lds-image lds-lion"></span><span class="lds-image lds-play"></span>
            </div>
        </div>
    </div>
    <!--END THE BIG IFRAMES PRELOADER/LOADER-->
</template>

<script>
export default {
    name: "Preloader",
}
</script>

<style scoped>

</style>
